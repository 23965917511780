import React, { forwardRef } from "react";
import {
  digitsRegularExpFnc,
  getFormattedPrice,
  korRegularExpFnc,
  phoneRegularExpFnc,
  phoneRemoveHyphen,
} from "../FunctionUtility";

const InputTag = forwardRef(
  (
    {
      id = "",
      type = "",
      style = {},
      className = "",
      placeholder = "",
      min = "",
      max = "",
      maxLength = "",
      onBlur = () => {},
      onChange,
      setValue,
      ...rest
    },
    ref
  ) => {
    const handleFocus = (e) => {
      if (id !== "contact") {
        e.currentTarget.select();
      } else {
        e.currentTarget.value = phoneRemoveHyphen(e.currentTarget.value);
      }
    };

    const handleBlur = (e) => {
      if (id !== "contact") {
        onBlur(e);
        const resetValue = e.currentTarget.getAttribute("reset-value");
        if (setValue && resetValue) {
          if (e.currentTarget.value === "") {
            setValue(resetValue, 0); // 공통적으로 setValue 호출
          } else {
            setValue(resetValue, getFormattedPrice(e.currentTarget.value));
          }
        }
      } else {
        onBlur(e);
        e.currentTarget.value = phoneRegularExpFnc(e.currentTarget.value);
      }
    };

    // 입력된 값이 "0"으로 시작하면 제거해주는 로직
    const handleChange = (e) => {
      let value = e.target.value;
      if (id !== "contact") {
        // 공백제거
        value = value.replace(/\D/g, "");
        // 0 제거
        if (value.startsWith("0") && value.length > 1) {
          value = value.replace(/^0+/, "");
        }
      }
      // 포맷팅된 값을 설정
      if (onChange) {
        onChange({
          ...e,
          target: {
            ...e.target,
            value: value,
          },
        });
      }
    };

    const handleInput = (max, e) => {
      if (id !== "contact") {
        digitsRegularExpFnc(max, e.currentTarget);
      } else {
        if (e.currentTarget.value.length > e.currentTarget.maxLength)
          e.currentTarget.value = e.currentTarget.value.slice(
            0,
            e.currentTarget.maxLength
          );
      }
    };

    return (
      <input
        ref={ref}
        id={id}
        type={type ? type : "text"}
        inputMode="numeric"
        style={style}
        placeholder={placeholder}
        className={className}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
        onChange={handleChange}
        onInput={(e) => handleInput(id === "contact" ? maxLength : max, e)}
        min={min}
        max={max}
        maxLength={11}
        {...rest}
      />
    );
  }
);

export default InputTag;
