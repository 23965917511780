import { createContext, useContext, useState } from "react";
import { getFormattedDateHyphen } from "../common/utils/FunctionUtility";

const ComponentsContext = createContext();

export const useComponentsContext = () => useContext(ComponentsContext);

export const ContextProvider = ({ children }) => {
  /********************** 사용자, 관리자 공통 useState **********************/
  const [loading, setLoading] = useState(false); // fetch 통신 시 로딩 useState

  const [searchPeriod, setSearchPeriod] = useState("daily"); // 통계 조회 시 period조건
  const [startDt, setStartDt] = useState(getFormattedDateHyphen()); // 시작날짜
  const [endDt, setEndDt] = useState(getFormattedDateHyphen()); // 종료날짜

  const [userInfo, setUserInfo] = useState([]); // 사용자 등록 정보 (관리자에서 가맹점 전환 시에도 사용)

  /********************************* 관리자 *********************************/
  const [isShowMngCallbackAlert, setIsShowMngCallbackAlert] = useState(false); // 관리자 콜백 Alert창
  const [isShowMngApproveAlert, setIsShowMngApproveAlert] = useState(false); // 관리자 완료 Alert창

  const [mngRestStep, setMngRestStep] = useState(0); // 관리자 api step
  const [mngRequestDatas, setMngRequestDatas] = useState(null); // 관리자 api 요청 파라미터

  const [modifyTabName, setModifyTabName] = useState("");

  /********************************* 사용자 *********************************/
  const [isShowUserCallbackAlert, setIsShowUserCallbackAlert] = useState(false); // 사용자 콜백 Alert창
  const [isShowUserApproveAlert, setIsShowUserApproveAlert] = useState(false); // 사용자 완료 Alert창

  const [noticeAlert, setNoticeAlert] = useState(false); // 알림창 notice
  const [cancelAlert, setCancelAlert] = useState(false); // 알림창 cancel

  const [regularInfo, setRegularInfo] = useState([]); // 정기결제 정보

  const [totalAmount, setTotalAmount] = useState(0); // pg 결제금액
  const [goodsName, setGoodsName] = useState(""); // pg 결제 상품명

  const [pgControlStep, setPgControlStep] = useState(0);
  const [userRestStep, setUserRestStep] = useState({}); // 사용자 api step
  const [userRequestDatas, setUserRequestDatas] = useState({}); // 사용자 api 요청 파라미터

  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 리스트 조회

  const [isShowHowToUse, setIsShowHowToUse] = useState(false);

  const [subsState, setSubsState] = useState(false);

  return (
    <ComponentsContext.Provider
      value={{
        /**
         * 공통 useState
         */
        loading,
        setLoading,
        searchPeriod,
        setSearchPeriod,
        startDt,
        setStartDt,
        endDt,
        setEndDt,
        userInfo,
        setUserInfo,

        /**
         * 관리자 useState
         */
        isShowMngCallbackAlert,
        setIsShowMngCallbackAlert,
        isShowMngApproveAlert,
        setIsShowMngApproveAlert,
        mngRestStep,
        setMngRestStep,
        mngRequestDatas,
        setMngRequestDatas,
        modifyTabName,
        setModifyTabName,

        /**
         * 사용자 useState
         */
        isShowUserCallbackAlert,
        setIsShowUserCallbackAlert,
        isShowUserApproveAlert,
        setIsShowUserApproveAlert,
        noticeAlert,
        setNoticeAlert,
        cancelAlert,
        setCancelAlert,
        regularInfo,
        setRegularInfo,
        totalAmount,
        setTotalAmount,
        goodsName,
        setGoodsName,
        pgControlStep,
        setPgControlStep,
        userRestStep,
        setUserRestStep,
        userRequestDatas,
        setUserRequestDatas,
        periodUpdate,
        setPeriodUpdate,
        isShowHowToUse,
        setIsShowHowToUse,
        subsState,
        setSubsState,
      }}
    >
      {children}
    </ComponentsContext.Provider>
  );
};
