import { Fragment, useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useComponentsContext } from "../../../ContextProvider";

import styles from "../../../../common/utils/UtilsAlertStyle.json";

const UserCallbackAlertForm = ({
  title,
  content,
  buttonTxt,
  cancelBtnMark,
  alertType,
  callback,
}) => {
  const {
    userInfo,
    setIsShowUserCallbackAlert,
    setNoticeAlert,
    setCancelAlert,
  } = useComponentsContext();

  const [isShowInfoAlert, setIsShowInfoAlert] = useState(true); // 기능 Alert useState
  const [alertStyle, setAlertStyle] = useState({}); // 알림 타입별 style 지정

  useEffect(() => {
    // 버튼별 기능 Alert 알림 화면 표시
    if (alertType) {
      const alertStyleClass = styles.alertType;
      setAlertStyle(alertStyleClass[alertType]);
      setIsShowInfoAlert(true);
    }
  }, []);

  // 기능 Alert 확인 클릭 시 (db저장, PG 결제)
  const handleConfirmClick = () => {
    setIsShowInfoAlert(false);
    setTimeout(() => {
      if (callback) callback();
      setAlertStyle({});
      setNoticeAlert(false);
      setCancelAlert(false);
    }, 300);
  };

  /**
   * 애니메이션 효과가 사라져서 애니메이션 효과가
   * 나타난 후 실행하도록 타임아웃 설정해 둠
   */
  useEffect(() => {
    setTimeout(() => {
      if (!isShowInfoAlert) {
        setIsShowUserCallbackAlert(false);
      }
    }, 310);
  }, [isShowInfoAlert]);

  return (
    <Fragment>
      {/* 버튼명에 따른 결제 Alert 알림창 표시 (paymentAlert) */}
      {alertStyle && Object.keys(alertStyle).length > 0 && (
        <Modal
          show={isShowInfoAlert}
          onHide={() => {
            setIsShowInfoAlert(false);
            setTimeout(() => {
              setNoticeAlert(false);
              setCancelAlert(false);
            }, 300);
          }}
          className="alert-modal resize-modal"
          style={{ zIndex: "100000" }}
        >
          <Modal.Header>
            <Modal.Title>
              <BsInfoCircle
                className={"icon-info " + `${alertStyle.addIconClass}`}
              />
              <b>{title}</b>
            </Modal.Title>
          </Modal.Header>
          {content.length > 0 && (
            <Modal.Body>
              {content.map((text, i) => {
                return <p key={i}>{text}</p>;
              })}
              {title.includes("카드 조회") && (
                <h5>{userInfo && userInfo.card_mask_no}</h5>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            <button
              className={"btn " + `${alertStyle.confirmBtnClass}`}
              onClick={handleConfirmClick}
              style={{ marginRight: "20px" }}
            >
              {buttonTxt}
            </button>
            {cancelBtnMark && (
              <button
                className={"btn " + `${alertStyle.showCancelBtnClass}`}
                onClick={() => {
                  setIsShowInfoAlert(false);
                  setTimeout(() => {
                    setNoticeAlert(false);
                    setCancelAlert(false);
                  }, 300);
                }}
              >
                취소
              </button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export const UserCallbackAlert = (
  title,
  content,
  buttonTxt,
  cancelBtnMark,
  alertType,
  callback
) => {
  return (
    <UserCallbackAlertForm
      title={title}
      content={content}
      buttonTxt={buttonTxt}
      cancelBtnMark={cancelBtnMark}
      alertType={alertType}
      callback={callback}
    />
  );
};

/* 
    [ 완료 ALert ]
  */
const UserApproveAlertForm = ({ title, alertType, callback }) => {
  const {
    setIsShowUserApproveAlert,
    userRestStep,
    setUserRestStep,
    pgControlStep,
    setPgControlStep,
    setNoticeAlert,
    setCancelAlert,
  } = useComponentsContext();

  const [isShowApproveAlert, setIsShowApproveAlert] = useState(true);
  const [alertStyle, setAlertStyle] = useState({}); // 알림 타입별 style 지정

  useEffect(() => {
    // 버튼별 기능 Alert 알림 화면 표시
    if (alertType) {
      const alertStyleClass = styles.alertType;
      setAlertStyle(alertStyleClass[alertType]);
      setIsShowApproveAlert(true);
    }
  }, []);

  // 완료 Alert 확인 버튼 클릭
  const handleInfoConfirm = () => {
    // 관리자
    if (userRestStep.key > 0) {
      if (callback) callback();
      setUserRestStep({ key: 0 });
    } else if (pgControlStep > 0) {
      /**
       * PG 결제 시 userRestStep에 값이 없으므로
       * userRestStep에 값을 받아 넘기면 restFunctions 함수 처리를 하기때문에
       * pgControlStep이라는 임의 step을 만들어 콜백 처리를 해줌
       */
      if (callback) callback();
      setPgControlStep(0);
    }

    setTimeout(() => {
      setNoticeAlert(false);
      setCancelAlert(false);
    }, 300);
    setIsShowApproveAlert(false);
  };

  /**
   * 애니메이션 효과가 사라져서 애니메이션 효과가
   * 나타난 후 실행하도록 타임아웃 설정해 둠
   */
  useEffect(() => {
    setTimeout(() => {
      if (!isShowApproveAlert) {
        setIsShowUserApproveAlert(false);
      }
    }, 310);
  }, [isShowApproveAlert]);

  return (
    <Fragment>
      <Modal
        show={isShowApproveAlert}
        className="alert-modal"
        style={{ zIndex: "100000" }}
      >
        <Modal.Header style={{ padding: "2rem 3rem" }}>
          <Modal.Title>
            <BsInfoCircle
              className={"icon-info " + `${alertStyle.addIconClass}`}
            />
            <b>{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            className={"btn " + `${alertStyle.addBtnClass}`}
            onClick={handleInfoConfirm}
          >
            확인
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export const UserApproveAlert = (title, alertType, callback) => {
  return (
    <UserApproveAlertForm
      title={title}
      alertType={alertType}
      callback={callback}
    />
  );
};
