import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { BsSearch, BsXLg } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  emptyToHyphen,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";

const MemberCouponList = (props) => {
  const { register, watch, reset } = useForm({
    defaultValues: {
      status: 99,
      cp_name: "",
    },
  });
  const status = watch("status");
  const cpName = watch("cp_name");

  const listPerPage = 10;
  const [couponKey, setCouponKey] = useState(0);

  const [couponList, setCouponList] = useState([]);

  // 회원 쿠폰 내역 조회
  const fetchData = () => {
    if (props.selectedContact) {
      fetch(
        `/api/member/coupon?st_code=${setToAdminTransAcc().stCode}&contact=${
          props.selectedContact
        }${setParameter()}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.result) {
            res.data.map((data, i) => (data.id = i + 1));
            setCouponList(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const setParameter = () => {
    return `${status === 99 ? "" : `&status=${status}`}&cp_name=${cpName}`;
  };

  // 검색 버튼 클릭
  const handleSearchBtnClick = () => {
    fetchData();
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
    }
  };

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const listCurrentTab = getCurrentList(couponList, couponKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setCouponKey(selected);
  };

  useEffect(() => {
    if (props.isShowCouponModal && props.selectedContact) {
      fetchData();
    }
  }, [props.isShowCouponModal, props.selectedContact]);

  useEffect(() => {
    if (!props.isShowCouponModal) {
      props.setSelectedContact("");
      reset();
      setTimeout(() => {
        setCouponList([]);
        setCouponKey(0);
      }, 300);
    }
  }, [props.isShowCouponModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowCouponModal}
        onHide={() => {
          props.setIsShowCouponModal(false);
        }}
        className={`member-coupon-modal ${
          listCurrentTab.length > 4 ? "resize-mod-modal" : "mod-modal"
        }`}
      >
        <Modal.Header>
          <Modal.Title>쿠폰 내역</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowCouponModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="m-0 sch_box">
            <div className="row form-group">
              <div className="col-lg-4">
                <select
                  id="search_grade"
                  name="search_grade"
                  className="form-control"
                  {...register("status")}
                >
                  <option value={99}>전체</option>
                  <option value={1}>사용완료</option>
                  <option value={2}>보유중</option>
                  <option value={3}>기간만료</option>
                  <option value={4}>사용취소</option>
                </select>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  id="search_cpname"
                  name="search_cpname"
                  placeholder="쿠폰명"
                  className="form-control"
                  onKeyDown={keyDownFnc}
                  {...register("cp_name")}
                />
              </div>
              <div className="row col-md-3-1 ml-1">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  onClick={handleSearchBtnClick}
                >
                  <BsSearch style={{ fontWeight: "bold" }} /> &nbsp; 조회
                </button>
              </div>
            </div>
          </div>
          <div
            className="order-table ov-h c-table"
            style={{ marginTop: "1.5rem" }}
          >
            <table
              className={
                "table text-center fontS add-table info-table " +
                `${listCurrentTab.length > 0 ? "" : "minHeight-table"}`
              }
            >
              <thead>
                <tr>
                  <th scope="col" style={{ width: "60px" }}>
                    No
                  </th>
                  <th scope="col" style={{ width: "200px" }}>
                    쿠폰명
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    발행일자
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    사용일자
                  </th>
                  <th scope="col" style={{ width: "150px" }}>
                    상태
                  </th>
                </tr>
              </thead>
              <tbody>
                {listCurrentTab.length > 0 &&
                  listCurrentTab.map((list, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ width: "60px" }}>{list.id}</td>
                        <td style={{ width: "220px" }}>{list.cp_name}</td>
                        <td style={{ width: "150px" }}>
                          {emptyToHyphen(list.scheduled_dt)}
                        </td>
                        <td style={{ width: "150px" }}>
                          {emptyToHyphen(list.use_dt)}
                        </td>
                        <td style={{ width: "150px" }}>{list.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="c-pagination prevent-drag">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              pageCount={Math.ceil(couponList.length / listPerPage)}
              breakLabel={false}
              marginPagesDisplayed={false}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export { MemberCouponList };
