import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePeriodTag from "../../../../common/utils/components/DatePeriodTag";
import {
  formattedPeriod,
  getFormattedFirstDay,
  getFormattedLastDay,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const ReservationStatistics = () => {
  const {
    setLoading,
    periodUpdate,
    setPeriodUpdate,
    searchPeriod,
    setSearchPeriod,
  } = useComponentsContext();

  const [startDt, setStartDt] = useState(getFormattedFirstDay());
  const [endDt, setEndDt] = useState(getFormattedLastDay());

  const [staticTables, setStaticTables] = useState([]);

  const datas = [
    {
      id: "pending",
      label: "대기",
      backgroundColor: "rgb(201 203 207 / 60%)",
    },
    {
      id: "confirmed",
      label: "확정",
      backgroundColor: "rgb(54 162 235 / 30%)",
    },
    {
      id: "canceled",
      label: "취소",
      backgroundColor: "rgb(255 159 64 / 30%)",
    },
    {
      id: "no_show",
      label: "노쇼",
      backgroundColor: "rgb(255 99 132 / 30%)",
    },
  ];

  // 그래프 조회
  const fetchGraphData = () => {
    setLoading(true);
    fetch(
      `/api/reservation/statistics?&st_code=${
        setToAdminTransAcc().stCode
      }${formattedPeriod(searchPeriod, startDt, endDt)}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          datas.map((type) => {
            if (res.data[type.id].length > 0) {
              type.data = res.data[type.id];
            }
          });
          setStaticTables(datas);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 그래프 옵션 설정
  const options = {
    responsive: true,
    scales: {
      x: {
        offset: true,
      },
      y: {
        beginAtZero: true,
        suggestedMax: 10,
      },
    },
  };

  // datalabels 옵션 세팅
  const datalabels = () => {
    let options = {
      labels: {
        value: {
          anchor: "end",
          clamp: true,
          borderWidth: 2,
          borderRadius: 4,
          font: { size: 15 },
          formatter: function (value, ctx) {
            return value > 0 ? `${value} 건` : null;
          },
          color: "black",
          backgroundColor: "#8f8f8f33",
        },
      },
    };

    return options;
  };

  // 막대 그래프 너비 설정
  const percentageSetting = (data) => {
    if (data.length > 0) {
      const length = data.length;
      return length === 1 ? 0.4 : length === 2 ? 0.5 : 0.6;
    }
  };

  // 예약 건수 그래프
  const cntGraphByResveType = (graphDatas) => {
    let resveCntGraph = {};
    let dateArrays = [];
    let cntArrays = [];

    if (graphDatas.data && graphDatas.data.length > 0) {
      graphDatas.data.map((list) => {
        dateArrays.push(list.time_span);
        cntArrays.push(list.cnt);
      });
    }

    resveCntGraph = {
      labels: dateArrays && dateArrays.length > 0 ? dateArrays : [],
      datasets: [
        {
          label: `${graphDatas.label} 건수`,
          data: cntArrays && cntArrays.length > 0 ? cntArrays : [],
          backgroundColor: graphDatas.backgroundColor,
          datalabels: datalabels(),
          barPercentage: percentageSetting(cntArrays),
          categoryPercentage: percentageSetting(cntArrays),
        },
      ],
    };
    return resveCntGraph;
  };

  useEffect(() => {
    fetchGraphData();

    return () => {
      setSearchPeriod("daliy");
    };
  }, []);

  useEffect(() => {
    if (periodUpdate) {
      fetchGraphData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  return (
    <Fragment>
      <div className="title search-div d-flex">
        <DatePeriodTag
          currentMenu={"statistics"}
          startDt={startDt}
          setStartDt={setStartDt}
          endDt={endDt}
          setEndDt={setEndDt}
        />
      </div>
      <div className="d-flex flex-wrap">
        {staticTables.length > 0 &&
          staticTables.map((resveGraph) => {
            return (
              <div className="col-xl-6 col-lg-12" key={resveGraph.id}>
                <div className="card shadow mb-4" id={`${resveGraph.id}Graph`}>
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                    <h6 className="m-0 font-weight-bold text-primary p-0">
                      예약 {resveGraph.label} 건수
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <Bar
                            options={options}
                            data={cntGraphByResveType(resveGraph)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default ReservationStatistics;
