import { Fragment, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaPen, FaStar, FaTrash } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import {
  emptyToHyphen,
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  settingSelectedPeriod,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";

const ReservationList = (props) => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();

  const [resveListInfo, setResveListInfo] = useState([]); // 예약 목록 조회
  const [startDt, setStartDt] = useState(getFormattedFirstDay());
  const [endDt, setEndDt] = useState(getFormattedLastDay());
  const [inputName, setInputName] = useState("");
  const [inputContact, setInputContact] = useState("");
  const [periodSetting, setPeriodSetting] = useState(false);

  const listPerPage = 10;
  const [resveKey, setResveKey] = useState(0);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const resveCurrentTab = getCurrentList(resveListInfo, resveKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setResveKey(selected);
  };

  // 기간 예약 목록 조회
  const periodFetchData = () => {
    setLoading(true);
    fetch(
      `/api/reservation/list?st_code=${
        setToAdminTransAcc().stCode
      }&start_dt=${startDt}&end_dt=${endDt}&contact=${inputContact}&name=${inputName}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((list, i) => {
            list.id = i + 1;
          });
          setResveListInfo(res.data);
        } else {
          setResveListInfo([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setStartDt(settingDate.startDt);
    setEndDt(settingDate.endDt);
    setPeriodSetting(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodSetting(true);
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (periodSetting) {
      periodFetchData();
      setPeriodSetting(false);
    }
  }, [periodSetting]);

  // 예약상태 포맷
  const resveStatusFormat = (status) => {
    switch (status) {
      case "PENDING":
        return "대기";
      case "CONFIRMED":
        return "확정";
      case "CANCELED":
        return "취소";
      case "NO_SHOW":
        return "노쇼";
      default:
        break;
    }
  };

  useEffect(() => {
    periodFetchData();

    // 자식창에서 메시지 호출 받은 경우
    window.addEventListener("message", function (e) {
      if (e.data.request === "refetch") {
        props.setIsShowResveModal(false);
        props.research();
        setPeriodSetting(true);
        setCancelAlert(false);
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="title search-div d-flex flex-row">
          <div className="col-lg-12 period-search pl-0 pr-0">
            <div className="col-lg-2 pl-0">
              <input
                type="text"
                id="search_name"
                name="search_name"
                value={inputName}
                placeholder="예약자"
                className="form-control"
                onKeyDown={keyDownFnc}
                onChange={(e) => setInputName(e.target.value)}
              />
            </div>
            <div className="col-lg-2 pl-0">
              <input
                type="text"
                id="search_phone"
                name="search_phone"
                value={inputContact}
                placeholder="연락처"
                className="form-control"
                onKeyDown={keyDownFnc}
                onChange={(e) => setInputContact(e.target.value)}
              />
            </div>
            <div className="col-lg-2 resize-period pr-0">
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                오늘
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                이번달
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                올해
              </button>
            </div>
            <div className="col-lg-4" style={{ padding: "0" }}>
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="between_day"
                  name="between_day"
                  value={startDt}
                  onChange={(e) => setStartDt(e.currentTarget.value)}
                  className="form-control"
                />
                <span>~</span>
              </div>
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="and_day"
                  name="and_day"
                  value={endDt}
                  onChange={(e) => setEndDt(e.currentTarget.value)}
                  className="form-control"
                />
              </div>
              <div className="input-con col-lg-3 resize-sch-btn">
                <button
                  type="submit"
                  className="btn btn-outline-secondary search-btn"
                  onClick={handleSearchClick}
                >
                  <BsSearch style={{ fontWeight: "bold" }} />
                  &nbsp; 조회
                </button>
              </div>
            </div>
            <div className="col-lg-2 resize-btn float-right px-0 cc-col-right">
              <button
                type="button"
                className="btn btn-primary d-flex align-items-center float-right mr-0"
                onClick={() => {
                  props.setSelectedResveNo("");
                  props.setIsShowResveModal(true);
                }}
              >
                <FaStar />
                &nbsp;예약 등록
              </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={"table text-center fontS add-table resize-table"}
                  style={{
                    minHeight: resveCurrentTab.length > 0 ? "" : "11rem",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "60px" }} rowSpan={"2"} scope="col">
                        No
                      </th>
                      <th style={{ width: "120px" }} rowSpan={"2"} scope="col">
                        예약자
                      </th>
                      <th style={{ width: "150px" }} rowSpan={"2"} scope="col">
                        연락처
                      </th>
                      <th style={{ width: "100px" }} rowSpan={"2"} scope="col">
                        방문횟수
                      </th>
                      <th style={{ width: "200px" }} rowSpan={"2"} scope="col">
                        예약일시
                      </th>
                      <th style={{ width: "60px" }} colSpan={"2"} scope="col">
                        예약인원
                      </th>
                      <th style={{ width: "120px" }} rowSpan={"2"} scope="col">
                        예약금
                      </th>
                      <th style={{ width: "400px" }} rowSpan={"2"} scope="col">
                        요청사항
                      </th>
                      <th style={{ width: "100px" }} rowSpan={"2"} scope="col">
                        예약상태
                      </th>
                      <th style={{ width: "100px" }} rowSpan={"2"} scope="col">
                        테이블
                      </th>
                      <th style={{ width: "120px" }} rowSpan={"2"} scope="col">
                        관리
                      </th>
                    </tr>
                    <tr>
                      <th>성인</th>
                      <th>유아</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resveCurrentTab.length > 0 &&
                      resveCurrentTab.map((list, i) => {
                        return (
                          <tr key={list.reservation_no}>
                            <td>{list.id}</td>
                            <td>{emptyToHyphen(list.name)}</td>
                            <td>{list.contact}</td>
                            <td>{list.visit_cnt}</td>
                            <td>{list.reservation_dt}</td>
                            <td>{list.adult}</td>
                            <td>{list.infant}</td>
                            <td>{getFormattedPrice(list.deposit_amt)}</td>
                            <td>{emptyToHyphen(list.memo)}</td>
                            <td>{resveStatusFormat(list.status)}</td>
                            <td>
                              {list.tbl_name === "미지정" ? "-" : list.tbl_name}
                            </td>
                            <td>
                              <div className="d-flex justify-content-sm-around align-items-center">
                                <div
                                  title="예약수정"
                                  className="modify-icon"
                                  onClick={() => {
                                    props.setSelectedResveNo(
                                      list.reservation_no
                                    );
                                    props.setIsShowResveModal(true);
                                  }}
                                >
                                  <FaPen />
                                </div>
                                <div
                                  title="예약삭제"
                                  style={{ fontSize: "18px" }}
                                  className="modify-icon"
                                  onClick={() => {
                                    setIsShowUserCallbackAlert(true);
                                    setCancelAlert(true);
                                    setUserRequestDatas({
                                      reservation_no: list.reservation_no,
                                    });
                                  }}
                                >
                                  <FaTrash />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(resveListInfo.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      {isShowUserCallbackAlert &&
        cancelAlert &&
        UserCallbackAlert(
          "예약 정보를 삭제하시겠습니까?",
          [],
          "확인",
          true,
          "caution",
          function () {
            setUserRestStep({ key: 50, control: "delete" });
            setCancelAlert(false);
          }
        )}
    </Fragment>
  );
};

export { ReservationList };
