import { Fragment, useEffect, useState } from "react";
import { BsPrinterFill, BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { useComponentsContext } from "../../../ContextProvider";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  setFormattedDate,
  settingSelectedPeriod,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import ReceiptDetail from "../../../../common/print/ReceiptDetail";

const PaymentHistory = () => {
  const { setLoading } = useComponentsContext();

  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 결제 내역 리스트 조회
  const [showHistory, setShowHistory] = useState(false); // 상세내역 표시 useState
  const [historyList, setHistoryList] = useState([]); // 결제내역 리스트
  const [historyByPsrInfo, setHistoryByPsrInfo] = useState({}); // psr별 결제 상세내역 리스트

  const [startDt, setStartDt] = useState(getFormattedFirstDay()); // 시작날짜
  const [endDt, setEndDt] = useState(getFormattedLastDay()); // 종료날짜

  const listPerPage = 10;
  const [historyKey, setHistoryKey] = useState(0);

  // 결제 내역 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(
      `/api/payment/list?st_code=${
        setToAdminTransAcc().stCode
      }&start_dt=${startDt}&end_dt=${endDt}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, index) => (data.id = index + 1));
          setHistoryList(res.data);
        } else {
          setHistoryList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setStartDt(settingDate.startDt);
    setEndDt(settingDate.endDt);
    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("기간 검색 시 시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const historyCurrentTab = getCurrentList(historyList, historyKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setHistoryKey(selected);
  };

  /**
   * 프린트 관련 함수
   */
  // 프린트 아이콘 클릭 시 결제 내역 한 건 조회
  const handlePrintBtnClick = (psr) => {
    fetch(
      `/api/payment/info?st_code=${setToAdminTransAcc().stCode}&ms_psr=${psr}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setHistoryByPsrInfo(res.data);
          setShowHistory(true);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="title search-div d-flex">
          <div className="col-lg-10 period-search pl-0">
            <div className="col-lg-4">
              <span style={{ marginRight: "8px" }}>조회기간</span>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                오늘
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                이번달
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                올해
              </button>
            </div>
            <div className="col-lg-8">
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="between_day"
                  name="between_day"
                  value={startDt}
                  onChange={(e) => setStartDt(e.currentTarget.value)}
                  className="form-control"
                />
                <span>~</span>
              </div>
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="and_day"
                  name="and_day"
                  value={endDt}
                  onChange={(e) => setEndDt(e.currentTarget.value)}
                  className="form-control"
                />
              </div>
              <div className="input-con col-lg-4 col-lg-3-1">
                <button
                  type="submit"
                  className="btn btn-outline-secondary search-btn"
                  onClick={handleSearchClick}
                >
                  <BsSearch style={{ fontWeight: "bold" }} />
                  &nbsp; 조회
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={
                    "table text-center fontS add-table resize-table " +
                    `${historyCurrentTab.length > 0 ? "" : "history-table"}`
                  }
                >
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">결제번호</th>
                      <th scope="col">서비스명</th>
                      <th scope="col">구분</th>
                      <th scope="col">금액</th>
                      <th scope="col">결제방법</th>
                      <th scope="col">결제일</th>
                      <th scope="col">서비스기간</th>
                      <th scope="col">결제상태</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyCurrentTab.length > 0 &&
                      historyCurrentTab.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td>{list.id}</td>
                            <td>{list.ms_psr}</td>
                            <td>{list.ms_pd_name}</td>
                            <td>{list.ms_pd_type}</td>
                            <td>{getFormattedPrice(list.amt_tot)}원</td>
                            <td>
                              {list.trn_type}
                              &nbsp;
                              <button
                                id={list.ms_psr}
                                className="printer-icon"
                                onClick={(e) =>
                                  handlePrintBtnClick(e.currentTarget.id)
                                }
                              >
                                <BsPrinterFill />
                              </button>
                            </td>
                            <td>{setFormattedDate(list.mod_dt)}</td>
                            <td>{`${list.start_dt} ~ ${list.end_dt}`}</td>
                            <td
                              className={
                                list.cancel_flag !== "0" && "red-color"
                              }
                            >
                              {list.cancel_flag === "0"
                                ? "결제 완료"
                                : "취소 완료"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(historyList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <ReceiptDetail
        receiptInfo={historyByPsrInfo}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
    </Fragment>
  );
};

export default PaymentHistory;
