import $ from "jquery";
import { Fragment, useEffect } from "react";

const Print = () => {
  // 영수증 프린트 영역 복사 후 모달 헤더 삭제
  const receipt_loc = window.opener.document.getElementById("receipt_modal").cloneNode(true);

  useEffect(() => {
    $("#receipt-html").append(receipt_loc);
    $('.modal-header').remove();
    window.print();
  }, []);

  return (
    <Fragment>
      <div id="receipt-html" className="receipt"></div>
    </Fragment>
  );
};

export default Print;
