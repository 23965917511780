import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  handleKeyPress,
  korRegularExpFnc,
  phoneRemoveHyphen,
} from "../../../../../common/utils/FunctionUtility";
import PointList from "../../../utils/PointList";

const MemberPoint = ({ stCode, accountUrl }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contact: "",
      password: "",
    },
  });
  const getStCode = localStorage.getItem("acc_st_code");

  const [selectedContact, setSelectedContact] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [validateChecked, setValidateChecked] = useState(false);

  // 비밀번호 검증
  const handleChckedMember = async (data) => {
    data.st_code = getStCode;
    data.contact = phoneRemoveHyphen(data.contact);

    const validate = await validatePw(data);

    if (validate) {
      setValidateChecked(true);
      setSelectedContact(data.contact);
    }
  };

  // 비밀번호 검증
  const validatePw = (data) => {
    return fetch(`/api/member/password/confirm`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          return true;
        } else {
          setTimeout(() => {
            errorMsg(res.message);
          }, 500);
          return false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 에러메시지 표시
  const errorMsg = (message) => {
    switch (message) {
      case "FAILED_NONE_MEMBER":
        alert("존재 하지 않는 회원입니다.");
        break;
      case "INVALID_PASSWORD":
        alert("비밀번호가 일치하지 않습니다.");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stCode) {
      localStorage.setItem("acc_st_code", stCode);
    }

    return () => {
      localStorage.removeItem("acc_st_code");
      localStorage.removeItem("acc_url");
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`register-container mt-4 ${
          validateChecked ? "acc-point-table" : ""
        }`}
      >
        {validateChecked ? (
          <PointList
            selectedContact={selectedContact}
            validateChecked={validateChecked}
          />
        ) : (
          <form
            onSubmit={handleSubmit(handleChckedMember)}
            onKeyPress={(e) => handleKeyPress(e)}
          >
            <div
              className="register-form mt-3"
              style={{ marginBottom: "2.2rem" }}
            >
              <h4 className="text-center">
                <b>포인트 조회</b>
              </h4>
            </div>
            <div className="mt-3 mb-4">
              <span>
                <small className="color-red mr-1">*</small>연락처
              </span>
              <div
                className="register-form-input d-flex mb-0"
                style={{ marginBottom: "0" }}
              >
                <InputTag
                  id="contact"
                  type="tel"
                  required
                  placeholder="연락처"
                  {...register("contact", {
                    validate: (value) =>
                      phoneRemoveHyphen(value).length == 11 ||
                      "연락처를 정확하게 입력해주세요.",
                  })}
                />
              </div>
              {errors.contact && (
                <span className="text-danger">
                  <small>{errors.contact.message}</small>
                </span>
              )}
            </div>
            <div className="mt-4">
              <span>
                <small className="color-red mr-1">*</small>비밀번호
              </span>
              <div
                className="register-form-input d-flex"
                style={{ marginBottom: "0" }}
              >
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="비밀번호 입력"
                  style={{ width: "91%" }}
                  onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
                  onInput={(e) => {
                    if (
                      e.currentTarget.value.length > e.currentTarget.maxLength
                    )
                      e.currentTarget.value = e.currentTarget.value.slice(
                        0,
                        e.currentTarget.maxLength
                      );
                  }}
                  maxLength={4}
                  required
                  {...register("password", {
                    validate: (value) =>
                      value.length == 4 || "비밀번호는 4자리로 입력해주세요.",
                  })}
                />
                <label
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-pw-label"
                >
                  {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                </label>
                {errors.password && (
                  <span className="text-danger">{errors.message}</span>
                )}
              </div>
            </div>
            <div
              className="register-footer text-center mb-2"
              style={{ marginTop: "3rem" }}
            >
              <button type="submit" className="btn btn-primary">
                조회하기
              </button>
            </div>
          </form>
        )}
      </div>
    </Fragment>
  );
};

export { MemberPoint };
