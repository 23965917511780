import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { Header, LeftSidebar, Footer } from "./layout";

const PageLayout = () => {
  const adminCheck = localStorage.getItem("webId") === "admin" ? true : false;

  return (
    <Fragment>
      <div id="wrapper">
        <LeftSidebar adminCheck={adminCheck} />
        <div id="container-wrapper" className="d-flex flex-column">
          <div id="content" className="h-100">
            <Header adminCheck={adminCheck} />
            <div className="container-fluid titlewrap kiosk">
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default PageLayout;
