import { Fragment, useEffect, useRef, useState } from "react";
import {
  getFormattedDateFull,
  getFormattedPrice,
  convertSymbolToKor,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import $ from "jquery";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";

const Usage = () => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    userInfo,
    setTotalAmount,
    setGoodsName,
    subscriptState,
    regularInfo,
  } = useComponentsContext();

  let date = getFormattedDateFull(new Date());
  const nowDate = date.substring(0, 16);

  const [phoneNo, setPhoneNo] = useState(""); // 발송자 전화번호
  const [regOrDelFncState, setRegOrDelFncState] = useState(false); // 알림톡 번호 삭제 또는 추가 API 함수 호출 useState

  const [userMng, setUserMng] = useState(""); // 최소 금액 발송자 관리
  const limit_case = [0, 100, 200, 300, 400, 500]; // 최소 발송 금액
  const [atalkPhoneNoList, setAtalkPhoneNoList] = useState([]); // 알림톡 발송자 전화번호 리스트

  const [noticePrice, setNoticePrice] = useState([]); // 추가 충전 안내 결제 단위
  const [addPaymentList, setAddPaymentList] = useState([]); // 추가 충전 정보 useState
  const [selectedRow, setSelectedRow] = useState(0); // 추가 충전 상품 선택
  const rowRefs = useRef([]);

  // 알림톡 정보 조회
  const fetchAltalk = () => {
    fetch(`/api/ms/talk/list?st_code=${setToAdminTransAcc().stCode}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setAtalkPhoneNoList(res.data);
        } else {
          setAtalkPhoneNoList([]);
        }
      })
      .catch((error) => console.error(error));
  };

  // 발송 전화번호 삭제 또는 추가 버튼 클릭
  const handleSaveOrDelete = (e) => {
    const type = e.target.innerText;
    setUserMng(type);

    if (type === "추가") {
      const isValidPhoneNo = phoneNo.length === 11 && phoneNo.startsWith("010");
      if (isValidPhoneNo) {
        setIsShowUserCallbackAlert(true);
      } else {
        alert("휴대폰 번호를 정확히 입력해주세요.");
        $("#phoneNoForm").focus();
      }
    } else if (type === "삭제") {
      const delPhoneNo = e.target.closest("tr").childNodes[1].innerText;
      setPhoneNo(delPhoneNo);
      setIsShowUserCallbackAlert(true);
    }
  };

  // 알림톡 번호 삭제 또는 추가 API
  const regOrDelAtalk = () => {
    const apiUrl = userMng === "추가" ? "reg" : "del";

    fetch(`/api/ms/talk/${apiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        st_code: setToAdminTransAcc().stCode,
        phone_no: phoneNo,
      }),
    })
      .then(() => {
        fetchAltalk();
        setPhoneNo("");
        setUserMng("");
        $("#phoneNoForm").val("");
        setRegOrDelFncState(false);
      })
      .catch((error) => console.error(error));
  };

  // 알림톡 최소 발송 금액 업데이트
  const handleLeftoverChange = (price) => {
    fetch("/api/ms/limitamt/up", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        st_code: setToAdminTransAcc().stCode,
        ms_limit_amt: parseInt(price),
      }),
    }).catch((error) => console.error(error));
  };

  // 추가 충전 정보 불러오기
  const fetchAddPayment = () => {
    fetch("/api/ms/prod/list?type=1")
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setAddPaymentList(res.data);

          res.data.map((data) => {
            setNoticePrice((noticePrice) => [
              ...noticePrice,
              convertSymbolToKor(data.price),
            ]);
          });
        }
      })
      .catch((error) => console.error(error));
  };

  // 행 클릭 시 체크박스 체크되도록 스타일 지정
  const handleRowClick = (i) => {
    setSelectedRow(i);
  };

  // 충전하기 버튼 클릭 (상품코드, 상품명, 금액 저장);
  const handleAddChargePayment = () => {
    if (selectedRow === null || !rowRefs.current[selectedRow]) return;
    const { innerText: text, id } = rowRefs.current[selectedRow].childNodes[1];
    const chargePrice = text.replace("추가 ", "").replace("원", "");

    sessionStorage.setItem("msPdCode", id);
    setGoodsName(text);
    setTotalAmount(chargePrice);
    setUserRestStep({ key: 1, control: "basic" });
  };

  useEffect(() => {
    fetchAddPayment();
    fetchAltalk();
  }, []);

  // regOrDelFncState가 true인 경우 삭제 또는 추가 api 호출
  useEffect(() => {
    if (regOrDelFncState) {
      regOrDelAtalk(userMng);
    }
  }, [regOrDelFncState]);

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h5 className="m-0 font-weight-bold">
              서비스 사용 현황
              <span className="now-date">[{nowDate} 현재]</span>
            </h5>
          </div>
          <div className="card-body">
            {Object.keys(userInfo).length > 0 && (
              <>
                <div className="table-responsive">
                  <div className="table-stats order-table ov-h c-table">
                    <table border={"1"} className="info-table resize-table usage-table">
                      <tbody>
                        <tr>
                          <th>
                            <p
                              style={{
                                width: "max-content",
                                margin: "0 4rem",
                              }}
                            >
                              발송 건수
                            </p>
                          </th>
                          <td>
                            <div className="leftover-div">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  className="price leftover form-control"
                                  value={getFormattedPrice(
                                    userInfo.atalk_count
                                  )}
                                  disabled
                                />
                                &nbsp;&nbsp;
                                <b>건</b>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <p
                              style={{
                                width: "max-content",
                                margin: "0 4rem",
                              }}
                            >
                              잔여 건수
                            </p>
                          </th>
                          <td>
                            <div className="leftover-div">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  className="price leftover form-control"
                                  value={getFormattedPrice(
                                    userInfo.remain_atalk
                                  )}
                                  disabled
                                />
                                &nbsp;&nbsp;
                                <b>건</b>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <br />
        {subscriptState && (
          <div
            className={userInfo.use_status === 0 ? "readonly-div" : ""}
            style={{ marginBottom: "3rem" }}
          >
            <div className="card">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h5 className="m-0 font-weight-bold">잔여금액 알림톡 설정</h5>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div className="table-stats order-table ov-h c-table">
                    <table border={"1"} className="info-table leftover-table">
                      <tbody>
                        <tr>
                          <th
                            style={{ height: "45px" }}
                            className="leftover-th"
                          >
                            알림톡 발송 최소 건수
                          </th>
                          <td>
                            <span>
                              <select
                                defaultValue={userInfo.ms_limit_amt}
                                className="price form-control"
                                onChange={(e) => {
                                  handleLeftoverChange(e.target.value);
                                }}
                              >
                                {limit_case.map((cases, i) => {
                                  return (
                                    <option key={i} value={cases}>
                                      {cases === 0 ? "사용안함" : cases + "건"}
                                    </option>
                                  );
                                })}
                              </select>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th className="leftover-th">알림톡 발송 전화번호</th>
                          <td>
                            <table
                              className="table text-center fontS add-table"
                              style={{ margin: "1rem 0" }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ width: "5rem" }}>No</th>
                                  <th style={{ width: "20rem" }}>번호</th>
                                  <th style={{ width: "5rem" }}>관리</th>
                                </tr>
                              </thead>
                              <tbody>
                                {atalkPhoneNoList &&
                                  atalkPhoneNoList.map((phoneNo, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{phoneNo}</td>
                                        <td>
                                          <button
                                            className="btn btn-dark"
                                            onClick={handleSaveOrDelete}
                                          >
                                            삭제
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr>
                                  <td></td>
                                  <td>
                                    <input
                                      id="phoneNoForm"
                                      type="text"
                                      placeholder="'-' 없이 입력해주세요."
                                      className="form-control"
                                      onKeyUp={(e) =>
                                        (e.target.value =
                                          e.target.value.replace(/[^0-9]/g, ""))
                                      }
                                      onChange={(e) =>
                                        setPhoneNo(e.target.value)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn btn-dark"
                                      onClick={handleSaveOrDelete}
                                    >
                                      추가
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr />
        <div className="card" style={{ marginTop: "3rem" }}>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <div className="title">
                  <h5>추가 충전 안내</h5>
                </div>
                <ul className="notice-ul">
                  {Object.keys(regularInfo).length > 0 && (
                    <>
                      <li>
                        기본 서비스 가입 시 <b>최대 발송 가능 수량 </b>[
                        <span className="highlight">
                          카카오톡 기준{" "}
                          {getFormattedPrice(regularInfo[0].atalk)}건
                          <small> (건당 {regularInfo[0].atalk_price}원)</small>
                        </span>
                        ]
                      </li>
                    </>
                  )}
                  {noticePrice.length > 0 && (
                    <li>
                      기본 금액 소진 시, 추가 충전 건에 대해서는{" "}
                      {noticePrice.join(", ")} 단위로 결제가 가능합니다.
                    </li>
                  )}
                  <li>
                    결제된 금액은 환불 처리되지 않사오니 잔여 금액은 모두
                    사용하는 것을 권장드립니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div
          className={
            "card " + `${userInfo.use_status === 0 ? "readonly-div" : ""}`
          }
        >
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h5 className="m-0 font-weight-bold">추가 충전</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table className="table text-center fontS add-table">
                  <thead>
                    <tr>
                      <th scope="col" rowSpan={"2"}>
                        선택
                      </th>
                      <th scope="col" rowSpan={"2"}>
                        결제금액
                      </th>
                      <th scope="col">카카오톡 최대 발송 수량</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addPaymentList.length > 0 &&
                      addPaymentList.map((list, i) => {
                        return (
                          <tr
                            className="addPayment-tr"
                            key={i}
                            ref={(el) => (rowRefs.current[i] = el)}
                            onClick={() => handleRowClick(i)}
                          >
                            <td>
                              <input
                                type="radio"
                                name="add-charge"
                                checked={selectedRow === i}
                                onChange={() => handleRowClick(i)}
                              />
                            </td>
                            <td id={list.ms_pd_code}>{list.ms_pd_name}</td>
                            <td>{getFormattedPrice(list.atalk)}건</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="charge-btn btn btn-primary"
                    onClick={handleAddChargePayment}
                  >
                    충전하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowUserCallbackAlert &&
        UserCallbackAlert(
          `해당 휴대폰 번호를 ${userMng}하시겠습니까?`,
          [],
          "확인",
          true,
          userMng === "추가" ? "notice" : "caution",
          function () {
            regOrDelAtalk();
          }
        )}
    </Fragment>
  );
};

export default Usage;
