import { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { BsXLg } from "react-icons/bs";
import {
  getFormattedPrice,
  handleKeyPress,
  setParamterInteger,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";
import InputTag from "../../../../../common/utils/components/InputTag";

const UsePolicyModifyModal = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
  } = useComponentsContext();

  const { control, handleSubmit, register, reset, setValue } = useForm();
  const formDefaultValues = {
    po_name: "",
    card_min_amt: 0,
    cash_min_amt: 0,
    max_use_crt: "ONCE",
    max_use_point: 0,
  };

  const [receiveExistCheck, setReceiveExistCheck] = useState(false);

  const [buttonTxt, setButtonTxt] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");

  // 저장 버튼 클릭 시 기존 값보다 작은 지 확인
  const handleModifyInfo = (data) => {
    data.st_code = setToAdminTransAcc().stCode;
    setUserRequestDatas(data);

    // 금액 포맷
    const formatData = ["card_min_amt", "cash_min_amt", "max_use_point"];
    formatData.map((format) => {
      data[format] = setParamterInteger(data[format]);
    });

    // 띄울 알림 종류 선택
    let type = receiveExistCheck ? "수정" : "등록";
    setButtonTxt(type);
    setNoticeTitle(`사용 정책을 ${type}하시겠습니까?`);
    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    let controlByType = buttonTxt === "등록" ? "reg" : "update";
    setUserRestStep({ key: 31, control: controlByType });
    setButtonTxt("");
  };

  // 선택한 회원이 있는 경우에만 조회
  useEffect(() => {
    if (Object.keys(props.selectedUsePolicy).length > 0) {
      reset(props.selectedUsePolicy);
      setReceiveExistCheck(true);
    }
  }, [props.selectedUsePolicy]);

  // 모달이 닫힌 경우 alert 비활성화 및 form, 선택한 회원 정보 리셋
  useEffect(() => {
    if (!props.isShowUsePolicyModal) {
      props.setSelectedUsePolicy({});
      setReceiveExistCheck(false);
      setNoticeAlert(false);
      reset(formDefaultValues);
    }
  }, [props.isShowUsePolicyModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowUsePolicyModal}
        onHide={() => {
          props.setIsShowUsePolicyModal(false);
        }}
        className="mod-modal"
      >
        <Modal.Header>
          <Modal.Title>사용정책관리</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowUsePolicyModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="point-modal-body">
            <div className="order-table ov-h c-table">
              <table className="usermng-mod-table usepol-table">
                <tbody>
                  <tr>
                    <th style={{ width: "10rem" }}>정책명</th>
                    <td>
                      <input
                        id="po_name"
                        type="text"
                        className="form-control"
                        placeholder="정책명"
                        {...register("po_name")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "10rem" }}>카드결제 최소금액</th>
                    <td>
                      <Controller
                        name="card_min_amt"
                        control={control}
                        render={({ field }) => (
                          <InputTag
                            {...field}
                            id="card_min_amt"
                            className="form-control text-right"
                            min={0}
                            max={9999999999}
                            reset-value="card_min_amt"
                            setValue={setValue}
                            required
                          />
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "10rem" }}>현금결제 최소금액</th>
                    <td>
                      <Controller
                        name="cash_min_amt"
                        control={control}
                        render={({ field }) => (
                          <InputTag
                            {...field}
                            id="cash_min_amt"
                            className="form-control text-right"
                            min={0}
                            max={9999999999}
                            reset-value="cash_min_amt"
                            setValue={setValue}
                            required
                          />
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>사용가능 한도</th>
                    <td>
                      <div className="d-flex">
                        <select
                          className="form-control col-lg-4 mr-2"
                          {...register("max_use_crt")}
                          required
                        >
                          <option value="ONCE">1회</option>
                          <option value="DAILY">1일</option>
                        </select>
                        <Controller
                          name="max_use_point"
                          control={control}
                          render={({ field }) => (
                            <InputTag
                              {...field}
                              id="max_use_point"
                              className="form-control text-right"
                              min={0}
                              max={9999999999}
                              reset-value="max_use_point"
                              setValue={setValue}
                              required
                            />
                          )}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              저장
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowUsePolicyModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { UsePolicyModifyModal };
