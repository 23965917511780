import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../../../../assets/css/reservation-menu.scss";
import { useComponentsContext } from "../../../ContextProvider";

const ReservationPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/user/reservation") {
      navigate("resveMng");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default ReservationPage;
