import React, { Fragment, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useComponentsContext } from "../../../components/ContextProvider";
import {
  getFormattedFirstDay,
  getFormattedLastDayByMonthly,
  settingByDatePeriod,
  settingSelectedPeriod,
  yearlyBox,
  yearlyPeriodCheck,
} from "../FunctionUtility";

// 조회 기간 input/select 포맷
const DateOrYearInput = ({
  isStart,
  isYearly,
  id,
  name,
  value,
  onChange,
  dateType,
  yearlyBox,
}) => (
  <div className="input-con col-lg-4">
    {isYearly ? (
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control"
      >
        {yearlyBox().map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    ) : (
      <input
        type={dateType}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="form-control"
      />
    )}
    {isStart && <span>~</span>}
  </div>
);

const DatePeriodTag = ({
  currentMenu,
  startDt,
  setStartDt,
  endDt,
  setEndDt,
}) => {
  const { setPeriodUpdate, searchPeriod, setSearchPeriod } =
    useComponentsContext();

  const periodRef = useRef({});

  const [dateType, setDateType] = useState("date"); // 검색 타입

  // 조회 기간 button 포맷
  const renderPeriodButtons = () => {
    let periods = [];
    if (currentMenu && currentMenu === "statistics") {
      periods = ["오늘", "이번달", "일별", "월별", "연도별"];
    } else {
      periods = ["오늘", "이번달", "올해"];
    }

    return periods.map((period, index) => (
      <button
        key={index}
        id={period}
        type="button"
        className={`btn btn-outline-secondary period_search_css ${
          currentMenu !== "statistics" ? "mr-1" : ""
        } ${period === "일별" ? "ml-4 active" : ""}`}
        onClick={(e) => handlePeriodInquiry(e, period)}
        ref={(el) => {
          if (el) periodRef.current[period] = el;
        }}
      >
        {period}
      </button>
    ));
  };

  const handleDateChange = (e) => {
    e.preventDefault();
    const inputId = e.target.id;
    const value = e.target.value;
    if (inputId === "between_day") {
      setStartDt(value);
    } else {
      setEndDt(value);
    }
  };

  // 기간 검색 포맷
  const handlePeriodInquiry = (e, period) => {
    let settingDate = {};
    if (period === "오늘" || period === "이번달") {
      settingDate = settingSelectedPeriod(period);
    } else {
      settingDate = settingByDatePeriod(period);
    }
    setStartDt(settingDate.startDt);
    setEndDt(settingDate.endDt);

    // 메뉴가 통계인 경우 설정
    if (currentMenu === "statistics") {
      periodActiveSet(period);

      // 변경되는 값에 따라서 액티브 표시
      let selectAllBtn = document.querySelectorAll(".period_search_css");
      selectAllBtn.forEach((btn) => {
        btn.classList.remove("active");
      });
      if (period === "오늘" || period === "이번달") {
        document.getElementById("일별").classList.add("active");
      } else {
        e.currentTarget.classList.add("active");
      }
    }

    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (searchPeriod === "yearly" && yearlyPeriodCheck(startDt, endDt)) {
      alert(
        "연도별 검색 시 시작연도와 종료연도의 기간이 5년 내로 되도록 설정해주세요."
      );
      return;
    }

    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };

  /**
   * ## 조건에 따라 일자 조회
   *    a. 일별 - 한달 검색
   *    b. 월별 - type: month
   *    c. 연도별 - selectbox 변경
   * @param searchPeriod (조회 조건)
   * @param dateType (input 태그 타입 || selectbox)
   */
  const periodActiveSet = (period) => {
    switch (period) {
      case "월별":
        setDateType("month");
        setSearchPeriod("monthly");
        break;
      case "연도별":
        setDateType("yearly");
        setSearchPeriod("yearly");
        break;
      default:
        setDateType("date");
        setSearchPeriod("daily");
        break;
    }
  };

  /*************************************************** */
  /** ## 조건에 따라 일자 제한
   *    a. 일별 - 한달 검색
   *    b. 월별 - type: month / 월 기준 1년
   *    c. 연도별 - selectbox 전환 ± 5년
   */
  // startDt값에 맞춰서 조회 조건 별 기간 제한
  useEffect(() => {
    // startDt와 endDt가 동일한 경우는 제외
    if (currentMenu === "statistics" && startDt !== endDt) {
      const endCheck = endDt.split("-");
      const startCheck = startDt.split("-");

      switch (searchPeriod) {
        case "daily":
          const endDtSetting = getFormattedLastDayByMonthly(startDt);
          setEndDt(endDtSetting);
          break;
        case "monthly":
          if (startCheck[0] !== endCheck[0]) {
            setEndDt(`${startCheck[0]}-12`);
          }
          break;
        case "yearly":
          break;
      }
    }
  }, [startDt]);

  // endDt값에 맞춰서 조회 조건 별 기간 제한
  useEffect(() => {
    // startDt와 endDt가 동일한 경우는 제외
    if (currentMenu === "statistics" && startDt !== endDt) {
      const endCheck = endDt.split("-");
      const startcheck = startDt.split("-");

      switch (searchPeriod) {
        case "daily":
          if (startcheck[1] !== endCheck[1]) {
            setStartDt(getFormattedFirstDay(endCheck[1]));
          }
          break;
        case "monthly":
          if (startcheck[0] !== endCheck[0]) {
            setStartDt(`${endCheck[0]}-01`);
          }
          break;
        case "yearly":
          break;
      }
    }
  }, [endDt]);

  // 초기 선택 값
  useEffect(() => {
    if (currentMenu === "statistics") document.getElementById("일별").click();

    return () => {
      setSearchPeriod("daily");
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`${
          currentMenu === "statistics" ? "col-lg-12" : "col-lg-9"
        } period-search pl-0`}
      >
        <div
          className={
            currentMenu === "statistics" ? "col-lg-5" : "col-lg-8 mb-2"
          }
        >
          <span style={{ marginRight: "8px" }}>조회기간</span>
          {renderPeriodButtons()}
        </div>
        <div
          className={currentMenu === "statistics" ? "col-lg-7" : "col-lg-12"}
        >
          <DateOrYearInput
            isStart={true}
            isYearly={dateType === "yearly"}
            id="between_day"
            name="between_day"
            value={startDt}
            onChange={handleDateChange}
            dateType={dateType}
            yearlyBox={yearlyBox}
          />
          <DateOrYearInput
            isYearly={dateType === "yearly"}
            id="and_day"
            name="and_day"
            value={endDt}
            onChange={handleDateChange}
            dateType={dateType}
            yearlyBox={yearlyBox}
          />
          <div className="input-con col-lg-4 col-lg-3-1">
            <button
              type="submit"
              className="btn btn-outline-secondary search-btn"
              onClick={handleSearchClick}
            >
              <BsSearch style={{ fontWeight: "bold" }} />
              &nbsp; 조회
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DatePeriodTag;
