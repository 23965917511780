import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import $ from "jquery";
import { useComponentsContext } from "../ContextProvider";

/**
 * 관리자
 * 로그인 - Admin 로그아웃
 * 가맹점 전환 - 매장 : 매장명 (결제기코드) 이전 | Admin 로그아웃
 *
 * 사용자
 * 로그인 - 매장 : 매장명(결제기코드) | 웹 아이디 로그아웃
 */
const Header = (props) => {
  const { setUserInfo } = useComponentsContext();
  const navigator = useNavigate();

  const adminToCustAcc = localStorage.getItem("adminToCustAcc");
  const transAccountInfo = localStorage.getItem("transAccountInfo");
  const parseAccInfo = JSON.parse(transAccountInfo);

  const webId = localStorage.getItem("webId");
  const stCode = localStorage.getItem("stCode");
  const custName = localStorage.getItem("custName");

  const initChecked =
    localStorage.getItem("isInitCheck") === "true" ? true : false; // 체크박스 체크 여부

  // 모바일 환경 - 햄버거 버튼 클릭 시 사이드바 토글
  const handleToggledSidebar = () => {
    $("#accordionSidebar").toggleClass("toggled");
    $("body").toggleClass("sidebar-toggled");
  };

  // 매장 정보 세팅
  const custInfoSetting = () => {
    return adminToCustAcc === "ACCESS"
      ? `${nullCheck(parseAccInfo.custName)} (${nullCheck(
          parseAccInfo.stCode
        )})`
      : `${nullCheck(custName)} (${nullCheck(stCode)})`;
  };
  // data null 체크
  const nullCheck = (item) => {
    return item ? item : "";
  };

  // 로그아웃
  const handleLogout = () => {
    location.href = "/login";
    alert("로그아웃 되었습니다.");

    if (initChecked === false) {
      localStorage.removeItem("webId");
      localStorage.removeItem("isInitCheck");
    }

    let removeItem = [
      "checkedLogin",
      "stCode",
      "custName",
      "stType",
      "initFetchCheck",
      "adminToCustAcc",
      "transAccountInfo",
      "subscriptState",
    ];
    removeItem.forEach((item) => {
      localStorage.removeItem(item);
    });
  };

  // 관리자 계정 전환
  const handleTransAdmin = () => {
    if (props.adminCheck) {
      localStorage.removeItem("adminToCustAcc");
      localStorage.removeItem("transAccountInfo");
      setUserInfo([]);
      alert("관리자 계정으로 전환되었습니다.");
      location.href = "/manager/services/userMng";
    }
  };

  return (
    <Fragment>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={handleToggledSidebar}
        >
          <FaBars />
        </button>
        <div className="navbar_wrap">
          <ul className="navbar-nav ml-auto">
            {(!props.adminCheck ||
              (props.adminCheck && adminToCustAcc === "ACCESS")) && (
              <>
                <li className="nav-item nav-item-po"></li>
                <li className="nav-item">
                  <div className="nav-link">
                    <span className="mr-4 d-none d-lg-inline text-gray-600 small">
                      <b>매장 : </b>
                      {custInfoSetting()}
                    </span>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#uaddmodal_ST_top"
                    >
                      <img
                        className="img-profile rounded-circle"
                        src={require("../../assets/images/profile.png")}
                        alt=""
                      />
                    </a>
                  </div>
                </li>
                <li className="nav-item">
                  {props.adminCheck && adminToCustAcc === "ACCESS" && (
                    <a className="nav-link" href="#" onClick={handleTransAdmin}>
                      <FaSignOutAlt className="icon-logout" />
                      <span
                        className="nav-tit"
                        style={{ fontWeight: "normal" }}
                      >
                        이전
                      </span>
                    </a>
                  )}
                </li>
              </>
            )}
            <div className="topbar-divider d-none d-sm-block"></div>
            <li className="nav-item">
              <div className="nav-link">
                <span className="mr-4 d-none d-lg-inline text-gray-600 small">
                  {nullCheck(props.adminCheck ? stCode : webId)}
                </span>
                <a href="#" data-toggle="modal" data-target="#uaddmodal_ST_top">
                  <img
                    className="img-profile rounded-circle"
                    src={require("../../assets/images/profile.png")}
                    alt=""
                  />
                </a>
              </div>
            </li>
            <li className="nav-item">
              <a href="#" className="nav-link" onClick={handleLogout}>
                <FaSignOutAlt className="icon-logout" />
                <span className="nav-tit" style={{ fontWeight: "normal" }}>
                  로그아웃
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </Fragment>
  );
};

export { Header };
