import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  handleKeyPress,
  setParamterInteger,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";

const AccmlPolicyModifyModal = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
  } = useComponentsContext();

  const { control, handleSubmit, register, reset, watch, setValue } = useForm({
    defaultValues: {
      gd_level: 0,
      gd_name: "",
      earning_point: 0,
      card_crt_amt: 0,
      card_crt_unit: 0,
      card_criteria: "P",
      cash_crt_amt: 0,
      cash_crt_unit: 0,
      cash_criteria: "P",
      is_active: "true",
    },
  });
  const cardCriteriaCheck = watch("card_criteria");
  const cashCriteriaCheck = watch("cash_criteria");

  const isActiveType = [
    { name: "사용", value: "true" },
    { name: "미사용", value: "false" },
  ];

  // 라디오 버튼 클릭에 따라서 비활성화된 행의 input disabeld처리
  const RadioInputGroup = ({ title, type, checkedCriteria }) => (
    <tr>
      <th>{title}</th>
      <td>
        {["P", "%"].map((value, i) => (
          <label key={value} className="d-flex align-items-center mt-2 mb-2">
            <input
              className="mr-3"
              type="radio"
              name={`${type}_criteria`}
              value={value}
              {...register(`${type}_criteria`, {
                onChange: () => {
                  setValue(`${type}_crt_amt`, 0);
                  setValue(`${type}_crt_unit`, 0);
                },
              })}
            />
            <div className="d-flex justify-content-sm-between align-items-center">
              <Controller
                name={checkedCriteria === value ? `${type}_crt_amt` : ""}
                control={control}
                defaultValue={
                  checkedCriteria === value ? `${type}_crt_amt` : ""
                }
                render={({ field }) => (
                  <InputTag
                    {...field}
                    className="form-control col-lg-4 text-right"
                    min={0}
                    max={99999999999}
                    disabled={checkedCriteria !== value}
                    reset-value={
                      checkedCriteria === value ? `${type}_crt_amt` : ""
                    }
                    setValue={setValue}
                  />
                )}
              />
              <span className={`ml-1 ${value === "P" ? "mr-4" : "mr-2"}`}>
                {value === "P" ? "원 당" : "원 이상"}
              </span>
              <Controller
                name={checkedCriteria === value ? `${type}_crt_unit` : ""}
                control={control}
                defaultValue={
                  checkedCriteria === value ? `${type}_crt_unit` : ""
                }
                render={({ field }) => (
                  <InputTag
                    {...field}
                    className="form-control col-lg-4 text-right"
                    min={0}
                    max={value === "%" ? 100 : 99999999999}
                    disabled={checkedCriteria !== value}
                    reset-value={
                      checkedCriteria === value ? `${type}_crt_unit` : ""
                    }
                    setValue={setValue}
                  />
                )}
              />
              <span className="ml-1">
                {value === "P" ? "P 적립" : "% 적립"}
              </span>
            </div>
          </label>
        ))}
      </td>
    </tr>
  );

  // 저장 버튼 클릭 시 기존 값보다 작은 지 확인
  const handleModifyInfo = (data) => {
    delete data.gd_name;
    data.is_active = JSON.parse(data.is_active);
    // 컴마 제거 적용
    const formatData = [
      "card_crt_amt",
      "card_crt_unit",
      "cash_crt_amt",
      "cash_crt_unit",
    ];
    formatData.map((format) => {
      data[format] = setParamterInteger(data[format]);
    });

    setUserRequestDatas({
      st_code: setToAdminTransAcc().stCode,
      pointEarnInfos: [data],
    });

    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    setUserRestStep({ key: 30, control: "update" });
  };

  useEffect(() => {
    if (
      props.isShowAccmlModal &&
      Object.keys(props.selectedAccmlPolicy).length > 0
    ) {
      // reack-hook-form에서 boolean값을 감지 못해 String으로 변환
      props.selectedAccmlPolicy.is_active = String(
        props.selectedAccmlPolicy.is_active
      );
      reset(props.selectedAccmlPolicy);
    }
  }, [props.isShowAccmlModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowAccmlModal}
        onHide={() => {
          props.setIsShowAccmlModal(false);
        }}
        className="mod-modal"
      >
        <Modal.Header>
          <Modal.Title>적립정책관리</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowAccmlModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="point-modal-body">
            <div className="order-table ov-h c-table">
              <table className="usermng-mod-table accml-modal-table">
                <tbody>
                  <tr>
                    <th style={{ width: "10rem" }}>적용등급</th>
                    <td>
                      <input
                        id="gd_name"
                        type="text"
                        className="form-control col-lg-6"
                        placeholder="적용등급"
                        disabled
                        {...register("gd_name")}
                      />
                    </td>
                  </tr>
                  <RadioInputGroup
                    title="카드결제 적립기준"
                    type="card"
                    checkedCriteria={cardCriteriaCheck}
                  />
                  <RadioInputGroup
                    title="현금결제 적립기준"
                    type="cash"
                    checkedCriteria={cashCriteriaCheck}
                  />
                  <tr>
                    <th>사용여부</th>
                    <td>
                      <div className="d-flex">
                        {isActiveType &&
                          isActiveType.map((type) => {
                            return (
                              <label className="mr-5" key={type.name}>
                                <input
                                  className="mr-3"
                                  type="radio"
                                  name="is_active"
                                  value={type.value}
                                  disabled={
                                    props.selectedAccmlPolicy.gd_level === 1
                                      ? true
                                      : false
                                  }
                                  {...register("is_active")}
                                />
                                {type.name}
                              </label>
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              저장
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowAccmlModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(
          "해당 적립 정책을 수정하시겠습니까?",
          [],
          "확인",
          true,
          "notice",
          function () {
            noticeFunction();
          }
        )}
    </Fragment>
  );
};

export { AccmlPolicyModifyModal };
