import React, { Fragment } from "react";

const Footer = ({ locationLogin }) => {
  const style = locationLogin
    ? {
        position: "absolute",
        zIndex: 100,
        marginTop: "8rem",
        backgroundColor: "#eee0",
        color: "#fff",
      }
    : {};

  return (
    <Fragment>
      <footer style={style}>
        {locationLogin && <hr style={{ border: "1px solid #fff" }} />}
        <div className="footer-info">
          <div className="footer-group com-info">
            <h5 style={{ marginBottom: "0" }}>
              <b>회사 기본정보</b>
            </h5>
            <div className="footer-sub-group">
              <span>
                <b>상호명</b>(주)담다
              </span>
              <span>
                <b>대표자명</b>류지창
              </span>
              <span>
                <b>사업장 주소</b>
                [39370] 경북 구미시 1공단로 217 2층 (주)담다
              </span>
            </div>
            <div className="footer-sub-group">
              <span>
                <b>대표 전화</b>1833-7425
              </span>
              <span>
                <b>사업자 등록번호</b>422-81-01299
              </span>
            </div>
            <div className="footer-sub-group">
              <span>
                <b>통신판매업 신고번호</b>제 2018-대구 중구-0527 호
              </span>
              <span>
                <b>개인정보보호책임자</b>고은영
              </span>
            </div>
            <div></div>
          </div>
          <div className="footer-group customer-group">
            <h5>
              <b>고객센터 정보</b>
            </h5>
            <span>
              <b style={{ marginRight: "0.5rem" }}>상담/주문 전화</b>1833-7425
            </span>
            <div>
              <b>상담/주문 이메일</b>
              <p>1833-7425@damdaa.com</p>
            </div>
            <div>
              <b>CS 운영시간</b>
              <p>월~금 09:00~17:00 (점심시간: 12:00~13:00)</p>
            </div>
          </div>
          <div className="footer-group">
            <h5>
              <b>결제 정보</b>
            </h5>
            <div className="payinfo">
              <b>무통장 계좌정보</b>
              <p>농협회원조합 301023827511 주식회사 담다</p>
            </div>
          </div>
        </div>
        {/* 버전 정보 표시 */}
        <div
          style={{
            position: "absolute",
            right: "10px",
            bottom: "10px",
            fontSize: "0.9rem",
            color: "#777",
          }}
        >
          {process.env.REACT_APP_LAST_RELEASE}_v
          {process.env.REACT_APP_VERSION}
        </div>
      </footer>
    </Fragment>
  );
};

export { Footer };
