import $ from "jquery";
import { useState } from "react";
import {
  FaAngleDown,
  FaAngleRight,
  FaCalendarAlt,
  FaCoins,
  FaComments,
  FaMoneyBill,
  FaUserFriends,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useComponentsContext } from "../ContextProvider";

const AccordionMenu = ({
  list,
  isManager,
  isShowSubMenu,
  setIsShowSubMenu,
  handleToggle,
}) => {
  const { subscriptState } = useComponentsContext();

  // 메뉴별 아이콘
  const iconByMenu = (path) => {
    switch (path) {
      case "member":
        return <FaUserFriends />;
      case "services":
        return <FaComments />;
      case "reservation":
        return <FaCalendarAlt />;
      case "point":
        return <FaCoins />;
      case "coupon":
        return <FaMoneyBill />;
      default:
        null;
    }
  };

  /****************** 관리자 **********************/
  // 관리자 path별 경로 이동
  const moveTheMngPathByMng = (path, menu) => {
    return `/manager/${path}${menu ? `/${menu}` : ""}`;
  };

  /****************** 사용자 **********************/
  // 사용자 사이드바 메뉴 클릭
  const moveTheMngPathByUser = (path, menu) => {
    return `/user/${path}/${menu}`;
  };

  // 메뉴 클릭 시 모바일 화면에서 플로팅 메뉴 숨김처리
  const handleOnClickFnc = (e, menu) => {
    if (
      (menu === "usage" || menu === "payHistory") &&
      subscriptState === "UNSUB"
    ) {
      e.preventDefault();
      alert(
        "서비스가 가입 되어 있지 않습니다.\n먼저 가입정보 메뉴에서 정기결제를 등록해주시길 바랍니다."
      );
      return;
    }

    const sidebarClassCheck = $("#accordionSidebar").hasClass("toggled");
    const sidebarMobileCheck = $("#accordionSidebar").hasClass("mobile");
    if (sidebarClassCheck || sidebarMobileCheck) {
      setIsShowSubMenu(null);
    }
  };

  return (
    <li key={list.path} className="nav-item interface-item">
      <Link
        className="nav-link"
        to={
          isManager && list.path === "coupon"
            ? moveTheMngPathByMng(list.path)
            : "#"
        }
        onClick={() => handleToggle(list.path)}
      >
        {iconByMenu(list.path)}
        <span>{list.title}</span>
        {list.subMenuList &&
          list.subMenuList.length > 0 &&
          (isShowSubMenu === list.path ? (
            <FaAngleDown
              className="menu-accordion"
              style={{ float: "right", marginTop: "6px" }}
            />
          ) : (
            <FaAngleRight
              className="menu-accordion"
              style={{ float: "right", marginTop: "6px" }}
            />
          ))}
      </Link>
      {list.subMenuList && (
        <div
          id="collapsePages"
          className={`collapse ${isShowSubMenu === list.path ? "show" : ""}`}
          aria-labelledby="headingPages"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            {list.subMenuList.map((sub) => (
              <Link
                key={sub.name}
                className="collapse-item"
                onClick={(e) => handleOnClickFnc(e, sub.id)}
                to={
                  isManager
                    ? moveTheMngPathByMng(list.path, sub.id)
                    : moveTheMngPathByUser(list.path, sub.id)
                }
              >
                {sub.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </li>
  );
};

export const AccordionItem = (menuList, isManager) => {
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  const handleToggle = (selectKey) => {
    setIsShowSubMenu(isShowSubMenu === selectKey ? null : selectKey);
  };

  return menuList.list.map((list) => (
    <AccordionMenu
      key={list.path}
      list={list}
      isManager={isManager}
      isShowSubMenu={isShowSubMenu}
      setIsShowSubMenu={setIsShowSubMenu}
      handleToggle={handleToggle}
    />
  ));
};
