import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import QRCode from "qrcode.react";
import { setToAdminTransAcc } from "../FunctionUtility";

const CouponShow = () => {
  const [genQrInfo, setGenQrInfo] = useState([]);
  const [renderingResult, setRenderingResult] = useState(false);

  const [isMsgPopupVisible, setIsMsgPopupVisible] = useState(false);

  // GET으로 넘어오는 쿼리스트링 가져오기
  const params = new URLSearchParams(location.search);
  const getStCode = params.get("st_code");
  const cpCode = params.get("cp_code");
  const psName = params.get("ps_name");
  const cpName = params.get("cp_name");
  const startDt = params.get("start_dt");
  const endDt = params.get("end_dt");

  const [custName, setCustName] = useState("");

  useLayoutEffect(() => {
    generationQrCode();
  }, []);

  // QR 코드 생성
  const generationQrCode = () => {
    // props에서 받아온 정보 토대로 생성
    if (getStCode && cpCode) {
      fetch(`/api/coupon/info?st_code=${getStCode}&coupon_code=${cpCode}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.result && Object.keys(res.data).length > 0) {
            setCustName(res.data.cust_name);

            const startDt = res.data.start_dt.replaceAll("-", ".");
            const endDt = res.data.end_dt.replaceAll("-", ".");
            setGenQrInfo({
              cp_code: cpCode,
              ps_name: setToAdminTransAcc().custName,
              cp_name: res.data.cp_name,
              use_dt: `${startDt} ~ ${endDt}`,
            });
          }
        });
    } else {
      setGenQrInfo({
        cp_code: cpCode,
        ps_name: psName,
        cp_name: cpName,
        use_dt: `${startDt} ~ ${endDt}`,
      });
    }
    setRenderingResult(true);
  };

  // 우클릭 시 컨텍스트 메뉴
  const handleShowContextMenu = (e) => {
    e.preventDefault();
    // 클릭 위치에 contextMenu 표시
    const contextMenu = document.getElementById("contextMenu");
    contextMenu.style.left = e.nativeEvent.clientX + "px";
    contextMenu.style.top = e.nativeEvent.clientY + "px";
    contextMenu.style.display = "block";
  };

  // url 복사
  const handleUrlCopy = async (e) => {
    e.preventDefault();
    try {
      // 현재 url 복사
      await navigator.clipboard.writeText(location.href);
      // contextMenu 숨김 처리 및 복사 안내 알림
      document.getElementById("contextMenu").style.display = "none";
      setIsMsgPopupVisible(!isMsgPopupVisible);
      if (!isMsgPopupVisible) {
        setTimeout(() => {
          setIsMsgPopupVisible(false);
        }, 1000);
      }
    } catch (e) {
      alert("클립보드 복사 실패! 다시 시도하여주세요.");
    }
  };

  return (
    <Fragment>
      {renderingResult && Object.keys(genQrInfo).length > 0 && (
        <div className="coupon-container d-flex flex-column justify-content-sm-between">
          <div className="p-4">
            <div className="w-100 h-100 d-flex flex-column align-items-center coupon-body p-4">
              <h4
                className="mb-5 d-flex align-items-center"
                style={{ fontWeight: "900" }}
              >
                <small className="mr-3" style={{ fontSize: "13px" }}>
                  ◆
                </small>{" "}
                {custName ? custName : genQrInfo.ps_name}
                <small className="ml-3" style={{ fontSize: "13px" }}>
                  ◆
                </small>
              </h4>
              <div className="show-coupon mb-5 d-flex flex-column align-items-center w-80">
                <div className="w-100 text-center">
                  <h4>{genQrInfo.cp_name}</h4>
                </div>
                <div className="pl-2 pr-2 pb-5 pt-5 d-flex justify-content-center align-items-center flex-column">
                  {genQrInfo && (
                    <QRCode
                      value={genQrInfo.cp_code}
                      onContextMenu={handleShowContextMenu}
                    />
                  )}
                  <span className="mt-1" style={{ fontWeight: "900" }}>
                    {cpCode ? cpCode : genQrInfo.cpCode}
                  </span>
                </div>
              </div>
              <p className="mb-1">※ 기간 : {genQrInfo.use_dt}</p>
              <p className="mb-1">※ 해당 할인 쿠폰을 직원에게 제시해주세요.</p>
            </div>
          </div>
        </div>
      )}
      <div id="contextMenu">
        <a href="#" onClick={handleUrlCopy}>
          URL 복사
        </a>
      </div>
      {isMsgPopupVisible && (
        <div className="pop_container">
          <div className="msg_popup">
            <p>URL 복사완료</p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CouponShow;
