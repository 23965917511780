import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  handleKeyPress,
  korRegularExpFnc,
  phoneRemoveHyphen,
} from "../../../../../common/utils/FunctionUtility";

const MemberChangePw = ({ stCode }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      contact: "",
      password: "",
      change_password: "",
    },
  });
  const getStCode = localStorage.getItem("acc_st_code");

  const [showDefaultPassword, setShowDefaultPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  // 비밀번호 변경
  const handleChangePw = async (data) => {
    if (window.confirm("비밀번호를 변경하시겠습니까?")) {
      data.st_code = getStCode;
      data.contact = phoneRemoveHyphen(data.contact);

      const validate = await validateChangePw(data);
      if (validate) {
        alert("비밀번호 변경이 완료되었습니다.");
        history.back();
      }
    }
  };

  // 비밀번호 검증
  const validateChangePw = (data) => {
    return fetch(`/api/member/password/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          return true;
        } else {
          setTimeout(() => {
            errorMsg(res.message);
          }, 500);
          return false;
        }
      })
      .catch((error) => {
        console.error(error);
        alert(
          "비밀번호 변경 과정에서 오류가 발생했습니다.\n다시 시도 해주세요."
        );
      });
  };

  // 에러메시지 표시
  const errorMsg = (message) => {
    switch (message) {
      case "FAILED_NONE_MEMBER":
        alert("존재 하지 않는 회원입니다.");
        break;
      case "INVALID_PASSWORD":
        alert("현재 비밀번호가 일치하지 않습니다.");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stCode) {
      localStorage.setItem("acc_st_code", stCode);
    }

    return () => {
      localStorage.removeItem("acc_st_code");
      localStorage.removeItem("acc_url");
    };
  }, []);

  return (
    <Fragment>
      <div className="register-container mt-4">
        <form
          onSubmit={handleSubmit(handleChangePw)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <div
            className="register-form mt-3"
            style={{ marginBottom: "2.2rem" }}
          >
            <h4 className="text-center">
              <b>비밀번호 변경</b>
            </h4>
          </div>
          <div className="mt-3">
            <span>
              <small className="color-red mr-1">*</small>연락처
            </span>
            <div
              className="register-form-input d-flex"
              style={{ marginBottom: "0" }}
            >
              <InputTag
                id="contact"
                type="tel"
                required
                placeholder="연락처"
                {...register("contact")}
              />
            </div>
          </div>
          <div className="mt-3">
            <span>
              <small className="color-red mr-1">*</small>현재 비밀번호
            </span>
            <div
              className="register-form-input d-flex"
              style={{ marginBottom: "0" }}
            >
              <input
                id="password"
                type="number"
                placeholder="현재 비밀번호 입력"
                style={{ width: "91%" }}
                className={showDefaultPassword ? "" : "password-show-type"}
                onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
                onInput={(e) => {
                  if (e.currentTarget.value.length > e.currentTarget.maxLength)
                    e.currentTarget.value = e.currentTarget.value.slice(
                      0,
                      e.currentTarget.maxLength
                    );
                }}
                maxLength={4}
                required
                {...register("password", {
                  validate: (value) =>
                    value.length == 4 || "비밀번호는 4자리입니다.",
                })}
              />
              <label
                onClick={() => setShowDefaultPassword(!showDefaultPassword)}
                className="show-pw-label"
              >
                {showDefaultPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </label>
              {errors.password && (
                <span className="text-danger">{errors.password.message}</span>
              )}
            </div>
          </div>
          <div className="mt-4">
            <span>
              <small className="color-red mr-1">*</small>변경 비밀번호
            </span>
            <div
              className="register-form-input d-flex"
              style={{ marginBottom: "0" }}
            >
              <input
                id="change_password"
                type="number"
                placeholder="변경 비밀번호 입력"
                style={{ width: "91%" }}
                className={showChangePassword ? "" : "password-show-type"}
                onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
                onInput={(e) => {
                  if (e.currentTarget.value.length > e.currentTarget.maxLength)
                    e.currentTarget.value = e.currentTarget.value.slice(
                      0,
                      e.currentTarget.maxLength
                    );
                }}
                maxLength={4}
                required
                {...register("change_password", {
                  validate: (value) =>
                    value.length == 4 || "비밀번호는 4자리로 입력해주세요.",
                })}
              />
              <label
                onClick={() => setShowChangePassword(!showChangePassword)}
                className="show-pw-label"
              >
                {showChangePassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </label>
              {errors.change_password && (
                <span className="text-danger">
                  {errors.change_password.message}
                </span>
              )}
            </div>
          </div>
          <p className="mt-2" style={{ wordBreak: "keep-all" }}>
            <small>
              가입 시 비밀번호를 설정하지 않은 경우, 초기 비밀번호는 휴대폰
              뒷자리 4자리입니다.
            </small>
          </p>
          <div
            className="register-footer text-center mb-2"
            style={{ marginTop: "3rem" }}
          >
            <button type="submit" className="btn btn-primary">
              변경하기
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export { MemberChangePw };
