import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";

const ReservationCalendar = (props) => {
  const { setLoading } = useComponentsContext();
  const widthSize = document.body.offsetWidth;

  const calendarRef = useRef(null);

  const [startDt, setStartDt] = useState(getFormattedFirstDay());
  const [endDt, setEndDt] = useState(getFormattedLastDay());
  const [showResveList, setShowResveList] = useState([]);

  // 캘린더 예약 목록 조회
  const calendarFetchData = () => {
    setLoading(true);
    fetch(
      `/api/reservation/list?st_code=${
        setToAdminTransAcc().stCode
      }&start_dt=${startDt}&end_dt=${endDt}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          let eventsDataSet = [];
          res.data.map((list) => {
            let resveDt = list.reservation_dt.split(" ");
            eventsDataSet.push({
              reservationNo: list.reservation_no,
              title: `${resveDt[1].substring(0, 5)} ${list.name} 성인${
                list.adult
              } 유아${list.infant}`,
              date: resveDt[0],
              borderColor:
                list.status !== "NO_SHOW" && list.atalk_send_flag
                  ? "#17a673"
                  : "#a3a4af",
              backgroundColor:
                list.status !== "NO_SHOW" && list.atalk_send_flag
                  ? "#17a673"
                  : "#a3a4af",
            });
          });
          setShowResveList(eventsDataSet);
        } else {
          setResveListInfo([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleDatesSet = (arg) => {
    const startMonth = arg.view.currentStart;
    const getMonth = startMonth.getMonth() + 1;
    setStartDt(getFormattedFirstDay(getMonth));
    setEndDt(getFormattedLastDay(getMonth));
  };

  useEffect(() => {
    calendarFetchData();
  }, [startDt, endDt]);

  useEffect(() => {
    calendarFetchData();

    // 자식창에서 메시지 호출 받은 경우
    window.addEventListener("message", function (e) {
      if (e.data.request === "refetch") {
        calendarFetchData();
        props.setIsShowResveModal(false);
        props.research();
      }
    });
  }, []);

  return (
    <Fragment>
      <div className="card mt-3">
        <div className="card-body pb-3 pt-3">
          <div
            className="calendar-container"
            style={{
              width: `${widthSize > 1000 ? "80%" : "100%"}`,
              margin: "0 auto",
            }}
          >
            <FullCalendar
              ref={calendarRef}
              locale="ko"
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              fixedWeekCount={false}
              // 예약 데이터
              events={showResveList}
              // 날짜 클릭
              dateClick={(arg) => {
                props.setSelectedResveDt(arg.dateStr);
                props.setSelectedResveNo("");
                props.setIsShowResveModal(true);
              }}
              // 예약정보 클릭
              eventClick={(info) => {
                props.setSelectedResveNo(
                  info.event._def.extendedProps.reservationNo
                );
                props.setIsShowResveModal(true);
              }}
              // 예약 등록 버튼 커스텀
              customButtons={{
                resveEncrollButton: {
                  text: "예약 등록",
                  click: () => {
                    props.setSelectedResveNo("");
                    props.setIsShowResveModal(true);
                  },
                },
              }}
              // 헤더 툴바 표시
              headerToolbar={{
                left: "title,prev,today,next",
                right: "resveEncrollButton",
              }}
              datesSet={handleDatesSet}
              // 날짜 형식에서 '일'을 제거하고 숫자만 표시
              dayCellContent={(args) => {
                return args.date.getDate();
              }}
              dayMaxEventRows={true}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export { ReservationCalendar };
