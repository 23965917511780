import { Fragment, useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { setToAdminTransAcc } from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

const ServicesPage = () => {
  const { setUserInfo, setRegularInfo, subsState, setSubsState } =
    useComponentsContext();

  const location = useLocation();
  const navigate = useNavigate();

  // 사용자 등록 정보 확인
  const fetchData = () => {
    fetch(`/api/ms/info?st_code=${setToAdminTransAcc().stCode}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.result && Object.keys(res.data).length > 0) {
          setUserInfo(res.data);
          setSubsState(true);
        } else {
          setSubsState(false);
        }
      })
      .catch((error) => console.error(error));
  };

  // 서비스 정기결제 상품 확인
  const fetchProdBasicPrice = () => {
    fetch("/api/ms/prod/list?type=0")
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setRegularInfo(res.data);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchData();
    fetchProdBasicPrice();
  }, []);

  useEffect(() => {
    if (
      (location.pathname.includes("usage") ||
        location.pathname.includes("payHistory")) &&
      !subsState
    ) {
      alert(
        "서비스가 가입 되어 있지 않습니다.\n먼저 가입정보 메뉴에서 정기결제를 등록해주시길 바랍니다."
      );
      history.back();
    }

    if (location.pathname === "/user/services") {
      subsState ? navigate("usage") : navigate("subInfo");
    }
  }, [subsState, location.pathname]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default ServicesPage;
