// 관리자 계정 전환 stCode 데이터 세팅
export function setToAdminTransAcc() {
  const adminToCustAcc = localStorage.getItem("adminToCustAcc");
  const transAccountInfo = localStorage.getItem("transAccountInfo");
  const parseAccInfo = JSON.parse(transAccountInfo);

  const stCode = localStorage.getItem("stCode");
  const custName = localStorage.getItem("custName");

  let accountSetting = {
    stCode: stCode,
    custName: custName,
  };

  return adminToCustAcc === "ACCESS" && Object.keys(parseAccInfo).length > 0
    ? parseAccInfo
    : accountSetting;
}

// 가격 포맷 (천단위 표시 + 단위)
export function getFormattedPrice(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * 날짜 포맷 (YYYY-MM-DD)
 * @returns
 */
// 기본
export function getFormattedDateHyphen() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function getFormattedDateHyphenGetDate(date) {
  const getDate = new Date(date);
  const year = getDate.getFullYear();
  const month = String(getDate.getMonth() + 1).padStart(2, "0");
  const day = String(getDate.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
// 이번 달 첫째 날
export function getFormattedFirstDay(getMonth) {
  const date = new Date();
  const year = date.getFullYear();
  const month = getMonth
    ? String(getMonth).padStart(2, "0")
    : String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}-01`;
}
// 이번 달 마지막 날
export function getFormattedLastDay(getMonth) {
  const date = new Date();
  const year = date.getFullYear();
  const month = getMonth
    ? String(getMonth).padStart(2, "0")
    : String(date.getMonth() + 1).padStart(2, "0");

  const day = new Date(year, month, 0);
  const lastDay = String(day.getDate()).padStart(2, "0");
  return `${year}-${month}-${lastDay}`;
}

// 일별 첫째 날 구하기
export function getFormattedFirstDayByMonthly(year, month) {
  return `${year}-${month.padStart(2, "0")}-01`;
}

// 일별 마지막 날 구하기
export function getFormattedLastDayByMonthly(getMonthly) {
  const setDate = getMonthly.split("-");
  const lastDay = new Date(setDate[0], setDate[1], 0); // 해당 월의 마지막 날
  return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(lastDay.getDate()).padStart(2, "0")}`;
}

// 연도별 기간 구하기
export function yearlyPeriodCheck(start, end) {
  const setStartDate = new Date(start, 1, 1);
  const setEndDate = new Date(end, 12, 31);

  if (setEndDate.getFullYear() - 6 - setStartDate.getFullYear() >= 1) {
    return true;
  } else {
    return false;
  }
}

// 날짜 포맷 (YYYY-MM-DD HH:MM:SS)
export function getFormattedDateFull(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// 날짜 포맷 (YYYYMMDD)
export function getFormattedDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
}
// 날짜 포맷 (YYYY.MM.DD)
export function getFormattedDateDot(date) {
  return date.replaceAll("-", ".");
}

// 조회 기간 날짜 세팅
export function settingSelectedPeriod(btnName) {
  const year = new Date().getFullYear();

  let periodObj = {};

  switch (btnName) {
    case "오늘":
      periodObj.startDt = getFormattedDateHyphen();
      periodObj.endDt = getFormattedDateHyphen();
      break;
    case "이번달":
      periodObj.startDt = getFormattedFirstDay();
      periodObj.endDt = getFormattedLastDay();
      break;
    case "올해":
      periodObj.startDt = `${year}-01-01`;
      periodObj.endDt = `${year}-12-31`;
      break;
  }

  return periodObj;
}

// 조회 조건 포맷 세팅
export function settingByDatePeriod(btnName) {
  const year = new Date().getFullYear();

  let periodObj = {};

  switch (btnName) {
    case "일별":
      periodObj.startDt = getFormattedFirstDay();
      periodObj.endDt = getFormattedLastDay();
      break;
    case "월별":
      periodObj.startDt = `${year}-01`;
      periodObj.endDt = `${year}-12`;
      break;
    case "연도별":
      periodObj.startDt = `${year}`;
      periodObj.endDt = `${year}`;
      break;
  }
  return periodObj;
}

// 조회 기간 포맷 세팅
export function formattedPeriod(searchPeriod, startDt, endDt) {
  let formattedStartDt = startDt;
  let formattedEndDt = endDt;
  switch (searchPeriod) {
    case "monthly":
      formattedStartDt = `${startDt}-01`;
      formattedEndDt = getFormattedLastDayByMonthly(endDt);
      break;
    case "yearly":
      formattedStartDt = `${startDt}-01-01`;
      formattedEndDt = `${endDt}-12-31`;
      break;
  }

  return `&start_dt=${formattedStartDt}&end_dt=${formattedEndDt}&period=${searchPeriod}`;
}

// 연도별 selectbox
export function yearlyBox() {
  const year = new Date().getFullYear();
  const yearlyArr = [];
  for (let i = year - 5; i <= year + 5; i++) {
    yearlyArr.push(i);
  }
  return yearlyArr;
}

// input 값에 대해서 HH:mm:ss 형태로 시간 반환
export function getTransDateTime(period, hour, minute, seconds) {
  let parseIntHour = parseInt(hour);

  if (period === "PM" && parseIntHour < 12) {
    parseIntHour += 12;
  } else if (period === "AM" && parseIntHour === 12) {
    parseIntHour = 0;
  }

  // 숫자를 항상 두 자리로 포맷
  return `${formatDate(parseIntHour)}:${formatDate(minute)}:${formatDate(
    seconds
  )}`;
}

// DB에서 받아온 일자 AM/PM HH시 mm분 형태로 반환
export function getTransDatePeriodTime(date) {
  const splitDate = date.split(":");
  let setPeriod = "";
  let parseIntHour = parseInt(splitDate[0]);

  if (parseIntHour > 12) {
    setPeriod = "PM";
    parseIntHour -= 12;
  } else {
    setPeriod = "AM";
  }

  return `${setPeriod}/${parseIntHour}/${formatDate(splitDate[1])}`;
}

function formatDate(date) {
  return String(date).padStart(2, "0");
}

// 랜덤 ID 생성 (기존 PHP 소스 로직)
export function getRandomId() {
  const timestamp = Math.floor(Date.now() / 1000); // 현재 시간을 초 단위로 가져옴
  const microtime = Date.now() % 1000; // 마이크로초 대신 밀리초를 사용
  let returnValue =
    timestamp.toString() + microtime.toString().padStart(3, "0"); // timestamp와 microtime을 문자열로 합침
  returnValue = "B_" + returnValue.split("").reverse().join(""); // 문자열을 뒤집고 "G_" 접두어 추가
  return returnValue;
}

/**
 * 결과값 포맷
 */
// 컴마 제거
export function commaRemovePrice(price) {
  let noneCommaPrice = price.replaceAll(",", "");
  return Number(noneCommaPrice);
}
export function setParamterInteger(price) {
  return String(price).includes(",") ? commaRemovePrice(price) : Number(price);
}
// 할부개월 세팅
export function setInstMonth(instMonth) {
  return instMonth === 0 ? "일시불" : instMonth + " 개월";
}
// 날짜 T제거
export function setFormattedDate(date) {
  return date.replace("T", " ");
}
// 값이 존재하지 않는 경우 "-"으로 표시시
export const emptyToHyphen = (data) => {
  return !data || data === "0" || data === 0 ? "-" : data;
};
// use_status, 서비스 기간에 따른 텍스트 표시
export function setUseStatus(statusType) {
  let returnMsg = "";
  if (statusType === 5) {
    returnMsg = " (정기결제 중단)";
  } else if (statusType === 9) {
    returnMsg = " (정기결제 실패)";
  }
  return returnMsg;
}

// 현재 사용중인지 여부, 해지사유에 따른 텍스트 표시
export const getUnscribedReason = (subsState, useStatus) => {
  if (subsState) {
    if (useStatus === 5) {
      return " (정기결제 중단 - 만료시점까지 잔여금액 사용)";
    } else if (useStatus === 9) {
      return " (정기결제 실패 - 만료시점까지 잔여금액 사용)";
    }
  } else {
    if (useStatus === 5) {
      return " (사용자 해지)";
    } else if (useStatus === 9) {
      return " (결제 실패로 인한 해지)";
    }
  }
};

// 포인트 poiint_type 에 따른 구분 텍스트 표시
export function useTypeKor(useType) {
  let msg = "";
  switch (useType) {
    case "PAY":
      msg = "결제";
      break;
    case "GRADE":
      msg = "등급 달성 포인트";
      break;
    case "MANAGER":
      msg = "관리자 지급";
      break;
  }
  return msg;
}
// 포인트 transaction_type 에 따른 구분 텍스트 표시
export function transTypeKor(transactionType) {
  let msg = "";
  switch (transactionType) {
    case "EARN":
      msg = "적립";
      break;
    case "USE":
      msg = "사용";
      break;
  }
  return msg;
}
// 쿠폰 status 에 따른 구분 텍스트 표시
// export function couponStatusKor(status) {
//   let msg = "";
//   switch (status) {
//     case 1:
//       msg = "";
//       break;
//     case 2:
//       msg = "";
//       break;
//     case 3:
//       msg = "취소";
//       break;
//     case 4:
//       msg = "취소";
//       break;
//     default:
//       msg = "취소";
//       break;
//   }
//   return msg;
// }

// 금액 한글 단위 변환 함수
export function convertSymbolToKor(amount) {
  const units = ["", "만"];
  const numStr = String(amount).split("").reverse().join("");
  const parts = [];

  for (let i = 0; i < numStr.length; i += 4) {
    parts.push(
      numStr
        .slice(i, i + 4)
        .split("")
        .reverse()
        .join("")
    );
  }

  parts.reverse();

  const result = parts
    .map((part, index) => {
      if (parseInt(part) !== 0) {
        const num = parseInt(part);
        let unit = units[parts.length - 1 - index];
        if (unit === "" && num % 1000 === 0) {
          return `${num / 1000}천`;
        }
        return `${num}${unit}`;
      }
      return null;
    })
    .filter(Boolean);

  return result + "원";
}

// 알림톡 서비스 가입 체크
export function subscriptStateCheck(menu, settingUrl) {
  return fetch(`/api/ms/info?st_code=${settingUrl}`)
    .then((res) => res.json())
    .then((res) => {
      if (res.result && Object.keys(res.data).length > 0) {
        if (menu === "reservation") {
          return true;
        } else if (menu === "coupon") {
          const remainAmtCheck = res.data.remain_amt < res.data.atalk_price;
          return remainAmtCheck ? "insufficient" : true;
        }
      } else {
        return false;
      }
    });
}

// 등급 목록 조회
export function getGradeList() {
  return fetch(`/api/grade/list/combo?st_code=${setToAdminTransAcc().stCode}`)
    .then((res) => res.json())
    .then((res) => {
      let gradeList = [];
      if (res.result) {
        gradeList = res.data;
      }
      return gradeList;
    });
}

// 발행 쿠폰 확인
export function handleShowCouponQR(cpCode) {
  const showUrl = `/show/coupon?st_code=${
    setToAdminTransAcc().stCode
  }&cp_code=${cpCode}`;
  window.open(showUrl, "_blank", "width=412, height=587");
}

// 자릿수 제한 정규식 함수
export function digitsRegularExpFnc(max, target) {
  // 현재 입력된 값이 max를 초과하면 max 값으로 제한
  if (max && parseInt(target.value) > max) {
    target.value = max;
  }

  if (max && target.value.length > max.toString().length) {
    target.value = target.value.slice(0, max.toString().length);
  }
}
// 한글 제한 정규식 함수
export function korRegularExpFnc(target) {
  target.value = target.value.replace(/[^0-9]/g, "");
}

// 전화번호 정규식 표현
export function phoneRegularExpFnc(contact) {
  return contact.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
}
// 전화번호 하이픈 제거
export function phoneRemoveHyphen(contact) {
  return contact.replaceAll("-", "");
}

// 전화번호 검증
export function valiadateContact(param) {
  return fetch(`/api/member/confirm`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.result) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
      alert("가입에 실패하였습니다. 다시 시도해주세요.");
    });
}

// form 에서 엔터 클릭 시 onSubmit 함수가 실행되는 것 방지
export function handleKeyPress(event) {
  if (event.key === "Enter") {
    event.preventDefault();
  }
}
