import { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { BsSearch, BsXLg } from "react-icons/bs";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  handleKeyPress,
  setParamterInteger,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { MngCallbackAlert } from "../../../utils/alert/MngAlertForm";

const CouponSendModal = (props) => {
  const {
    setLoading,
    isShowMngCallbackAlert,
    setIsShowMngCallbackAlert,
    setMngRestStep,
    setMngRequestDatas,
  } = useComponentsContext();

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  const formDefaultValues = {
    cp_name: "",
    cp_comment: "",
    cp_type: "MULTI",
    use_type: "INFINITE",
    use_limit: 0,
    discount_type: "AMOUNT",
    discount_unit: 0,
    start_dt: getFormattedFirstDay(),
    end_dt: getFormattedLastDay(),
    showList: {
      selectAll: false,
      st_code: "",
      cust_name: "",
    },
  };

  const endDt = watch("end_dt");
  const cpTypeCheck = watch("cp_type") === "ONCE" ? true : false;
  const useTypeCheck = watch("use_type") === "FINITE" ? false : true;
  const discountTypeCheck = watch("discount_type");
  const searchStCode = watch("showList.st_code");
  const searchCustName = watch("showList.cust_name");

  // discountTypeCheck 값이 변경될 때마다 discount_unit 0 세팅
  useEffect(() => {
    setValue("discount_unit", 0);
  }, [discountTypeCheck]);

  const [issueAlert, setIssueAlert] = useState(false);

  const [marketList, setMarketList] = useState([]);
  const [selectMarketList, setSelectMarketList] = useState([]);

  const [sendMarketList, setSendMarketList] = useState([]);

  const [selectedCount, setSelectedCount] = useState(0);
  const [stCodeList, setStCodeList] = useState([]);

  const [noticeTitle, setNoticeTitle] = useState("");

  // 매장 정보 조회
  const fetchMarketDatas = () => {
    fetchAndSetData("market/list", setMarketList);
  };

  // 매장 발행 정보 조회
  const fetchSendMarketDatas = () => {
    fetchAndSetData("info", setSendMarketList);
  };

  const fetchAndSetData = (url, callback) => {
    setLoading(true);
    fetch(`/api/manager/coupon/${url}?${setParameter(url)}`)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, i) => (data.id = i + 1));
          const resultData = dataSettings(url, res.data);
          callback(resultData);
        } else {
          setMarketList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const setParameter = (url) => {
    if (url === "market/list") {
      return `st_code=${searchStCode}&cust_name=${searchCustName}`;
    } else {
      return `cp_code=${props.selectedCouponList.cp_code}`;
    }
  };

  const dataSettings = (url, data) => {
    if (url === "market/list") {
      // 체크박스한 값이 변경되지않도록 따로 데이터를 저장
      if (selectMarketList.length === 0) {
        setSelectMarketList(data);
      }
    }
    return data;
  };

  const handleSearchBtnClick = () => {
    fetchMarketDatas();
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
      // fetch로 받아온 데이터에서 검색되도록 해야할듯?
    }
  };

  /******** 체크박스 ********/
  // 모든 checkbox를 체크, 체크해제하는 로직
  const handleSelectAll = (e) => {
    if (e.currentTarget.checked) {
      // selectAll checkbox가 체크되면
      selectMarketList.forEach((item) => {
        setValue(`id.${item.st_code}`, true); // 모든 checkbox의 value를 true로
      });
    } else {
      // selectAll checkbox가 체크해제되면
      selectMarketList.forEach((item) => {
        setValue(`id.${item.st_code}`, false); // 모든 checkbox의 value를 false로
      });
    }
  };

  // checkbox가 다 선택 된 경우 selectAll checkbox가 check
  useEffect(
    () => {
      const selected = selectMarketList.filter(
        (item) => watch(`id.${item.st_code}`) === true
      );

      // 선택된 아이템 stCodeList에 담기
      const selectedIds = selected.map((item) => item.st_code);
      setStCodeList(selectedIds);

      if (
        selectMarketList.length > 0 &&
        selectMarketList.every((item) => watch(`id.${item.st_code}`) === true)
      ) {
        setValue("showList.selectAll", true);
      } else {
        setValue("showList.selectAll", false);
      }
    },
    selectMarketList.map((item) => watch(`id.${item.st_code}`))
  );

  useEffect(() => {
    // 선택된 체크박스 length
    const count = stCodeList.length;
    setSelectedCount(count);
  }, [stCodeList]);

  // 발행 버튼 클릭
  const handleSendSettingInfo = (data) => {
    if (stCodeList.length > 0) {
      ["id", "showList", "send_type"].forEach((value) => {
        delete data[value];
      });
      data.st_list = stCodeList;
      const formData = ["discount_unit", "use_limit"];
      formData.map((format) => {
        data[format] = setParamterInteger(data[format]);
      });
      setMngRequestDatas(data);

      // 띄울 알림 종류 선택
      setNoticeTitle("쿠폰을 발행하시겠습니까?");
      setIsShowMngCallbackAlert(true);
      setIssueAlert(true);
    } else {
      alert("쿠폰을 발송 할 회원을 한 명 이상 선택해주세요.");
    }
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    setMngRestStep(20);
  };

  useEffect(() => {
    if (props.isShowSendModal) {
      if (Object.keys(props.selectedCouponList).length > 0) {
        reset(props.selectedCouponList);
        fetchSendMarketDatas();
      } else {
        fetchMarketDatas();
      }
    }
  }, [props.isShowSendModal, props.selectedCouponList]);

  useEffect(() => {
    if (!props.isShowSendModal) {
      reset(formDefaultValues);
      setMarketList([]);
      setSendMarketList([]);
      setTimeout(() => {
        props.setSelectedCouponList({});
      }, 300);
    }
  }, [props.isShowSendModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowSendModal}
        onHide={() => {
          props.setIsShowSendModal(false);
        }}
        className="mng-coupon-mod-modal resize-mod-modal mod-modal"
      >
        <Modal.Header>
          <Modal.Title>쿠폰 발행</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowSendModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleSendSettingInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="d-flex">
            <div className="float-left mr-4">
              <div
                className="order-table ov-h c-table"
                style={{
                  pointerEvents:
                    Object.keys(props.selectedCouponList).length > 0
                      ? "none"
                      : "",
                }}
              >
                <table className="usermng-mod-table mng-coupon-table">
                  <tbody>
                    <tr>
                      <th style={{ width: "10rem" }}>쿠폰명</th>
                      <td>
                        <input
                          id="cp_name"
                          type="text"
                          className="form-control col-lg-12"
                          placeholder="쿠폰명"
                          {...register("cp_name")}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "10rem" }}>설명</th>
                      <td>
                        <textarea
                          type="text"
                          className="form-control col-lg-12"
                          placeholder="설명"
                          {...register("cp_comment")}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>종류</th>
                      <td>
                        <div className="d-flex">
                          <label className="mr-5">
                            <input
                              className="mr-3"
                              type="radio"
                              name="type"
                              value="MULTI"
                              disabled
                              {...register("cp_type")}
                            />
                            다회성
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>발행 횟수</th>
                      <td>
                        <div className="d-flex align-items-center">
                          <label className="mr-5">
                            <input
                              className="mr-3"
                              type="radio"
                              name="use_type"
                              value="INFINITE"
                              disabled={cpTypeCheck}
                              {...register("use_type")}
                            />
                            <span>무제한</span>
                          </label>
                          <label>
                            <input
                              className="mr-3"
                              type="radio"
                              name="use_type"
                              value="FINITE"
                              disabled={cpTypeCheck}
                              {...register("use_type")}
                            />
                            <span>제한</span>
                          </label>
                          <Controller
                            name="use_limit"
                            control={control}
                            render={({ field }) => (
                              <InputTag
                                {...field}
                                id="use_limit"
                                className="text-right form-control col-lg-3 ml-3 mr-1"
                                disabled={cpTypeCheck ? true : useTypeCheck}
                                min={0}
                                max={9999}
                                reset-value="use_limit"
                                setValue={setValue}
                              />
                            )}
                          />
                          <span>회</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>할인 내용</th>
                      <td>
                        <div className="d-flex">
                          <Controller
                            name="discount_unit"
                            control={control}
                            render={({ field }) => (
                              <InputTag
                                {...field}
                                id="discount_unit"
                                className="form-control text-right col-lg-4 mr-2"
                                min={0}
                                max={
                                  discountTypeCheck === "PERCENT"
                                    ? 100
                                    : 9999999999
                                }
                                reset-value="discount_unit"
                                setValue={setValue}
                              />
                            )}
                          />
                          <select
                            className="form-control col-lg-3"
                            {...register("discount_type")}
                          >
                            <option value="AMOUNT">원</option>
                            <option value="PERCENT">%</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>사용 기간</th>
                      <td>
                        <div className="d-flex align-items-center">
                          <input
                            id="start_dt"
                            type="date"
                            className="form-control col-lg-5 mr-2"
                            {...register("start_dt", {
                              validate: (value) =>
                                value <= endDt ||
                                "시작일자는 종료일자보다 이전으로 설정해주세요.",
                            })}
                          />
                          &nbsp;~&nbsp;&nbsp;
                          <input
                            id="end_dt"
                            type="date"
                            className="form-control col-lg-5 mr-2"
                            {...register("end_dt")}
                          />
                        </div>
                        {errors.start_dt && (
                          <span className="text-danger">
                            {errors.start_dt.message}
                          </span>
                        )}
                      </td>
                    </tr>
                    {/* <tr>
                    <th>시행 여부</th>
                    <td>
                      <div className="d-flex">
                        <label className="mr-5">
                          <input
                            className="mr-3"
                            type="radio"
                            name="status"
                            defaultChecked
                          />
                          예
                        </label>
                        <label>
                          <input className="mr-3" type="radio" name="status" />
                          아니오
                        </label>
                      </div>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="float-right">
              <div className="card" style={{ marginTop: "1.3rem" }}>
                <div className="card-body">
                  <div className="m-0 sch_box mt-2">
                    <div className="row form-group mb-2">
                      <div className="col-lg-3 pr-0">
                        <input
                          type="text"
                          id="search_name"
                          name="search_name"
                          placeholder="매장코드"
                          className="form-control"
                          onKeyDown={keyDownFnc}
                          {...register("showList.st_code")}
                        />
                      </div>
                      <div className="col-lg-3 pr-0">
                        <input
                          type="text"
                          id="search_name"
                          name="search_name"
                          placeholder="매장명"
                          className="form-control"
                          onKeyDown={keyDownFnc}
                          {...register("showList.cust_name")}
                        />
                      </div>
                      <div className="row ml-2">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          //   onClick={handleSearchBtnClick}
                        >
                          <BsSearch style={{ fontWeight: "bold" }} /> &nbsp;
                          조회
                        </button>
                        <span className="ml-2 pt-2 pb-1">
                          선택 : {selectedCount}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="table-resposive">
                    <div className="table-stats order-table ov-h c-table">
                      <div className="order-table ov-h c-table coupon-mod-table">
                        <table className={"table text-center fontS add-table "}>
                          <thead>
                            <tr>
                              <th style={{ width: "45px" }} scope="col">
                                No
                              </th>
                              <th style={{ width: "35px" }} scope="col">
                                <div
                                  className="custom-checkbox"
                                  style={{ paddingLeft: "2rem" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    {...register("showList.selectAll")}
                                    onChange={(e) => handleSelectAll(e)}
                                    id="customCheck"
                                    disabled={
                                      sendMarketList.length > 0 ? true : false
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck"
                                  ></label>
                                </div>
                              </th>
                              <th style={{ width: "80px" }} scope="col">
                                매장코드
                              </th>
                              <th style={{ width: "180px" }} scope="col">
                                매장명
                              </th>
                              <th style={{ width: "90px" }} scope="col">
                                사업자등록번호
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sendMarketList.length > 0 &&
                              sendMarketList.map((list, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{list.id}</td>
                                    <td>
                                      <div
                                        className="custom-checkbox"
                                        style={{ paddingLeft: "2rem" }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={list.st_code}
                                          {...register(`id.${list.st_code}`)}
                                          key={`${list.st_code}`}
                                          disabled={
                                            sendMarketList.length > 0
                                              ? true
                                              : false
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={list.st_code}
                                          style={{ width: "auto" }}
                                        ></label>
                                      </div>
                                    </td>
                                    <td>{list.st_code}</td>
                                    <td>{list.cust_name}</td>
                                    <td>{list.biz_no}</td>
                                  </tr>
                                );
                              })}
                            {marketList.length > 0 &&
                              marketList.map((list, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{list.row_num}</td>
                                    <td>
                                      <div
                                        className="custom-checkbox"
                                        style={{ paddingLeft: "2rem" }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={list.st_code}
                                          {...register(`id.${list.st_code}`)}
                                          key={`${list.st_code}`}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={list.st_code}
                                          style={{ width: "auto" }}
                                        ></label>
                                      </div>
                                    </td>
                                    <td>{list.st_code}</td>
                                    <td>{list.cust_name}</td>
                                    <td>{list.biz_no}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="w-100">
            <button type="submit" className="btn btn-primary">
              발행
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowSendModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowMngCallbackAlert &&
        issueAlert &&
        MngCallbackAlert(noticeTitle, "", "확인", "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export default CouponSendModal;
