import DamdaaLogo from "../../assets/images/logo/damdaa-logo.svg";
import YeogiyoLogo from "../../assets/images/logo/yeogiyo-logo.svg";
import DaitdaLogo from "../../assets/images/logo/daitda-logo.svg";

export function getCname() {
  const params = new URLSearchParams(location.search);
  const cname = params.get("cname");

  // 로고 이미지 URL 설정
  let logoUrl = DamdaaLogo;

  // 회사 구분 정보 로컬 스토리지 저장
  localStorage.setItem("stType", cname ? cname.toUpperCase() : "DAMDAA");

  document.documentElement.style.setProperty("--logo-img", `url('${logoUrl}')`);
}
