import React, { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./App.css";
import { getCname } from "./common/utils/SetAccordingToCname";

// 공통
import PageLoading from "./common/utils/PageLoading";
import { ContextProvider } from "./components/ContextProvider";
import Login from "./components/Login";
import PageLayout from "./components/PageLayout";

/************************* 사용자 *************************/
import UserPage from "./components/user/UserPage";
// 알림톡 서비스
import ServicesPage from "./components/user/menus/services/UserServicesPage";
import SubscriptionInfo from "./components/user/menus/services/SubscriptionInfo";
import Usage from "./components/user/menus/services/Usage";
import PaymentHistory from "./components/user/menus/services/PaymentHistory";

// 회원관리
import MemberMngPage from "./components/user/menus/membermng/MemberMngPage";
import MemberInfoMng from "./components/user/menus/membermng/MemberInfoMng";
import MemberGradeMng from "./components/user/menus/membermng/MemberGradeMng";
import MemberStatistics from "./components/user/menus/membermng/MemberStatistics";
// 회원 계정 페이지 (회원가입, 포인트 조회, 비밀번호 변경)
import MemberAccount from "./components/user/menus/membermng/account/MemberAccount";

// 포인트
import PointPage from "./components/user/menus/point/PointPage";
import AccumlationPolicyMng from "./components/user/menus/point/AccumulationPolicyMng";
import UsePolicyMng from "./components/user/menus/point/UsePolicyMng";
import PointHistory from "./components/user/menus/point/PointHistory";
import PointStatistics from "./components/user/menus/point/PointStatistics";

// 쿠폰
import CouponPage from "./components/user/menus/coupon/CouponPage";
import CouponMng from "./components/user/menus/coupon/CouponMng";
import CouponSendHistory from "./components/user/menus/coupon/CouponSendHistory";
import CouponUseHistory from "./components/user/menus/coupon/CouponUseHistory";
import CouponShowQrCode from "./common/utils/components/CouponShowQrCode";

// 예약관리
import ReservationPage from "./components/user/menus/reservation/ReservationPage";
import ReservationMng from "./components/user/menus/reservation/ReservationMng";
import ReservationStatistics from "./components/user/menus/reservation/ReservationStatistics";

// 프린트, PG
import ReceiptPrint from "./common/print/ReceiptPrint"; // 프린트
import PgResultControl from "./components/user/utils/pg/payment/PgResultControl";

/************************* 관리자 *************************/
import ManagePage from "./components/manager/ManagePage";
import Dashboard from "./components/manager/Dashboard";
// 알림톡 서비스
import MngServicesPage from "./components/manager/menus/services/ServicesPage";
import SettingPrice from "./components/manager/menus/services/SettingPrice";
import UserManagement from "./components/manager/menus/services/UserManagement";
import MngPaymentHistory from "./components/manager/menus/services/PaymentHistory";

// 쿠폰관리
import MngCouponPage from "./components/manager/menus/coupon/CouponMng";

function App() {
  useEffect(() => {
    getCname();
  }, []);

  return (
    <Fragment>
      <Router>
        <ContextProvider>
          <PageLoading />
          <Routes>
            <Route path={"/"} element={<Navigate to="/login" />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/account/*"} element={<MemberAccount />} />

            {/* PG 결과 컨트롤러 */}
            <Route path={"/pg/result"} element={<PgResultControl />} />
            {/* 영수증 프린트 창 */}
            <Route path={"/print"} element={<ReceiptPrint />} />
            {/* 쿠폰 확인 창 */}
            <Route path={"/show/coupon"} element={<CouponShowQrCode />} />

            {/* 공통 레이아웃 */}
            <Route element={<PageLayout />}>
              {/************************* 사용자 *************************/}
              {/* 사용자 페이지 이중 라우터 처리 */}
              <Route path={"/user"} element={<UserPage />}>
                {/* 알림톡 서비스 */}
                <Route path={"services/*"} element={<ServicesPage />}>
                  <Route path={"subInfo"} element={<SubscriptionInfo />} />
                  <Route path={"usage"} element={<Usage />} />
                  <Route path={"payHistory"} element={<PaymentHistory />} />
                </Route>
                {/* 회원관리 */}
                <Route path={"member/*"} element={<MemberMngPage />}>
                  <Route path={"memberInfo"} element={<MemberInfoMng />} />
                  <Route path={"memberGrade"} element={<MemberGradeMng />} />
                  <Route
                    path={"memberStatistics"}
                    element={<MemberStatistics />}
                  />
                </Route>
                {/* 포인트관리*/}
                <Route path={"point/*"} element={<PointPage />}>
                  <Route
                    path={"accmlPolicyMng"}
                    element={<AccumlationPolicyMng />}
                  />
                  <Route path={"usePolicyMng"} element={<UsePolicyMng />} />
                  <Route path={"pointHistory"} element={<PointHistory />} />
                  <Route
                    path={"pointStatistics"}
                    element={<PointStatistics />}
                  />
                </Route>
                {/* 쿠폰관리 */}
                <Route path={"coupon"} element={<CouponPage />}>
                  <Route path={"couponMng"} element={<CouponMng />} />
                  <Route
                    path={"couponSendHistory"}
                    element={<CouponSendHistory />}
                  />
                  <Route
                    path={"CouponUseHistory"}
                    element={<CouponUseHistory />}
                  />
                </Route>
                {/* 예약관리 */}
                <Route path={"reservation"} element={<ReservationPage />}>
                  <Route path={"resveMng/*"} element={<ReservationMng />} />
                  <Route
                    path={"resveStatistics"}
                    element={<ReservationStatistics />}
                  />
                </Route>
              </Route>

              {/************************* 관리자 *************************/}
              {/* 관리자 페이지 이중 라우터 처리 */}
              <Route path={"/manager"} element={<ManagePage />}>
                {/* 대시보드 */}
                <Route path={"dashboard"} element={<Dashboard />} />
                {/* 알림톡 서비스 */}
                <Route path={"services"} element={<MngServicesPage />}>
                  <Route path={"userMng"} element={<UserManagement />} />
                  <Route path={"setPrice"} element={<SettingPrice />} />
                  <Route path={"payHistory"} element={<MngPaymentHistory />} />
                </Route>
                <Route path={"coupon"} element={<MngCouponPage />} />
              </Route>
            </Route>
          </Routes>
        </ContextProvider>
      </Router>
    </Fragment>
  );
}

export default App;
