import { FaFileDownload } from "react-icons/fa";
import XLSX from "xlsx-js-style";
import {
  getFormattedDate,
  getFormattedPrice,
  phoneRegularExpFnc,
} from "../FunctionUtility";

const ExcelDownload = ({ menu, downloadDatas, className = "", style = {} }) => {
  const handleExcelDownload = () => {
    if (menu === "point") {
      if (!downloadDatas || downloadDatas.length === 0) {
        alert("엑셀로 다운로드 할 내역이 없습니다.");
        return;
      }

      try {
        // Excel 파일 생성 및 다운로드
        const wb = XLSX.utils.book_new();
        // 헤더 설정
        const headerStyle = {
          font: {
            bold: true,
            color: { rgb: "000000" },
            name: "맑은 고딕",
            sz: 11,
          },
          fill: { fgColor: { rgb: "5CA0BD" } },
          alignment: { horizontal: "center", vertical: "center" },
          border: {
            left: { style: "thin", color: { auto: 1 } },
            right: { style: "thin", color: { auto: 1 } },
            top: { style: "thin", color: { auto: 1 } },
            bottom: { style: "thin", color: { auto: 1 } },
          },
        };

        // 데이터 설정
        const dataStyle = {
          font: { color: { rgb: "000000" }, name: "맑은 고딕", sz: 11 },
          fill: { fgColor: { rgb: "FFFFFF" } },
          alignment: { horizontal: "center", vertical: "center" },
          border: {
            left: { style: "thin", color: { auto: 1 } },
            right: { style: "thin", color: { auto: 1 } },
            top: { style: "thin", color: { auto: 1 } },
            bottom: { style: "thin", color: { auto: 1 } },
          },
        };

        const headerRow = [
          {},
          { v: "No", t: "s", s: headerStyle },
          { v: "연락처", t: "s", s: headerStyle },
          { v: "회원명", t: "s", s: headerStyle },
          { v: "등급", t: "s", s: headerStyle },
          { v: "구분", t: "s", s: headerStyle },
          { v: "포인트", t: "s", s: headerStyle },
          { v: "잔여 포인트", t: "s", s: headerStyle },
          { v: "등록날짜", t: "s", s: headerStyle },
        ];

        const dataRows = downloadDatas.map((member) => [
          {}, // A행 띄워주는 용
          { v: member.id, t: "s", s: dataStyle }, // No
          {
            v: phoneRegularExpFnc(member.contact) || "-",
            t: "s",
            s: dataStyle,
          }, // 연락처
          { v: member.name || "-", t: "s", s: dataStyle }, // 회원명
          { v: member.gd_name, t: "s", s: dataStyle }, // 등급
          {
            v: setTransactionType(member.transaction_type, member.is_cancel),
            t: "s",
            s: dataStyle,
          }, // 구분
          {
            v: `${getFormattedPrice(member.point)} P` || "-",
            t: "s",
            s: dataStyle,
          }, // 포인트
          {
            v: `${getFormattedPrice(member.remain_point)} P` || "-",
            t: "s",
            s: dataStyle,
          }, // 잔여 포인트
          { v: member.reg_dt || "-", t: "s", s: dataStyle }, // 등록날짜
        ]);

        const rowsData = [
          [], // 1행 띄워주는 용
          headerRow,
          ...dataRows,
        ];

        // 새로운 Sheet 객체 생성
        const ws = XLSX.utils.aoa_to_sheet(rowsData);

        // 각 열의 너비 조절
        // A열의 너비를 25px로 설정 (공간 띄워주는 용)
        const colWidths = [25, 80, 150, 100, 100, 80, 120, 120, 150];
        const cols = colWidths.map((width) => ({ wpx: width }));
        ws["!cols"] = cols;

        // 각 행의 높이 설정
        // +2를 해주는 이유는 공간 띄운 행과 헤더를 더한 행으로 계산
        const totalRows = dataRows.length + 2;
        const rowHeight = 25;
        const rows = Array(totalRows).fill({ hpx: rowHeight });
        ws["!rows"] = rows;

        // workbook에 추가
        XLSX.utils.book_append_sheet(wb, ws, "포인트 내역");

        // 파일 다운로드
        XLSX.writeFile(wb, `포인트_내역_${getFormattedDate()}.xlsx`);
      } catch (error) {
        console.error(error);
        alert("엑셀 다운로드에 실패하였습니다.\n다시 시도 해주세요.");
      }
    } else if (menu === "member") {
      const link = document.createElement("a");
      // link.href = "/담다PAY_회원정보_일괄등록양식.xlsx"; // 파일의 경로를 지정
      link.href = "/register_example.xlsx"; // 파일의 경로를 지정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // 포인트 구분 세팅
  const setTransactionType = (transactionType, isCancel) => {
    if (isCancel) {
      return "취소";
    } else {
      return transactionType === "EARN" ? "적립" : "사용";
    }
  };

  return (
    <button
      type="button"
      className={`${className}`}
      style={style}
      onClick={handleExcelDownload}
    >
      {menu === "point" ? (
        <>
          <FaFileDownload className="mr-2" />
          엑셀 다운로드
        </>
      ) : (
        "다운로드"
      )}
    </button>
  );
};

export default ExcelDownload;
