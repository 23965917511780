import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsPrinterFill, BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import ReceiptDetail from "../../../../common/print/ReceiptDetail";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  setFormattedDate,
  settingSelectedPeriod,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import { MngCallbackAlert } from "../../utils/alert/MngAlertForm";

const PaymentHistory = () => {
  const {
    setLoading,
    setMngRestStep,
    isShowMngCallbackAlert,
    setIsShowMngCallbackAlert,
    setMngRequestDatas,
  } = useComponentsContext();

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      start_dt: getFormattedFirstDay(),
      end_dt: getFormattedLastDay(),
      cust_code: "",
      cust_name: "",
    },
  });
  const startDt = watch("start_dt");
  const endDt = watch("end_dt");
  const inputCustCode = watch("cust_code");
  const inputCustName = watch("cust_name");

  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 결제 내역 리스트 조회
  const [showHistory, setShowHistory] = useState(false); // 상세내역 표시 useState
  const [historyList, setHistoryList] = useState([]); // 결제내역 리스트
  const [historyByPsrInfo, setHistoryByPsrInfo] = useState({}); // psr별 결제 상세내역 리스트

  const listPerPage = 10;
  const [historyKey, setHistoryKey] = useState(0);

  // 결제 내역 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(
      `/api/manager/pay/list?start_dt=${startDt}&end_dt=${endDt}&cust_code=${inputCustCode}&cust_name=${inputCustName}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, index) => (data.id = index + 1));
          setHistoryList(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setValue("start_dt", settingDate.startDt);
    setValue("end_dt", settingDate.endDt);
    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };
  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const historyCurrentTab = getCurrentList(historyList, historyKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setHistoryKey(selected);
  };

  /**
   * 프린트 관련 함수
   */
  // 프린트 아이콘 클릭 시 결제 내역 한 건 조회
  const handlePrintBtnClick = (e) => {
    let stCode = e.currentTarget.parentElement.id;
    let psr = e.currentTarget.id;
    fetch(`/api/payment/info?st_code=${stCode}&ms_psr=${psr}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setHistoryByPsrInfo(res.data);
          setShowHistory(true);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // 자식창에서 메시지 호출 받은 경우  reload
    window.addEventListener("message", function (e) {
      if (e.data.request === "refetch") {
        fetchData();
      }
    });
  }, [startDt, endDt]);

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="title search-div d-flex">
          <div className="col-lg-12 period-search pl-0">
            <div className="col-lg-4">
              <span style={{ marginRight: "8px" }}>조회기간</span>
              <button
                type="button"
                className="btn btn-outline-secondary sales_search_css"
                onClick={handlePeriodInquiry}
              >
                오늘
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary sales_search_css"
                onClick={handlePeriodInquiry}
              >
                이번달
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary sales_search_css"
                onClick={handlePeriodInquiry}
              >
                올해
              </button>
            </div>
            <div className="col-lg-8">
              <div className="input-con col-lg-3">
                <input
                  type="date"
                  id="between_day"
                  name="between_day"
                  className="form-control"
                  {...register("start_dt")}
                />
                <span>~</span>
              </div>
              <div className="input-con col-lg-3">
                <input
                  type="date"
                  id="and_day"
                  name="and_day"
                  className="form-control"
                  {...register("end_dt")}
                />
              </div>
              <div className="input-con col-lg-2">
                <input
                  type="text"
                  placeholder="매장코드"
                  className="form-control"
                  onKeyDown={keyDownFnc}
                  {...register("cust_code")}
                />
              </div>
              <div className="input-con col-lg-2">
                <input
                  type="text"
                  placeholder="매장명"
                  className="form-control"
                  onKeyDown={keyDownFnc}
                  {...register("cust_name")}
                />
              </div>
              <div className="input-con col-lg-2 col-lg-3-1">
                <button
                  type="submit"
                  className="btn btn-outline-secondary search-btn"
                  onClick={handleSearchClick}
                >
                  <BsSearch style={{ fontWeight: "bold" }} />
                  &nbsp; 조회
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={
                    "table text-center fontS add-table " +
                    `${historyList.length > 0 ? "" : "history-table"}`
                  }
                >
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">매장코드</th>
                      <th scope="col">매장명</th>
                      <th scope="col">결제번호</th>
                      <th scope="col">서비스명</th>
                      <th scope="col">구분</th>
                      <th scope="col">금액</th>
                      <th scope="col">승인일시</th>
                      <th scope="col">결제취소</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyCurrentTab.length > 0 &&
                      historyCurrentTab.map((list, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ width: "60px" }}>{list.id}</td>
                            <td style={{ width: "150px" }}>{list.st_code}</td>
                            <td style={{ width: "300px" }}>{list.cust_name}</td>
                            <td style={{ width: "200px" }}>{list.ms_psr}</td>
                            <td id={list.st_code} style={{ width: "200px" }}>
                              {list.ms_pd_name}&nbsp;{" "}
                              <button
                                id={list.ms_psr}
                                className="printer-icon"
                                onClick={handlePrintBtnClick}
                              >
                                <BsPrinterFill />
                              </button>
                            </td>
                            <td style={{ width: "60px" }}>{list.ms_pd_type}</td>
                            <td style={{ width: "150px" }}>
                              {getFormattedPrice(list.amt_tot)}원
                            </td>
                            <td>{setFormattedDate(list.mod_dt)}</td>
                            <td id={list.st_code}>
                              {list.cancel_flag === "0" ? (
                                <button
                                  id={list.ms_psr}
                                  className="btn btn-danger"
                                  onClick={() => {
                                    setIsShowMngCallbackAlert(true);
                                    setMngRequestDatas({
                                      paramStCode: list.st_code,
                                      cancelMsPsr: list.ms_psr,
                                    });
                                  }}
                                >
                                  결제취소
                                </button>
                              ) : (
                                <span className="red-color">취소 완료</span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(historyList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <ReceiptDetail
        receiptInfo={historyByPsrInfo}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
      />
      {isShowMngCallbackAlert &&
        MngCallbackAlert(
          "결제 상태를 취소 처리하시겠습니까?",
          "PG 결제에 대한 취소는 PG 관리자 콘솔을 이용해주세요.",
          "결제취소",
          "caution",
          function () {
            setMngRestStep(10);
          }
        )}
    </Fragment>
  );
};

export default PaymentHistory;
