import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../../../../assets/css/point-menu.scss";

const PointPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/user/point") {
      navigate("accmlPolicyMng");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default PointPage;
