import { Fragment, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import {
  FaCaretDown,
  FaCaretUp,
  FaFileUpload,
  FaPen,
  FaQuestionCircle,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import ReactPaginate from "react-paginate";
import {
  getFormattedPrice,
  getGradeList,
  phoneRegularExpFnc,
  phoneRemoveHyphen,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import {
  MemberInfoModifyModal,
  MemberExcelUpload,
  MemberCouponList,
  MemberPointList,
  PointManualSetting,
} from "./modal";
import { useComponentsContext } from "../../../ContextProvider";
import { useForm } from "react-hook-form";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";
import InputTag from "../../../../common/utils/components/InputTag";

const MemberInfoMng = () => {
  const { register, watch } = useForm({
    defaultValues: {
      name: "",
      contact: "",
      gd_name: "ALL",
    },
  });
  const inputName = watch("name");
  const inputContact = watch("contact");
  const inputGdName = watch("gd_name");

  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();
  const memberRef = useRef({});

  const [memberInfoList, setMemberInfoList] = useState([]);
  const listPerPage = 10;
  const [infoKey, setInfoKey] = useState(0);
  const [memberCurrentTab, setMemberCurrentTab] = useState([]);
  const [sortOrder, setSortOrder] = useState({ type: null, asc: true }); // 정렬 상태 관리

  const [selectedMemberInfos, setSelectedMemberInfos] = useState({});
  const [selectedContact, setSelectedContact] = useState("");

  const [gradeList, setGradeList] = useState([]);

  const [isShowNotice, setIsShowNotice] = useState(false); // 회원 비율 알림 메시지 표시

  // 모달 오픈 useState
  const [isShowMemberInfoModal, setIsShowMemberInfoModal] = useState(false); // 회원정보 등록/수정
  const [isShowExcelModal, setIsShowExcelModal] = useState(false); // 회원 일괄 등록
  const [isShowCouponModal, setIsShowCouponModal] = useState(false); // 쿠폰 리스트
  const [isShowPointModal, setIsShowPointModal] = useState(false); // 포인트 리스트
  const [isShowPointSetModal, setIsShowPointSetModal] = useState(false); // 포인트 적립/차감

  const [staticTables, setStaticTables] = useState([
    {
      title: "전체 고객 수",
      title2: "총 방문횟수",
      title3: "평균 방문횟수",
      datas: ["-", "-", "-"],
    },
    {
      title: "재방문 고객 수",
      title2: "평균 재방문 회원 비율",
      datas: ["-", "-"],
    },
  ]);

  // 등급 리스트 조회
  const getGradeNameList = async () => {
    const gradeList = await getGradeList(setToAdminTransAcc().stCode);
    setGradeList(gradeList);
  };

  // 회원 현황 조회
  const fetchDataState = () => {
    fetchAndSetData("state", setStaticTables);
  };
  // 회원 내역 조회
  const fetchDataByInfo = () => {
    fetchAndSetData("list", setMemberInfoList);
  };

  // 공통 기간검색 fetch
  const fetchAndSetData = (url, callback) => {
    // 가입 고객인 경우 use_status도 포함
    setLoading(true);
    fetch(
      `/api/member/${url}?st_code=${setToAdminTransAcc().stCode}${
        url === "list" ? listSearchParamSet() : ""
      }`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          const resultData = dataSettings(url, res.data);
          callback(resultData);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // fetch별 데이터 세팅
  const dataSettings = (url, data) => {
    if (url === "state") {
      staticTables.map((table, i) => {
        if (i === 0) {
          table.datas = [data.member_cnt, data.visit_cnt, data.avg_visit_cnt];
        } else {
          // data.revisit_rate = `${data.revisit_rate} %`;
          table.datas = [data.revisit_cnt, data.revisit_rate];
        }
      });
      return staticTables;
    } else {
      data.map((list, i) => (list.id = i + 1));
      return data;
    }
  };

  // fetch URL 세팅
  const listSearchParamSet = () => {
    return `&name=${inputName}&contact=${inputContact}&gd_name=${
      inputGdName === "ALL" ? "" : inputGdName
    }`;
  };

  // 검색 버튼 클릭
  const handleSearchBtnClick = () => {
    fetchDataByInfo();
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
    }
  };

  // 회원 정보 확인
  const handleGetListAndOpenModal = (contact, modal) => {
    const currentRefID = memberRef.current[contact].id;
    const getList = memberInfoList.find(
      (member) => member.contact === currentRefID
    );
    getList.contact = phoneRemoveHyphen(getList.contact);
    setSelectedMemberInfos(getList);
    modal === "detail"
      ? setIsShowMemberInfoModal(true)
      : setIsShowPointSetModal(true);
  };

  // 회원 삭제
  const handleDelMember = (contact) => {
    setIsShowUserCallbackAlert(true);
    setCancelAlert(true);
    setUserRequestDatas({
      st_code: setToAdminTransAcc().stCode,
      contact: contact,
    });
  };

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setInfoKey(selected);
  };

  // 테이블 정렬
  const handleSortTable = (e, type) => {
    const sortIcon = e.currentTarget.childNodes[0].classList;
    sortIcon.toggle("asc");
    const isAsc = sortOrder.type === type ? !sortOrder.asc : true; // 기존 타입이라면 방향만 반대로

    const sortedList = [...memberCurrentTab].sort((a, b) => {
      if (a[type] < b[type]) return isAsc ? -1 : 1;
      if (a[type] > b[type]) return isAsc ? 1 : -1;
      return 0;
    });

    setSortOrder({ type, asc: isAsc }); // 정렬 상태 업데이트
    setMemberCurrentTab(sortedList); // 정렬된 리스트 업데이트
  };

  // 페이지 또는 memberInfoList 변경 시 현재 페이지 리스트 업데이트
  useEffect(() => {
    const currentList = getCurrentList(memberInfoList, infoKey);
    setMemberCurrentTab(currentList);
  }, [infoKey, memberInfoList]);

  useEffect(() => {
    getGradeNameList();
    fetchDataState();
    fetchDataByInfo();
  }, []);

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="table-responsive">
          <div className="table-stats order-table ov-h c-table">
            <div className="d-flex statistic-table justify-content-sm-between">
              {staticTables.map((table, i) => {
                return (
                  <table
                    key={i}
                    className="table text-center fontS add-table userinfo-table"
                  >
                    <thead className="thead-dark">
                      <tr>
                        <th>{table.title}</th>
                        <th>
                          <span>{table.title2}</span>
                          {table.title2 === "평균 재방문 회원 비율" && (
                            <span
                              style={{ padding: "0.5rem" }}
                              onMouseOver={() => {
                                setIsShowNotice(true);
                              }}
                              onMouseOut={() => {
                                setIsShowNotice(false);
                              }}
                            >
                              <FaQuestionCircle className="float-right notice-icon" />
                            </span>
                          )}
                          {isShowNotice && (
                            <div className="notice-memo">
                              <p className="mb-1">평균 재방문 회원 비율이란?</p>
                              <p className="mb-1">
                                <b>
                                  (2번 이상 방문한 회원 수 / 전체 회원 수) x 100
                                </b>
                              </p>
                              <p>위 계산식을 통해 계산된 값입니다.</p>
                            </div>
                          )}
                        </th>
                        {table.title3 && <th>{table.title3}</th>}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {table.datas.map((state, i) => {
                          return <td key={i}>{state}</td>;
                        })}
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4 sch_box">
        <div className="row form-group col-lg-9 cc-col-left pl-0 mt-2">
          <div className="col-md-3-1">
            <input
              type="text"
              id="search_name"
              name="search_name"
              placeholder="회원명"
              className="form-control"
              onKeyDown={keyDownFnc}
              {...register("name")}
            />
          </div>
          <div className="col-md-3-1">
            <input
              id="contact"
              type="tel"
              required
              className="form-control col-lg-12"
              placeholder="연락처"
              onKeyDown={keyDownFnc}
              {...register("contact")}
            />
          </div>
          <div className="row col-md-3-1">
            <select
              id="search_grade"
              name="search_grade"
              className="form-control"
              {...register("gd_name")}
            >
              <option value={"ALL"}>등급</option>
              {gradeList.length > 0 &&
                gradeList.map((grade) => {
                  return (
                    <option key={grade.gd_name} value={grade.gd_name}>
                      {grade.gd_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="row col-md-3-1 ml-1">
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={handleSearchBtnClick}
            >
              <BsSearch style={{ fontWeight: "bold" }} /> &nbsp; 검색
            </button>
          </div>
        </div>
        <div className="form-group col-lg-3 float-right px-0 cc-col-right mt-2">
          <button
            type="button"
            className="btn btn-primary float-right"
            onClick={() => setIsShowExcelModal(true)}
          >
            <FaFileUpload />
            &nbsp;회원일괄등록
          </button>
          <button
            type="button"
            className="btn btn-primary float-right usermng-ecroll-btn ml-3 mr-3"
            onClick={() => setIsShowMemberInfoModal(true)}
          >
            <FaStar />
            &nbsp;회원등록
          </button>
        </div>
        <div className="col-lg-12 pl-0 pr-0"></div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={
                    "table text-center fontS add-table resize-table " +
                    `${memberInfoList.length > 0 ? "" : "history-table"}`
                  }
                >
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">
                        <span className="ml-3">이름</span>
                        <span
                          className="member-sort"
                          onClick={(e) => handleSortTable(e, "name")}
                        >
                          <FaCaretDown />
                        </span>
                      </th>
                      <th scope="col">연락처</th>
                      <th scope="col">성별</th>
                      <th scope="col">
                        <span className="ml-2">등급</span>
                        <span
                          className="member-sort"
                          onClick={(e) => handleSortTable(e, "gd_name")}
                        >
                          <FaCaretDown />
                        </span>
                      </th>
                      <th scope="col">
                        <span className="ml-2">방문횟수</span>
                        <span
                          className="member-sort"
                          onClick={(e) => handleSortTable(e, "visit_cnt")}
                        >
                          <FaCaretDown />
                        </span>
                      </th>
                      <th scope="col">
                        <span className="ml-2">최근 방문일자</span>
                        <span
                          className="member-sort"
                          onClick={(e) => handleSortTable(e, "mod_dt")}
                        >
                          <FaCaretDown />
                        </span>
                      </th>
                      <th scope="col">잔여 포인트</th>
                      <th scope="col">쿠폰 내역</th>
                      <th scope="col">포인트 내역</th>
                      <th scope="col">포인트 적립/차감</th>
                      <th scope="col">관리</th>
                    </tr>
                  </thead>
                  <tbody>
                    {memberCurrentTab.length > 0 &&
                      memberCurrentTab.map((list, i) => {
                        return (
                          <tr
                            key={list.contact}
                            id={list.contact}
                            ref={(el) => (memberRef.current[list.contact] = el)}
                          >
                            <td style={{ width: "60px" }}>{i + 1}</td>
                            <td style={{ width: "200px" }}>{list.name}</td>
                            <td style={{ width: "220px" }}>
                              {phoneRegularExpFnc(list.contact)}
                            </td>
                            <td style={{ width: "60px" }}>
                              {list.gender === "M" ? "남" : "여"}
                            </td>
                            <td style={{ width: "150px" }}>{list.gd_name}</td>
                            <td style={{ width: "130px" }}>
                              {getFormattedPrice(list.visit_cnt)} 회
                            </td>
                            <td style={{ width: "270px" }}>{list.mod_dt}</td>
                            <td style={{ width: "150px" }}>
                              {list.remain_point &&
                                getFormattedPrice(list.remain_point) + " P"}
                            </td>
                            <td id="" style={{ width: "100px" }}>
                              <button
                                type="button"
                                className="btn btn-primary margin-auto-center"
                                onClick={() => {
                                  setSelectedContact(list.contact);
                                  setIsShowCouponModal(true);
                                }}
                              >
                                조회
                              </button>
                            </td>
                            <td id="" style={{ width: "100px" }}>
                              <button
                                type="button"
                                className="btn btn-primary margin-auto-center"
                                onClick={() => {
                                  setSelectedContact(list.contact);
                                  setIsShowPointModal(true);
                                }}
                              >
                                조회
                              </button>
                            </td>
                            <td id="" style={{ width: "130px" }}>
                              <button
                                type="button"
                                className="btn btn-primary margin-auto-center"
                                onClick={() =>
                                  handleGetListAndOpenModal(
                                    list.contact,
                                    "point-set"
                                  )
                                }
                              >
                                P
                              </button>
                            </td>
                            <td
                              style={{ width: "130px" }}
                              className="modify-td"
                            >
                              <div className="d-flex justify-content-sm-around align-items-center">
                                <div
                                  title="회원정보수정"
                                  className="modify-icon"
                                  onClick={() =>
                                    handleGetListAndOpenModal(
                                      list.contact,
                                      "detail"
                                    )
                                  }
                                >
                                  <FaPen />
                                </div>
                                <div
                                  title="회원삭제"
                                  style={{ fontSize: "18px" }}
                                  className="modify-icon"
                                  onClick={() => handleDelMember(list.contact)}
                                >
                                  <FaTrash />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(memberInfoList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <MemberInfoModifyModal
        isShowMemberInfoModal={isShowMemberInfoModal}
        setIsShowMemberInfoModal={setIsShowMemberInfoModal}
        selectedMemberInfos={selectedMemberInfos}
        setSelectedMemberInfos={setSelectedMemberInfos}
      />
      <MemberExcelUpload
        isShowExcelModal={isShowExcelModal}
        setIsShowExcelModal={setIsShowExcelModal}
      />
      <MemberCouponList
        isShowCouponModal={isShowCouponModal}
        setIsShowCouponModal={setIsShowCouponModal}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
      />
      <MemberPointList
        isShowPointModal={isShowPointModal}
        setIsShowPointModal={setIsShowPointModal}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
      />
      <PointManualSetting
        isShowPointSetModal={isShowPointSetModal}
        setIsShowPointSetModal={setIsShowPointSetModal}
        selectedMemberInfos={selectedMemberInfos}
        setSelectedMemberInfos={setSelectedMemberInfos}
      />
      {isShowUserCallbackAlert &&
        cancelAlert &&
        UserCallbackAlert(
          "회원 정보를 삭제하시겠습니까?",
          [],
          "확인",
          true,
          "caution",
          function () {
            setUserRestStep({ key: 20, control: "delete" });
            setCancelAlert(false);
          }
        )}
    </Fragment>
  );
};

export default MemberInfoMng;
