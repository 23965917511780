import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar, Line, Pie } from "react-chartjs-2";
import { BsSearch } from "react-icons/bs";
import { useComponentsContext } from "../ContextProvider";
import {
  emptyToHyphen,
  getFormattedDateHyphen,
  getFormattedPrice,
  settingSelectedPeriod,
} from "../../common/utils/FunctionUtility";
import DatePeriodTag from "../../common/utils/components/DatePeriodTag";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const Dashboard = () => {
  const {
    setLoading,
    periodUpdate,
    setPeriodUpdate,
    startDt,
    setStartDt,
    endDt,
    setEndDt,
  } = useComponentsContext();
  const navigate = useNavigate();
  const [adminPageOpen, setAdminPageOpen] = useState(false);

  const settingDay = ["오늘", "이번달", "올해"];
  const staticTables = [
    { title: "고객 현황", subTitle: ["전체", "사용", "미사용"] },
    {
      title: "누적 결제 금액",
      subTitle: ["총 금액", "정기결제", "추가결제"],
    },
  ];

  const [totalRegUser, setTotalRegUser] = useState([]); // 고객 현황
  const [totalPay, setTotalPay] = useState([]); // 누적 결제 금액
  const [dayRegUser, setDayRegUser] = useState([]); // 일별 고객수
  const [dayPay, setDayPay] = useState([]); // 일별 결제 금액

  // total 조회
  const fetchDataTotal = () => {
    // 고객 현황
    fetchAndSetData("reg/total", setTotalRegUser);
    // 누적 결제 금액
    fetchAndSetData("pay/total", setTotalPay);
  };

  // 그래프, 기간 모두 조회
  const fetchDataByPeriod = () => {
    // 일별 가입 고객 수 (그래프)
    fetchAndSetData("reg/day", setDayRegUser);
    // 일별 결제 금액 (그래프)
    fetchAndSetData("pay/day", setDayPay);
  };

  // 공통 기간검색 fetch
  const fetchAndSetData = (url, callback) => {
    // 가입 고객인 경우 use_status도 포함
    setLoading(true);
    fetch(
      `/api/dashboard/${url}${
        url.includes("total") ? "" : `?start_dt=${startDt}&end_dt=${endDt}`
      }`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          const resultData = dataSettings(url, res.data);
          callback(resultData);
        } else {
          alert(
            "데이터를 불러오는 데 실패하였습니다.\n동일한 증상이 반복되는 경우 관리자에 문의바랍니다."
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // datalabels 옵션 세팅
  const datalabels = (type) => {
    let options = {
      labels: {
        value: {
          borderWidth: 2,
          borderRadius: 4,
          font: { size: 15 },
          formatter: function (value, ctx) {
            return value > 0
              ? `${value} ${type === "pay" ? "원" : "명"}`
              : null;
          },
          color: "black",
          backgroundColor: "#eee",
        },
      },
    };

    if (type === "dayReg") {
      options.align = "end";
      options.anchor = "end";
    }
    return options;
  };

  // 테이블, 그래프 데이터 세팅
  const dataSettings = (url, data) => {
    let listArr = [];
    switch (url) {
      case "reg/total":
        if (data) {
          listArr.push(
            emptyToHyphen(data.active_user + data.terminated_user),
            emptyToHyphen(data.active_user),
            emptyToHyphen(data.terminated_user)
          );
        } else {
          listArr.push("-", "-", "-");
        }
        break;
      case "pay/total":
        listArr.push(
          getFormattedPrice(
            emptyToHyphen(data.additional_tot + data.regular_tot)
          ),
          getFormattedPrice(emptyToHyphen(data.additional_tot)),
          getFormattedPrice(emptyToHyphen(data.regular_tot))
        );
        break;
      case "reg/day":
        let regDateArr = [];
        let regCountArr = [];
        data.map((reg) => {
          regDateArr.push(reg.date);
          regCountArr.push(reg.reg_count);
        });
        listArr.push({
          date: regDateArr,
          reg_count: regCountArr,
        });
        break;
      case "pay/day":
        let payDateArr = [];
        let additonalAmtArr = [];
        let regularAmtArr = [];
        data.map((pay) => {
          payDateArr.push(pay.date);
          additonalAmtArr.push(pay.additional_amt);
          regularAmtArr.push(pay.regular_amt);
        });
        listArr.push({
          date: payDateArr,
          additional_amt: additonalAmtArr,
          regular_amt: regularAmtArr,
        });
        break;
    }

    return listArr;
  };

  // 고객 현황 그래프
  const totalUserGraph = {
    labels: ["사용중", "해지"],
    datasets: [
      {
        label: "고객 현황",
        data: [totalRegUser[1], totalRegUser[2]],
        backgroundColor: ["rgba(90, 155, 181, 0.5)", "rgba(66, 66, 67, 0.3)"],
        borderColor: ["rgba(90, 155, 181, 1)", "rgba(66, 66, 67, 1)"],
        borderWidth: 1,
        datalabels: datalabels("reg"),
      },
    ],
  };

  // 일별 가입 고객 그래프
  const dayByDayRegGraph = {
    labels: dayRegUser.length > 0 ? dayRegUser[0].date : [],
    datasets: [
      {
        label: "가입 고객",
        data: dayRegUser.length > 0 ? dayRegUser[0].reg_count : [],
        fill: false,
        borderColor: "rgba(90, 155, 181, 0.7)",
        tension: 1,
        datalabels: datalabels("dayReg"),
      },
    ],
  };

  // 일별 가입 고객 옵션 설정
  const dayRegGraphOptions = {
    responsive: true,
    scales: {
      x: {
        offset: true,
      },
      y: {
        beginAtZero: true,
        suggestedMax: 10, // 데이터 값이 10 이하일 때 최대값을 10으로 설정
        ticks: {
          stepSize: 1, // y축 틱 간격을 1로 설정
          callback: function (value) {
            return Number(value).toFixed(0); // 소수점 없이 표시
          },
        },
      },
    },
  };

  // 막대 그래프 너비 설정
  const percentageSetting = () => {
    if (dayPay.length > 0) {
      const length = dayPay[0].date.length;
      return length === 1 ? 0.4 : length === 2 ? 0.5 : 0.6;
    }
  };

  // 일별 결제 금액 그래프
  const dayByDayPayGraph = {
    labels: dayPay.length > 0 ? dayPay[0].date : [],
    datasets: [
      {
        label: "정기결제",
        data: dayPay.length > 0 ? dayPay[0].regular_amt : [],
        backgroundColor: "rgba(90, 155, 181, 0.5)",
        datalabels: datalabels("pay"),
        barPercentage: percentageSetting(),
        categoryPercentage: percentageSetting(),
      },
      {
        label: "추가결제",
        data: dayPay.length > 0 ? dayPay[0].additional_amt : [],
        backgroundColor: "rgba(66, 66, 67, 0.3)",
        datalabels: datalabels("pay"),
        barPercentage: percentageSetting(),
        categoryPercentage: percentageSetting(),
      },
    ],
  };

  // 결제 금액 그래프 옵션 설정
  const payGraphOptions = {
    responsive: true,
    scales: {
      x: {
        offset: true,
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
        suggestedMax: 25000,
      },
    },
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchDataByPeriod();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  useEffect(() => {
    if (localStorage.getItem("webId") === "admin") {
      fetchDataTotal();
      fetchDataByPeriod();
      setAdminPageOpen(true);
    } else {
      alert("해당 경로로 접근할 수 없습니다.");
      navigate("/login");
      return;
    }

    return () => {
      setAdminPageOpen(false);
    };
  }, []);

  return (
    <Fragment>
      {adminPageOpen ? (
        <>
          <div className="mb-4 alert-light shadow pt-sm-3 pb-sm-3 pp-m1 cf menu-header">
            <div className="col-sm-2 title_box">
              <h1 className="h5 mb-0 text-gray-800 ml-3 float-l">DASHBOARD</h1>
            </div>
            <DatePeriodTag
              startDt={startDt}
              setStartDt={setStartDt}
              endDt={endDt}
              setEndDt={setEndDt}
            />
          </div>
          <div className="col-lg-12 pl-0 pr-0">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div className="table-stats order-table ov-h c-table">
                    <div className="d-flex dashboard-table justify-content-sm-between">
                      {staticTables.map((table, i) => {
                        return (
                          <table
                            key={i}
                            className="table text-center fontS add-table"
                          >
                            <thead className="thead-dark">
                              <tr>
                                <th colSpan="3">{table.title}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {table.subTitle.map((sub, i) => {
                                  return <td key={i}>{sub}</td>;
                                })}
                              </tr>
                              <tr>
                                {table.title === "고객 현황"
                                  ? totalRegUser.map((user, i) => {
                                      return <td key={i}>{user}</td>;
                                    })
                                  : totalPay.map((pay, i) => {
                                      return <td key={i}>{pay}</td>;
                                    })}
                              </tr>
                            </tbody>
                          </table>
                        );
                      })}
                    </div>
                  </div>
                  <div className="d-flex justify-content-sm-around charts-div">
                    <div className="dashboard-chart">
                      <h5>고객 현황</h5>
                      <Pie data={totalUserGraph} />
                    </div>
                    <div className="dashboard-chart" style={{ width: "45%" }}>
                      <h5>일별 가입 고객 수</h5>
                      <Line
                        options={dayRegGraphOptions}
                        data={dayByDayRegGraph}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="d-flex justify-content-sm-around charts-div">
                    <div className="dashboard-chart" style={{ width: "75%" }}>
                      <h5>일별 결제 금액</h5>
                      <Bar options={payGraphOptions} data={dayByDayPayGraph} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="w-100 h-100"
            style={{
              background: "#fff",
              position: "fixed",
              top: "0",
              left: "0",
            }}
          ></div>
        </>
      )}
    </Fragment>
  );
};

export default Dashboard;
