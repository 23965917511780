import { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { BsPrinterFill, BsXLg } from "react-icons/bs";
import { getFormattedPrice, setInstMonth, setFormattedDate } from "../utils/FunctionUtility";

const ReceiptDetail = (props) => {
  // print 버튼 클릭 시 인쇄 페이지 오픈
  const handlePrinting = () => {
    window.open("/print", "_blank", "width=900, height=725");
  };

  return (
    <Fragment>
      {/* 프린트 버튼 클릭 시 해당 결제 내역 상세 조회 */}
      <Modal
        id="receipt_modal"
        show={props.showHistory}
        onHide={() => props.setShowHistory(false)}
        className="receipt resize-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <button
              className="btn"
              style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
              onClick={() => props.setShowHistory(false)}
            >
              <BsXLg />
            </button>
            <button
              style={{ margin: "0.5rem" }}
              className="btn btn-light d-flex justify-content-end align-items-center"
              onClick={handlePrinting}
            >
              <BsPrinterFill />
              &nbsp; 프린트하기
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(props.receiptInfo).length > 0 && (
            <div>
              <div className="receipt-title">
                <span>신용카드 매출전표</span>
                <hr />
                <div>
                  <span>주식회사 담다</span>
                  <h3 style={{ marginBottom: "0" }}>
                    <b>{getFormattedPrice(props.receiptInfo.amt_tot)}</b>
                    <small>&nbsp;원</small>
                  </h3>
                </div>
              </div>
              <div className="receipt-info">
                <span>결제금액</span>
                <hr style={{ color: "#000" }} />
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>총 결제금액</th>
                        <th className="right">
                          {getFormattedPrice(props.receiptInfo.amt_tot)}원
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>금액</td>
                        <td className="right">
                          {getFormattedPrice(props.receiptInfo.price)}원
                        </td>
                      </tr>
                      <tr>
                        <td>부가세</td>
                        <td className="right">
                          {getFormattedPrice(props.receiptInfo.vat)}원
                        </td>
                      </tr>
                      <tr>
                        <td>합계</td>
                        <td className="right">
                          {getFormattedPrice(props.receiptInfo.amt_tot)}원
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <div>
                  <span>결제정보</span>
                  <hr style={{ color: "#000" }} />
                  <table>
                    <tbody>
                      <tr>
                        <td>성명</td>
                        <td className="right">{props.receiptInfo.cust_name}</td>
                      </tr>
                      <tr>
                        <td>카드번호</td>
                        <td className="right">
                          {props.receiptInfo.card_mask_no}
                        </td>
                      </tr>
                      <tr>
                        <td>카드종류</td>
                        <td className="right">
                          {props.receiptInfo.acquire_name}
                        </td>
                      </tr>
                      <tr>
                        <td>거래유형</td>
                        <td className="right">
                          {props.receiptInfo.trn_type}(
                          {setInstMonth(props.receiptInfo.inst_month)})
                        </td>
                      </tr>
                      <tr>
                        <td>승인번호</td>
                        <td className="right">{props.receiptInfo.aprv_no}</td>
                      </tr>
                      <tr>
                        <td>할부개월</td>
                        <td className="right">
                          {setInstMonth(props.receiptInfo.inst_month)}
                        </td>
                      </tr>
                      <tr>
                        <td>거래번호</td>
                        <td className="right">{props.receiptInfo.trn_no}</td>
                      </tr>
                      <tr>
                        <td>상품명</td>
                        <td className="right">
                          {props.receiptInfo.ms_pd_name}
                        </td>
                      </tr>
                      <tr>
                        <td>거래일시</td>
                        <td className="right">
                          {setFormattedDate(props.receiptInfo.mod_dt)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ReceiptDetail;
