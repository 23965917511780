import { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import {
  commaRemovePrice,
  getFormattedDate,
  getFormattedPrice,
  setUseStatus,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";

const UserInfoModifyModal = (props) => {
  const { setIsShowMngCallbackAlert, setMngRequestDatas } =
    useComponentsContext();

  const [infosByStCode, setInfosByStCode] = useState({});

  const [serviceEndDt, setServiceEndDt] = useState("");
  const [remainAmt, setRemainAmt] = useState(0);

  // 저장 버튼 클릭 시 기존 값보다 작은 지 확인
  const handleModifyInfo = () => {
    const getServiceEndDt =
      document.getElementById("serviceEndDt").defaultValue;
    const getRemainAmt = document.getElementById("remainAmt").defaultValue;
    // 기존 값과 동일한 경우 예외처리
    if (
      getServiceEndDt === serviceEndDt &&
      commaRemovePrice(getRemainAmt) === remainAmt
    ) {
      alert("변경된 내용이 없습니다.");
    } else {
      setMngRequestDatas({
        paramStCode: props.selectedStCode,
        modEndDt: serviceEndDt,
        modRemainAmt: remainAmt,
      });
      setIsShowMngCallbackAlert(true);
    }
  };

  // 기존 고객 정보 조회
  const fetchData = () => {
    fetch(`/api/manager/member/reg/info?st_code=${props.selectedStCode}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setInfosByStCode(res.data);
          setServiceEndDt(res.data.end_dt);
          setRemainAmt(res.data.remain_amt);
        }
      })
      .catch((error) => console.error(error));
  };

  // 상태 표시
  const markTextState = (state) => {
    return setUseStatus(state);
  };

  useEffect(() => {
    if (props.selectedStCode) {
      setInfosByStCode({});
      fetchData();
    }
  }, [props.selectedStCode]);

  return (
    <Fragment>
      <Modal
        show={props.isShowUserInfoModal}
        onHide={() => {
          props.setIsShowUserInfoModal(false);
        }}
        className="mod-modal resize-modal"
      >
        <Modal.Header>
          <Modal.Title>서비스 정보 관리</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowUserInfoModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="table-stats order-table ov-h c-table">
            <table border={"1"} className="info-table modify-table">
              {Object.keys(infosByStCode).length > 0 && (
                <tbody>
                  <tr>
                    <th style={{ width: "9rem" }}>매장명</th>
                    <td>
                      {infosByStCode.cust_name} ({infosByStCode.st_code})
                    </td>
                    <th style={{ width: "6rem" }}>상태</th>
                    <td>
                      <div style={{ width: "max-content" }}>
                        <span id="state-info">
                          {infosByStCode.use_status === 0 ||
                          (infosByStCode.end_dt < getFormattedDate() &&
                            infosByStCode.use_status === 9)
                            ? "해지"
                            : "사용중"}
                        </span>
                        <span id="state-msg" className="red-color">
                          {markTextState(infosByStCode.use_status)}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>서비스 이용기간</th>
                    <td className="service_period">
                      <div style={{ display: "flex" }}>
                        {infosByStCode.start_dt}
                        <span>~</span>
                        <input
                          id="serviceEndDt"
                          type="date"
                          defaultValue={infosByStCode.end_dt}
                          className="form-control col-lg-7"
                          disabled={
                            infosByStCode.use_status === 9 ? true : false
                          }
                          onChange={(e) => setServiceEndDt(e.target.value)}
                        />
                      </div>
                    </td>
                    <th>발송 건수</th>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={getFormattedPrice(
                          infosByStCode.atalk_count
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>잔여 금액</th>
                    <td>
                      <input
                        id="remainAmt"
                        type="text"
                        className="form-control"
                        defaultValue={getFormattedPrice(
                          infosByStCode.remain_amt
                        )}
                        disabled={infosByStCode.use_status === 9 ? true : false}
                        onFocus={(e) => e.currentTarget.select()}
                        onChange={(e) => setRemainAmt(e.target.value)}
                        onKeyUp={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ))
                        }
                      />
                    </td>
                    <th>잔여 건수</th>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={getFormattedPrice(
                          infosByStCode.remain_atalk
                        )}
                      />
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleModifyInfo}>
            저장
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className="btn btn-secondary"
            onClick={() => props.setIsShowUserInfoModal(false)}
          >
            닫기
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export { UserInfoModifyModal };
