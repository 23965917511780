import { Fragment, useEffect, useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { useComponentsContext } from "../../../ContextProvider";

import content from "../../../../common/utils/UtilsAlertStyle.json";

/**
 * callback 기능 Alert
 * @param {} param
 * @returns
 */
const MngCallbackAlertForm = ({
  title,
  subTitle,
  buttonTxt,
  alertType,
  callback,
}) => {
  const { setIsShowMngCallbackAlert } = useComponentsContext();

  const [isShowInfoAlert, setIsShowInfoAlert] = useState(true); // 기능 Alert useState
  const [alertStyle, setAlertStyle] = useState({}); // 알림 타입별 style 지정

  useEffect(() => {
    // 버튼별 기능 Alert 알림 화면 표시
    if (alertType) {
      const alertStyleClass = content.alertType;
      setAlertStyle(alertStyleClass[alertType]);
      setIsShowInfoAlert(true);
    }
  }, []);

  // 기능 Alert 확인 클릭 시
  const handleConfirmClick = () => {
    setIsShowInfoAlert(false);
    setTimeout(() => {
      if (callback) callback();
      setAlertStyle({});
    }, 300);
  };

  /**
   * 애니메이션 효과가 사라져서 애니메이션 효과가
   * 나타난 후 실행하도록 타임아웃 설정해 둠
   */
  useEffect(() => {
    setTimeout(() => {
      if (!isShowInfoAlert) {
        setIsShowMngCallbackAlert(false);
      }
    }, 310);
  }, [isShowInfoAlert]);

  return (
    <Fragment>
      {/* 버튼명에 따른 기능 Alert 알림창 표시 */}
      {alertStyle && Object.keys(alertStyle).length > 0 && (
        <Modal
          show={isShowInfoAlert}
          onHide={() => setIsShowInfoAlert(false)}
          className="alert-modal resize-modal"
          style={{ zIndex: "10000" }}
        >
          <Modal.Header>
            <Modal.Title>
              <BsInfoCircle
                className={"icon-info " + `${alertStyle.addIconClass}`}
              />
              <b>{title}</b>
            </Modal.Title>
          </Modal.Header>
          {subTitle && <Modal.Body>{subTitle}</Modal.Body>}
          <Modal.Footer>
            <button
              className={"btn " + `${alertStyle.confirmBtnClass}`}
              onClick={handleConfirmClick}
              style={{ marginRight: "20px" }}
            >
              {buttonTxt}
            </button>
            <button
              className={"btn " + `${alertStyle.showCancelBtnClass}`}
              onClick={() => {
                setIsShowInfoAlert(false);
              }}
            >
              닫기
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};

export const MngCallbackAlert = (
  title,
  subTitle,
  buttonTxt,
  alertType,
  callback
) => {
  return (
    <MngCallbackAlertForm
      title={title}
      subTitle={subTitle}
      buttonTxt={buttonTxt}
      alertType={alertType}
      callback={callback}
    />
  );
};

/**
 * 완료 Alert
 * @param {} param
 * @returns
 */
const MngApproveAlertForm = ({ title, alertType, callback }) => {
  const { setIsShowMngApproveAlert, mngRestStep, setMngRestStep } =
    useComponentsContext();

  const [isShowApproveAlert, setIsShowApproveAlert] = useState(true);
  const [alertStyle, setAlertStyle] = useState({}); // 알림 타입별 style 지정

  useEffect(() => {
    // 버튼별 기능 Alert 알림 화면 표시
    if (alertType) {
      const alertStyleClass = content.alertType;
      setAlertStyle(alertStyleClass[alertType]);
      setIsShowApproveAlert(true);
    }
  }, []);

  // 완료 Alert 확인 버튼 클릭
  const handleInfoConfirm = () => {
    // 관리자
    if (mngRestStep > 0) {
      if (callback) callback();
      setMngRestStep(0);
    }
    setIsShowApproveAlert(false);
  };

  /**
   * 애니메이션 효과가 사라져서 애니메이션 효과가
   * 나타난 후 실행하도록 타임아웃 설정해 둠
   */
  useEffect(() => {
    setTimeout(() => {
      if (!isShowApproveAlert) {
        setIsShowMngApproveAlert(false);
      }
    }, 310);
  }, [isShowApproveAlert]);

  return (
    <Fragment>
      <Modal
        show={isShowApproveAlert}
        className="alert-modal"
      >
        <Modal.Header style={{ padding: "2rem 3rem" }}>
          <Modal.Title>
            <BsInfoCircle
              className={"icon-info " + `${alertStyle.addIconClass}`}
            />
            <b>{title}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            className={"btn " + `${alertStyle.addBtnClass}`}
            onClick={handleInfoConfirm}
          >
            확인
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export const MngApproveAlert = (title, alertType, callback) => {
  return (
    <MngApproveAlertForm
      title={title}
      alertType={alertType}
      callback={callback}
    />
  );
};
