import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import InputTag from "../../../../../common/utils/components/InputTag";
import {
  getFormattedDateHyphen,
  handleKeyPress,
  korRegularExpFnc,
  phoneRemoveHyphen,
  valiadateContact,
} from "../../../../../common/utils/FunctionUtility";

const MemberRegisterForm = ({
  stCode,
  successRegister,
  setSuccessRegister,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      contact: "",
      gender: "M",
      password: "",
      agreement: false,
    },
  });
  const getStCode = localStorage.getItem("acc_st_code");

  const [showPassword, setShowPassword] = useState(false);

  // 가입 클릭
  const handleRegisterMember = async (data) => {
    data.st_code = getStCode;

    // 비밀번호가 설정 되지 않은 경우 휴대폰 뒷자리 4자리로 설정
    if (!data.password) {
      const getPw = data.contact.substring(7, data.contact.length);
      data.password = getPw;
    }

    data.contact = phoneRemoveHyphen(data.contact);

    const valiadate = await valiadateContact({
      st_code: getStCode,
      contact: data.contact,
    });

    if (valiadate) {
      registerMember(data);
    } else {
      alert("이미 가입된 연락처입니다!");
    }
  };

  // 등록
  const registerMember = (data) => {
    fetch(`/api/member/reg/qr`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setSuccessRegister(true);
        } else {
          errorMsg(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
        alert("가입에 실패하였습니다. 다시 시도해주세요.");
      });
  };

  // 에러메시지 표시
  const errorMsg = (message) => {
    switch (message) {
      case "INVALID_PASSWORD_FORMAT":
        alert("비밀번호 형식이 맞지 않습니다. 4자리로 입력해주세요.");
        break;
      default:
        alert("가입에 실패하였습니다. 다시 시도해주세요.");
        break;
    }
  };

  useEffect(() => {
    if (stCode) {
      localStorage.setItem("acc_st_code", stCode);
    }

    return () => {
      localStorage.removeItem("acc_st_code");
      localStorage.removeItem("acc_url");
    };
  }, []);

  return (
    <Fragment>
      {successRegister ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h5>
            <b>가입이 완료되었습니다!</b>
          </h5>
          <button
            className="btn btn-primary mt-4"
            onClick={() => history.back()}
          >
            메인으로
          </button>
        </div>
      ) : (
        <div
          className="register-container mt-4 mb-4"
          style={{ top: "25px", position: "relative" }}
        >
          <form
            onSubmit={handleSubmit(handleRegisterMember)}
            onKeyPress={(e) => handleKeyPress(e)}
          >
            <div className="register-form">
              <h4 className="mt-3 mb-4 text-center">
                <b>회원가입</b>
              </h4>
              <div className="register-form-input">
                <span>
                  <small className="color-red mr-1">*</small>이름
                </span>
                <input
                  type="text"
                  placeholder="이름"
                  required
                  {...register("name")}
                />
              </div>
              <div className="mt-3 mb-4">
                <div className="register-form-input mb-0">
                  <span>
                    <small className="color-red mr-1">*</small>연락처
                  </span>
                  <InputTag
                    id="contact"
                    type="tel"
                    placeholder="연락처"
                    required
                    {...register("contact", {
                      validate: (value) =>
                        phoneRemoveHyphen(value).length == 11 ||
                        "연락처를 정확하게 입력해주세요.",
                    })}
                  />
                </div>
                {errors.contact && (
                  <span className="text-danger">
                    <small>{errors.contact.message}</small>
                  </span>
                )}
              </div>
              <div className="register-form-input pb-2 pr-2">
                <span>생년월일</span>
                <br />
                <input
                  className="mt-2"
                  type="date"
                  max={getFormattedDateHyphen()}
                  {...register("birthdate")}
                />
              </div>
              <div className="mt-3 mb-4">
                <span>성별</span>
                <div className="mt-3 d-flex align-items-center">
                  {["M", "W"].map((gender) => {
                    return (
                      <label
                        key={gender}
                        style={{
                          marginLeft: "0.8rem",
                          marginRight: gender === "M" ? "3rem" : "",
                        }}
                      >
                        <input
                          type="radio"
                          name="gender"
                          value={gender}
                          className="mr-2"
                          {...register("gender")}
                        />
                        {gender === "M" ? "남" : "여"}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="mt-3" style={{ marginBottom: "5rem" }}>
              <span>비밀번호</span>
              <div
                className="register-form-input d-flex"
                style={{ marginBottom: "0" }}
              >
                <input
                  id="password"
                  type="number"
                  inputMode="numberic"
                  pattern={/[0-9]*/}
                  className={showPassword ? "" : "password-show-type"}
                  style={{ width: "91%" }}
                  placeholder="비밀번호 입력"
                  onKeyUp={(e) => korRegularExpFnc(e.currentTarget)}
                  onInput={(e) => {
                    if (
                      e.currentTarget.value.length > e.currentTarget.maxLength
                    )
                      e.currentTarget.value = e.currentTarget.value.slice(
                        0,
                        e.currentTarget.maxLength
                      );
                  }}
                  maxLength={4}
                  {...register("password")}
                />
                <label
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-pw-label"
                >
                  {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                </label>
              </div>
              <p className="ml-2 mt-1">
                <small>비밀번호는 이후 포인트 조회 시 필요합니다.</small>
              </p>
              <p className="ml-2">
                <small>비밀번호는 4자리로 입력해주세요.</small>
              </p>
              <p className="ml-2" style={{ wordBreak: "keep-all" }}>
                <small>
                  비밀번호를 설정하시지 않는 경우, 초기 비밀번호는 휴대폰 뒷자리
                  4자리로 설정됩니다.
                </small>
              </p>
            </div>
            <hr />
            <div className="info-collect-agreement">
              <span>
                <small>
                  <b style={{ textDecoration: "underline" }}>
                    개인정보 수집 및 이용 동의
                  </b>
                </small>
              </span>
              <ul className="mt-2">
                <li type="none">
                  <small>
                    <b>1. 수집목적: </b> 쿠폰과 포인트 적립 서비스 제공
                  </small>
                </li>
                <li type="none">
                  <small>
                    <b>2. 수집항목</b>
                    <li type="circle" className="ml-4">
                      필수 항목 - 이름, 연락처, 성별
                    </li>
                    <li type="circle" className="ml-4">
                      선택 항목 - 생년월일, 비밀번호
                    </li>
                  </small>
                </li>
              </ul>
              <div className="mb-4">
                <label className="d-flex align-items-center mt-2">
                  <input
                    type="checkbox"
                    name="agree"
                    className="mr-2"
                    {...register("agreement", {
                      validate: (value) =>
                        value || "개인정보 수집 및 이용에 동의해 주세요.",
                    })}
                  />
                  <small>동의합니다.</small>
                </label>
                {errors.agreement && (
                  <small>
                    <span className="text-danger">
                      {errors.agreement.message}
                    </span>
                  </small>
                )}
              </div>
            </div>
            <div
              className="register-footer text-center mb-2"
              style={{ marginTop: "3rem" }}
            >
              <button type="submit" className="btn btn-primary">
                가입하기
              </button>
            </div>
          </form>
        </div>
      )}
    </Fragment>
  );
};

export { MemberRegisterForm };
