import { Fragment, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FaRedo } from "react-icons/fa";
import {
  getFormattedPrice,
  handleKeyPress,
  setParamterInteger,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import InputTag from "../../../../common/utils/components/InputTag";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";
import HowToUse from "../../../../common/utils/components/HowToUse";

const UserGradeMng = () => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
  } = useComponentsContext();

  const { control, reset, handleSubmit, setValue } = useForm();

  const [gradeList, setGradeList] = useState([]);

  // 등급 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(`/api/grade/list?st_code=${setToAdminTransAcc().stCode}`)
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data) => {
            let formatDatas = ["earning_point", "visit_cnt", "amt_sum"];
            formatDatas.map((format) => {
              data[format] = getFormattedPrice(data[format]);
            });
          });
          setGradeList(res.data);
          reset({ gradeList: res.data });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [reset]);

  // 폼의 전체 값을 watch로 감시
  const watchedGradesInfo = useWatch({
    control,
    name: "gradeList",
    defaultValue: gradeList,
  });

  // 저장
  const handleSaveClick = (data) => {
    data.gd_level = [1, 2, 3, 4, 5, 6];
    const modifiedGrades = [];

    // 기존 값과 현재 값을 비교하여 변경된 인덱스 가져오기
    gradeList.forEach((grade, index) => {
      const originalItem = grade;
      const currentItem = watchedGradesInfo[index];

      // 기존 값과 현재 값을 비교
      const gradeValue = [
        "gd_name",
        "earning_point",
        "visit_cnt",
        "amt_sum",
        "is_active",
      ];
      const isModified = gradeValue.some(
        (value) => originalItem[value] !== currentItem[value]
      );

      // 변경된 인덱스 담기
      if (isModified) {
        modifiedGrades.push(index);
      }
    });

    const modifiedData = modifiedGrades.map((index) => ({
      gd_level: data.gd_level[index],
      gd_name: watchedGradesInfo[index].gd_name,
      earning_point: setParamterInteger(watchedGradesInfo[index].earning_point),
      visit_cnt: setParamterInteger(watchedGradesInfo[index].visit_cnt),
      amt_sum: setParamterInteger(watchedGradesInfo[index].amt_sum),
      is_active: JSON.parse(watchedGradesInfo[index].is_active),
    }));

    if (modifiedData.length === 0) {
      alert("저장할 내용이 없습니다.");
      return;
    }

    setUserRequestDatas({
      st_code: setToAdminTransAcc().stCode,
      gradeInfos: modifiedData,
    });

    // 띄울 알림 종류 선택
    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    setUserRestStep({ key: 21, control: "update" });
  };

  // 입력 초기화
  const handleResetData = () => {
    reset({ gradeList }); // fetch된 items로 폼을 리셋
  };

  return (
    <Fragment>
      <HowToUse currentMenu={"member"} />
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <form
              onSubmit={handleSubmit(handleSaveClick)}
              onKeyPress={(e) => handleKeyPress(e)}
            >
              <div className="table-responsive">
                <div className="table-stats order-table ov-h c-table">
                  <div className="d-flex dashboard-table justify-content-sm-between flex-column">
                    <table className="table text-center fontS add-table">
                      <thead>
                        <tr>
                          <th style={{ width: "50px" }} rowSpan={"2"}>
                            단계
                          </th>
                          <th rowSpan={"2"}>등급명</th>
                          <th colSpan={"2"}>등급 달성 조건</th>
                          <th style={{ width: "160px" }} rowSpan={"2"}>
                            달성 시 지급포인트
                          </th>
                          <th style={{ width: "110px" }} rowSpan={"2"}>
                            사용여부
                          </th>
                        </tr>
                        <tr>
                          <th style={{ width: "120px" }}>방문횟수</th>
                          <th>누적결제금액</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gradeList.length > 0 &&
                          gradeList.map((list, i) => {
                            return (
                              <tr key={list.gd_level}>
                                <td>{list.gd_level}</td>
                                <td>
                                  <Controller
                                    name={`gradeList[${i}].gd_name`}
                                    control={control}
                                    defaultValue={list.gd_name}
                                    render={({ field }) => (
                                      <input
                                        className="form-control"
                                        required
                                        {...field}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    name={`gradeList[${i}].visit_cnt`}
                                    control={control}
                                    defaultValue={list.visit_cnt}
                                    render={({ field }) => (
                                      <InputTag
                                        {...field}
                                        className="form-control text-right"
                                        min={0}
                                        max={9999999999}
                                        reset-value={`gradeList[${i}].visit_cnt`}
                                        setValue={setValue}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    name={`gradeList[${i}].amt_sum`}
                                    control={control}
                                    defaultValue={list.amt_sum}
                                    render={({ field }) => (
                                      <InputTag
                                        {...field}
                                        className="form-control text-right"
                                        min={0}
                                        max={9999999999}
                                        reset-value={`gradeList[${i}].amt_sum`}
                                        setValue={setValue}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    name={`gradeList[${i}].earning_point`}
                                    control={control}
                                    defaultValue={list.earning_point}
                                    render={({ field }) => (
                                      <InputTag
                                        {...field}
                                        className="form-control text-right"
                                        min={0}
                                        max={99999999999}
                                        reset-value={`gradeList[${i}].earning_point`}
                                        setValue={setValue}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    name={`gradeList[${i}].is_active`} // 고유한 이름 설정
                                    control={control}
                                    defaultValue={list.is_active}
                                    render={({ field }) => (
                                      <select
                                        className="form-control"
                                        {...field}
                                        disabled={
                                          list.gd_level === 1 ? true : false
                                        }
                                      >
                                        <option value={true}>예</option>
                                        <option value={false}>아니오</option>
                                      </select>
                                    )}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className="grade-btn-group d-flex mt-2 mb-2"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  className="d-flex align-items-center btn btn-secondary"
                  onClick={handleResetData}
                >
                  <FaRedo />
                  &nbsp;&nbsp; 입력초기화
                </button>
                <button type="submit" className="btn btn-primary">
                  저장
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(
          "회원 등급을 저장하시겠습니까?",
          [],
          "확인",
          true,
          "notice",
          function () {
            noticeFunction();
          }
        )}
    </Fragment>
  );
};

export default UserGradeMng;
