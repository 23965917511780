import $ from "jquery";
import { Fragment, useEffect, useState } from "react";
import { FaChevronLeft, FaChevronRight, FaLaptop } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AccordionItem } from "./AccordionMenu";

import managerMenuList from "../../components/manager/menus/managerMenuList.json";
import userMenuList from "../../components/user/menus/userMenuList.json";

const LeftSidebar = (props) => {
  const adminToCustAcc = localStorage.getItem("adminToCustAcc");

  const [toggledIcon, setToggledIcon] = useState(true);

  // 사이드바 미니바 토글
  const handleSidebarToggle = () => {
    $("#accordionSidebar").toggleClass("toggled");
    $("body").toggleClass("sidebar-toggled");
    setToggledIcon(!toggledIcon);
  };

  // 모바일인지 확인 후 사이드바 스타일 수정
  const sidebarSizeCheck = () => {
    const widthSize = document.body.offsetWidth;
    if (parseInt(widthSize) < 1000) {
      $("#accordionSidebar").addClass("mobile");
    }
  };

  useEffect(() => {
    sidebarSizeCheck();
  }, []);

  return (
    <Fragment>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          to={props.adminCheck ? "/manager/dashboard" : "/user/services"}
          className="sidebar-brand d-flex align-items-center justify-content-center logo"
        >
          <div className="sidebar-brand-text mx-3 logo c-logo-image c-sidebar-logo"></div>
        </Link>
        {props.adminCheck && adminToCustAcc !== "ACCESS" && (
          <>
            <hr className="sidebar-divider my-0" />
            <li className="nav-item active">
              <Link className="nav-link f-en" to="/manager/dashboard">
                <FaLaptop style={{ color: "#5ca0bd", marginRight: "15px" }} />
                <span>Dashboard</span>
              </Link>
            </li>
          </>
        )}
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Interface</div>
        {props.adminCheck
          ? adminToCustAcc === "ACCESS"
            ? AccordionItem(userMenuList, false)
            : AccordionItem(managerMenuList, true)
          : AccordionItem(userMenuList, false)}
        <hr className="sidebar-divider d-none d-md-block" />
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={handleSidebarToggle}
          >
            {toggledIcon ? (
              <FaChevronLeft style={{ color: "#fff" }} />
            ) : (
              <FaChevronRight style={{ color: "#fff" }} />
            )}
          </button>
        </div>
      </ul>
    </Fragment>
  );
};

export { LeftSidebar };
