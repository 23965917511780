import { Fragment, useEffect, useState } from "react";
import { useComponentsContext } from "../../../../ContextProvider";
import UserRestFunctions from "../../UserRestFunctions";

const PgResultControl = () => {
  const { setUserRestStep } = useComponentsContext();

  // GET으로 넘어오는 쿼리스트링 가져오기
  const params = new URLSearchParams(location.search);
  const resCd = params.get("rescd");
  const shoporderno = params.get("shoporderno");
  const authorizationid = params.get("authorizationid");
  const paymentType = params.get("type");
  const mappedParams = {
    shopOrderNo: shoporderno,
    authorizationId: authorizationid,
    paymentType: paymentType,
  };

  useEffect(() => {
    switch (resCd) {
      case "0000":
        setUserRestStep({ key: 2 });
        break;
      case "W002":
        alert("결제를 취소하셨습니다.");
        self.close();
        break;
    }
  }, []);

  return (
    <Fragment>
      <div
        className="user-auth-wrapper"
        style={{ flexDirection: "column", background: "#fff" }}
      >
        <div className="dots-flow">
          <div style={{ marginTop: "5rem" }}>
            <h5 style={{ paddingTop: "2rem" }}>진행 중 입니다.</h5>
          </div>
        </div>
      </div>
      <UserRestFunctions mappedParams={mappedParams} />
    </Fragment>
  );
};

export default PgResultControl;
