import React, { useEffect, useState } from "react";
import { BsPlus, BsDash } from "react-icons/bs";
import $, { param } from "jquery";
import {
  commaRemovePrice,
  getFormattedPrice,
} from "../../../common/utils/FunctionUtility";

const AddChargeFunction = ({
  addChargeList,
  setAddChargeList,
  existAddList,
  setExistAddList,
  deleteList,
  setDeleteList,
}) => {
  // + 버튼 클릭 시 로우 하나 추가
  const handleAddRow = () => {
    const newRow = {
      id: `insert-${addChargeList.length + 1}`,
      ms_pd_code: `insert-${addChargeList.length + 1}`,
      ms_pd_name: "",
      price: "",
      ms_amt: "",
    };
    setAddChargeList([...addChargeList, newRow]);
  };

  // - 버튼 클릭 시 해당 Row 삭제
  const handleDelRow = (msPdCode) => {
    if (msPdCode.startsWith("insert")) {
      setAddChargeList(addChargeList.filter((item) => item.id !== msPdCode));
    } else {
      setAddChargeList(
        addChargeList.filter((item) => item.ms_pd_code !== msPdCode)
      );
      setExistAddList(existAddList.filter((list) => list !== msPdCode));
      setDeleteList((deleteList) => [...deleteList, msPdCode]);
    }
  };

  return (
    <table id="add-charge-table" className="table text-center fontS add-table">
      <thead>
        <tr>
          <th>No</th>
          <th>상품명</th>
          <th>결제 금액</th>
          <th>충전 금액</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {addChargeList.length > 0 &&
          addChargeList.map((list, i) => {
            // 행 번호 매기는 인덱스
            const existIndex = existAddList.indexOf(list.ms_pd_code);
            return (
              <tr id={list.ms_pd_code} key={list.ms_pd_code}>
                <td>{existIndex !== -1 ? existIndex + 1 : ""}</td>
                <td>
                  <span className="price-table-span">
                    <input
                      id="ms_pd_name"
                      type="text"
                      className="form-control text"
                      placeholder="상품명"
                      defaultValue={list.ms_pd_name}
                    />
                  </span>
                </td>
                <td>
                  <span className="price-table-span">
                    <input
                      id="price"
                      type="text"
                      className="form-control"
                      placeholder="결제금액"
                      defaultValue={getFormattedPrice(list.price)}
                      onFocus={(e) => e.target.select()}
                      onKeyUp={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />{" "}
                    원
                  </span>
                </td>
                <td>
                  <span className="price-table-span">
                    <input
                      id="ms_amt"
                      type="text"
                      className="form-control"
                      placeholder="충전금액"
                      defaultValue={getFormattedPrice(list.ms_amt)}
                      onFocus={(e) => e.target.select()}
                      onKeyUp={(e) =>
                        (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                      }
                    />{" "}
                    원
                  </span>
                </td>
                <td>
                  <button
                    className="btn btn-danger btn-row"
                    onClick={() => handleDelRow(list.ms_pd_code)}
                  >
                    <BsDash />
                  </button>
                </td>
              </tr>
            );
          })}
        <tr id="add-row">
          <td style={{ padding: "0.7rem 0.5rem" }}>
            <button className="btn btn-primary btn-row" onClick={handleAddRow}>
              <BsPlus />
            </button>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default AddChargeFunction;

// 추가 충전 저장 데이터 가공 함수
export function mappedAddChargeParams(tableId, deleteList) {
  const addTr = document.querySelectorAll(`${tableId} tbody tr`);
  const paramMap = new Map();

  addTr.forEach((list) => {
    if (!list.id.startsWith("insert") && list.id !== "#add-row") {
      const updateObj = {};
      const addInput = list.querySelectorAll("td input");

      // 업데이트 데이터
      addInput.forEach((input) => {
        const newValue = input.value;
        const field = input.id;

        if (newValue !== input.defaultValue) {
          if (!updateObj.ms_pd_code) {
            updateObj.ms_pd_code = list.id;
            updateObj.state = "update";
          }

          if (field !== "ms_pd_name") {
            updateObj[field] = commaRemovePrice(newValue);
            input.value = getFormattedPrice(newValue);
          } else {
            updateObj[field] = newValue;
          }
        }
      });

      if (updateObj.ms_pd_code) {
        if (paramMap.has(updateObj.ms_pd_code)) {
          Object.assign(paramMap.get(updateObj.ms_pd_code), updateObj);
        } else {
          paramMap.set(updateObj.ms_pd_code, updateObj);
        }
      }
    } else {
      // 추가 데이터
      const insertRowInputs = Array.from(list.querySelectorAll("td"))
        .filter(() => list.id.startsWith("insert"))
        .flatMap((td) => Array.from(td.querySelectorAll("input")));

      if (insertRowInputs.length > 0) {
        const insertObj = { state: "insert" };
        let isValid = true;

        insertRowInputs.forEach((input) => {
          if (input.id === "ms_pd_name" && !input.value.trim()) {
            isValid = false;
          }
          if (input.id !== "ms_pd_name") {
            insertObj[input.id] = commaRemovePrice(input.value);
            input.value = getFormattedPrice(input.value);
          } else {
            insertObj[input.id] = input.value;
          }
        });

        if (isValid) {
          paramMap.set(`insert_${list.rowIndex}`, insertObj);
        } else {
          paramMap.delete(`insert_${list.rowIndex}`);
        }
      }
    }
  });

  // 삭제 데이터
  deleteList.forEach((del) => {
    paramMap.set(`delete_${del}`, {
      ms_pd_code: del,
      state: "delete",
    });
  });

  const paramArr = Array.from(paramMap.values());
  return paramArr;
}
