import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import DatePeriodTag from "../../../../common/utils/components/DatePeriodTag";
import {
  formattedPeriod,
  getFormattedFirstDay,
  getFormattedLastDay,
  getGradeList,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const MemberStatistics = () => {
  const {
    setLoading,
    periodUpdate,
    setPeriodUpdate,
    searchPeriod,
    setSearchPeriod,
  } = useComponentsContext();

  const [gradeList, setGradeList] = useState([]);

  const [staticTables, setStaticTables] = useState([]);

  const [startDt, setStartDt] = useState(getFormattedFirstDay());
  const [endDt, setEndDt] = useState(getFormattedLastDay());

  // 등급 리스트 조회
  const getGradeNameList = async () => {
    const gradeList = await getGradeList(setToAdminTransAcc().stCode);
    let gdNameList = [];
    gradeList.map((grade) => {
      gdNameList.push(grade.gd_name);
    });
    setGradeList(gdNameList);
  };

  // 회원 통계 조회
  const fetchStaticData = () => {
    setLoading(true);
    fetch(
      `/api/member/statistics?st_code=${
        setToAdminTransAcc().stCode
      }${formattedPeriod(searchPeriod, startDt, endDt)}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          setStaticTables(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 그래프 옵션 설정
  const options = {
    responsive: true,
    scales: {
      x: {
        offset: true,
      },
      y: {
        beginAtZero: true,
        suggestedMax: 10,
      },
    },
  };

  // datalabels 옵션 세팅
  const datalabels = (graph, type) => {
    let marked = "";
    switch (type) {
      case "percent":
        marked = "%";
        break;
      case "people":
        marked = "명";
        break;
      default:
        marked = "회";
        break;
    }

    let options = {
      labels: {
        value: {
          anchor: graph === "pie" ? "center" : "end",
          clamp: true,
          borderWidth: 2,
          borderRadius: 4,
          font: { size: 15 },
          formatter: function (value, ctx) {
            return value > 0 ? `${value}${marked}` : null;
          },
          color: "black",
          backgroundColor: "#8f8f8f33",
        },
      },
    };

    if (type === "dayReg") {
      options.align = "end";
      options.anchor = "end";
    }
    return options;
  };

  // 막대 그래프 너비 설정
  const percentageSetting = (data) => {
    const length = data.length;
    if (length > 0) {
      return length === 1 ? 0.4 : length === 2 ? 0.5 : 0.6;
    }
  };

  // 기간별 매장 방문 회원 수 그래프
  const periodVisitDate =
    Object.keys(staticTables).length > 0 &&
    staticTables.date.map((date) => date.time_span);
  const periodVisitCnt =
    Object.keys(staticTables).length > 0 &&
    staticTables.date.map((date) => date.visit_count);
  const visitMemberByPeriodGraph = {
    labels: periodVisitDate,
    datasets: [
      {
        label: "방문 회원 수",
        data: periodVisitCnt,
        backgroundColor: "rgba(90, 155, 181, 0.5)",
        datalabels: datalabels("bar"),
        barPercentage: percentageSetting(periodVisitCnt),
        categoryPercentage: percentageSetting(periodVisitCnt),
      },
    ],
  };

  // 회원 등급별 매장 방문 횟수 그래프
  const gradeVisitCnt =
    Object.keys(staticTables).length > 0 &&
    staticTables.grade.map((grade) => grade.visit_count);
  const visitMemberByGradeGraph = {
    labels: gradeList,
    datasets: [
      {
        label: "방문 횟수",
        data: gradeVisitCnt,
        backgroundColor: [
          "rgb(201 203 207 / 30%)",
          "rgb(54 162 235 / 30%)",
          "rgb(255 99 132 / 30%)",
          "rgb(75 192 192 / 30%)",
          "rgb(255 159 64 / 30%)",
          "rgb(153 102 255 / 30%)",
        ],
        borderColor: [
          "rgb(201 203 207)",
          "rgb(54 162 235)",
          "rgb(255 99 132)",
          "rgb(75 192 192)",
          "rgb(255 159 64)",
          "rgb(153 102 255)",
        ],
        borderWidth: 1,
        datalabels: datalabels("pie"),
      },
    ],
  };

  // 신규 회원 재방문율
  const reVisitPercent = () => {
    let sortingRevist = [];
    if (Object.keys(staticTables).length > 0) {
      staticTables.revisit.sort(function (a, b) {
        return a.visit_group > b.visit_group
          ? -1
          : a.visit_group < b.visit_group
          ? 1
          : 0;
      });
      sortingRevist = staticTables.revisit.map((revisit) => revisit.cnt);
    }
    return sortingRevist;
  };
  const reVistPercentByMemberGraph = {
    labels: ["재방문", "미방문"],
    datasets: [
      {
        label: "신규 회원 재방문율",
        data: reVisitPercent(),
        backgroundColor: ["rgb(75 192 192 / 30%)", "rgb(201 203 207 / 30%)"],
        borderColor: ["rgb(75 192 192)", "rgb(201 203 207)"],
        borderWidth: 1,
        datalabels: datalabels("pie", "percent"),
      },
    ],
  };

  // 성별 연령대별 방문 회원 수 그래프
  const ageGroup =
    Object.keys(staticTables).length > 0 &&
    staticTables.age.map((age) => age.age_group);
  let setAgeGroup = ageGroup.length > 0 ? [...new Set(ageGroup)] : [];
  let genderMan = [];
  let genderWoman = [];
  const genderAgeVisitCnt =
    Object.keys(staticTables).length > 0 &&
    staticTables.age.map((age) => {
      if (age.gender === "M") {
        genderMan.push(age.visit_count);
      } else if (age.gender === "W") {
        genderWoman.push(age.visit_count);
      }
    });
  const visitMemberByGenderAgeGraph = {
    labels: setAgeGroup,
    datasets: [
      {
        label: "남자",
        data: genderMan,
        backgroundColor: "rgb(54 162 235 / 50%)",
        datalabels: datalabels("bar", "people"),
        barPercentage: percentageSetting(genderAgeVisitCnt),
        categoryPercentage: percentageSetting(genderAgeVisitCnt),
      },
      {
        label: "여자",
        data: genderWoman,
        backgroundColor: "rgb(255 99 132 / 50%)",
        datalabels: datalabels("bar", "people"),
        barPercentage: percentageSetting(genderAgeVisitCnt),
        categoryPercentage: percentageSetting(genderAgeVisitCnt),
      },
    ],
  };

  useEffect(() => {
    getGradeNameList();
    fetchStaticData();

    return () => {
      setSearchPeriod("daliy");
    };
  }, []);

  useEffect(() => {
    if (periodUpdate) {
      fetchStaticData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  return (
    <Fragment>
      <div className="title search-div d-flex">
        <DatePeriodTag
          currentMenu={"statistics"}
          startDt={startDt}
          setStartDt={setStartDt}
          endDt={endDt}
          setEndDt={setEndDt}
        />
      </div>
      {Object.keys(staticTables).length > 0 && (
        <div className="d-flex flex-wrap">
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4" id="myAreaChart_body">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                <h6 className="m-0 font-weight-bold text-primary p-0">
                  기간별 매장 방문 회원 수
                </h6>
                <div className="col p-0 option_box justify-content-end"></div>
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <div className="chartjs-size-monitor">
                    <div className="chartjs-size-monitor-expand">
                      <Bar options={options} data={visitMemberByPeriodGraph} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4" id="myAreaChart_body">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                <h6 className="m-0 font-weight-bold text-primary p-0">
                  회원 등급별 매장 방문 횟수
                </h6>
                <div className="col p-0 option_box justify-content-end"></div>
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <div className="chartjs-size-monitor">
                    <div className="chartjs-size-monitor-expand pie-chart">
                      {gradeList.length > 0 && (
                        <Pie data={visitMemberByGradeGraph} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4" id="myAreaChart_body">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                <h6 className="m-0 font-weight-bold text-primary p-0">
                  회원 재방문율
                </h6>
                <div className="col p-0 option_box justify-content-end"></div>
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <div className="chartjs-size-monitor">
                    <div className="chartjs-size-monitor-expand pie-chart">
                      <Pie data={reVistPercentByMemberGraph} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4" id="myAreaChart_body">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                <h6 className="m-0 font-weight-bold text-primary p-0">
                  성별 연령대별 방문 회원 수
                </h6>
                <div className="col p-0 option_box justify-content-end"></div>
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <div className="chartjs-size-monitor">
                    <div className="chartjs-size-monitor-expand">
                      <Bar
                        options={options}
                        data={visitMemberByGenderAgeGraph}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default MemberStatistics;
