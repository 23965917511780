import React, { Fragment, useEffect, useState } from "react";
import { FaUserShield } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

const UserAuthModal = ({ isUserAuthModal, setIsUserAuthModal, callback }) => {
  const userWebId = localStorage.getItem("webId");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [inputPw, setInputPw] = useState("");

  // 사용자 인증
  const handleAuthClick = async (e) => {
    e.preventDefault();

    fetch("/api/user/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        web_id: userWebId,
        web_pass: inputPw,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setInputPw("");
          setShowErrorMsg(false);
          callback();
        } else {
          setShowErrorMsg(true);
          $("input[type='password']").focus();
        }
      })
      .catch((error) => console.error(error));
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAuthClick(e);
    }
  };

  useEffect(() => {
    if (isUserAuthModal) {
      $("input[type='password'").focus();
    } else {
      setInputPw("");
      setShowErrorMsg(false);
    }
  }, [isUserAuthModal]);

  return (
    <Fragment>
      <Modal
        show={isUserAuthModal}
        onHide={() => setIsUserAuthModal(false)}
        className="auth-modal resize-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <FaUserShield style={{ fontSize: "25px", marginRight: "0.5rem" }} />
            <b style={{ fontSize: "18px" }}>사용자 인증</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            <b>본인 확인을 위해 비밀번호를 다시 한 번 입력해주세요.</b>
          </p>
          <div className="user-form">
            <table>
              <tbody>
                <tr className="d-flex align-items-center">
                  <td
                    className="d-flex align-items-center"
                    style={{ width: "140px" }}
                  >
                    <span>·</span> 아이디
                  </td>
                  <td className="d-flex align-items-baseline">
                    <span style={{ fontSize: "18px" }}>|</span>
                    <span style={{ color: "#3b3e40", fontWeight: "900" }}>
                      {userWebId}
                    </span>
                  </td>
                </tr>
                <tr className="d-flex">
                  <td
                    className="d-flex align-items-center"
                    style={{ width: "140px" }}
                  >
                    <span>·</span> 비밀번호
                  </td>
                  <td className="d-flex align-items-center">
                    <span style={{ fontSize: "18px" }}>|</span>
                    <input
                      type="password"
                      className="form-control"
                      value={inputPw}
                      onKeyDown={keyDownFnc}
                      onChange={(e) => setInputPw(e.target.value)}
                    />
                  </td>
                </tr>
                {showErrorMsg && (
                  <tr>
                    <td colSpan={"2"} style={{ paddingTop: "0.5rem" }}>
                      <small
                        className="error-notice"
                        style={{ fontSize: "85%" }}
                      >
                        비밀번호를 다시 입력해주세요.
                      </small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAuthClick}
          >
            확인
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setIsUserAuthModal(false)}
          >
            취소
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default UserAuthModal;
