import { Fragment, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FaPen, FaStar, FaTrash } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import HowToUse from "../../../../common/utils/components/HowToUse";
import {
  getFormattedPrice,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";
import { UsePolicyModifyModal } from "./modal";

const UsePolicyMng = () => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();
  const usePoRef = useRef({});

  const [useList, setUseList] = useState([]);
  const listPerPage = 10;
  const [useKey, setUseKey] = useState(0);

  const [inputPoName, setInputPoName] = useState("");
  const [selectedUsePolicy, setSelectedUsePolicy] = useState({});

  const [isShowUsePolicyModal, setIsShowUsePolicyModal] = useState(false);

  // 포인트 내역 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(
      `/api/point/policy/use/list?st_code=${
        setToAdminTransAcc().stCode
      }&po_name=${inputPoName}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, index) => {
            data.id = index + 1;

            // 금액 포맷
            const formatData = [
              "card_min_amt",
              "cash_min_amt",
              "max_use_point",
            ];
            formatData.map((format) => {
              data[format] = getFormattedPrice(data[format]);
            });
          });
          setUseList(res.data);
        } else {
          setHistoryList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleSearchBtnClick = () => {
    fetchData();
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
    }
  };

  // 사용할 정책 active
  const handleUsePolicyActive = (po_code) => {
    setUserRestStep({ key: 31, control: "update/active" });
    setUserRequestDatas({
      st_code: setToAdminTransAcc().stCode,
      po_code: po_code,
    });
  };

  // 사용 정책 정보 확인
  const handleGetListAndOpenModal = (po_code) => {
    const currentRefID = usePoRef.current[po_code].id;
    const getList = useList.find((use) => use.po_code === currentRefID);
    setSelectedUsePolicy(getList);
    setIsShowUsePolicyModal(true);
  };

  // 사용 정책 삭제
  const handleDelUsePolicy = (is_active, po_code) => {
    if (!JSON.parse(is_active)) {
      setIsShowUserCallbackAlert(true);
      setCancelAlert(true);
      setUserRequestDatas({
        st_code: setToAdminTransAcc().stCode,
        po_code: po_code,
      });
    } else {
      alert("현재 사용 중인 정책은 삭제할 수 없습니다.");
    }
  };

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const useCurrentTab = getCurrentList(useList, useKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setUseKey(selected);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <HowToUse currentMenu={"usePolicy"} cardWidth={"full"} />
      <div className="mt-3 mb-4 sch_box">
        <div className="row form-group col-lg-9 cc-col-left pl-0">
          <div className="col-md-3-1">
            <input
              type="text"
              id="search_name"
              name="search_name"
              value={inputPoName}
              placeholder="정책명"
              className="form-control"
              onKeyDown={keyDownFnc}
              onChange={(e) => setInputPoName(e.target.value)}
            />
          </div>
          <div className="row col-md-3-1 ml-1">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={handleSearchBtnClick}
            >
              <BsSearch style={{ fontWeight: "bold" }} /> &nbsp; 검색
            </button>
          </div>
        </div>
        <div className="form-group col-lg-3 float-right px-0 cc-col-right">
          <button
            type="button"
            className="btn btn-primary float-right d-flex align-items-center"
            onClick={() => setIsShowUsePolicyModal(true)}
          >
            <FaStar />
            &nbsp;&nbsp;정책 등록
          </button>
        </div>
      </div>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <div className="d-flex justify-content-sm-between flex-column">
                  <p className="mb-3">한 번에 하나의 정책만 사용 가능합니다.</p>
                  <table
                    className={"table text-center fontS add-table resize-table"}
                    style={{
                      minHeight: useCurrentTab.length > 0 ? "" : "11rem",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "60px" }} rowSpan={"2"}>
                          No
                        </th>
                        <th rowSpan={"2"}>사용</th>
                        <th rowSpan={"2"}>정책명</th>
                        <th colSpan={"2"}>최소 결제 금액</th>
                        <th colSpan={"2"}>사용 가능 한도</th>
                        <th style={{ width: "200px" }} rowSpan={"2"}>
                          수정날짜
                        </th>
                        <th rowSpan={"2"}>관리</th>
                      </tr>
                      <tr>
                        <th>카드결제</th>
                        <th>현금결제</th>
                        <th>기준단위</th>
                        <th>포인트</th>
                      </tr>
                    </thead>
                    <tbody>
                      {useCurrentTab &&
                        useCurrentTab.map((table, i) => {
                          return (
                            <tr
                              key={table.po_code}
                              id={table.po_code}
                              ref={(el) =>
                                (usePoRef.current[table.po_code] = el)
                              }
                            >
                              <td>{table.id}</td>
                              <td>
                                <input
                                  type="radio"
                                  name="isActive"
                                  defaultChecked={table.is_active}
                                  onChange={() =>
                                    handleUsePolicyActive(table.po_code)
                                  }
                                />
                              </td>
                              <td>{table.po_name}</td>
                              <td>{table.card_min_amt}</td>
                              <td>{table.cash_min_amt}</td>
                              <td>
                                {table.max_use_crt === "ONCE" ? "1회" : "1일"}
                              </td>
                              <td>{table.max_use_point}</td>
                              <td>{table.mod_dt}</td>
                              <td>
                                <div className="d-flex justify-content-sm-around align-items-center">
                                  <div
                                    title="정책수정"
                                    className="modify-icon"
                                    onClick={() =>
                                      handleGetListAndOpenModal(table.po_code)
                                    }
                                  >
                                    <FaPen />
                                  </div>
                                  <div
                                    title="정책삭제"
                                    style={{ fontSize: "18px" }}
                                    className="modify-icon"
                                    onClick={() =>
                                      handleDelUsePolicy(
                                        table.is_active,
                                        table.po_code
                                      )
                                    }
                                  >
                                    <FaTrash />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(useList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <UsePolicyModifyModal
        isShowUsePolicyModal={isShowUsePolicyModal}
        setIsShowUsePolicyModal={setIsShowUsePolicyModal}
        selectedUsePolicy={selectedUsePolicy}
        setSelectedUsePolicy={setSelectedUsePolicy}
      />
      {isShowUserCallbackAlert &&
        cancelAlert &&
        UserCallbackAlert(
          "사용 정책을 삭제하시겠습니까?",
          [],
          "확인",
          true,
          "caution",
          function () {
            setUserRestStep({ key: 31, control: "delete" });
            setCancelAlert(false);
          }
        )}
    </Fragment>
  );
};

export default UsePolicyMng;
