import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ManagerRestFunctions from "./utils/ManagerRestFunctions";

import managerMenuList from "./menus/managerMenuList.json";

const ManagePage = () => {
  const navigate = useNavigate();
  const stCode = localStorage.getItem("stCode");

  const titleFromPathname = () => {
    const getSubMenuTitleName =
      managerMenuList.list
        .find((menu) => location.pathname.includes(menu.path))
        ?.insideMenuList?.find((sub) => location.pathname.includes(sub.id))
        ?.name ??
      managerMenuList.list
        .find((menu) => location.pathname.includes(menu.path))
        ?.subMenuList?.find((sub) => location.pathname.includes(sub.id))?.name;

    return getSubMenuTitleName;
  };

  useEffect(() => {
    // stCode가 없는 경우 로그인 화면으로 이동
    if (!stCode) {
      navigate("/login");
    }

    if (localStorage.getItem("webId") !== "admin") {
      alert("해당 경로로 접근할 수 없습니다.");
      navigate("/user");
      return;
    }

    if (location.pathname === "/manager" || location.pathname === "/manager/") {
      navigate("/manager/dashboard");
    }
  }, []);

  return (
    <Fragment>
      {!(
        location.pathname.includes("/dashboard") ||
        location.pathname.includes("/coupon")
      ) && (
        <div className="mb-4 alert-light shadow pt-sm-3 pb-sm-3 pp-m1 cf menu-header">
          <h1 className="h5 mb-0 text-gray-800 ml-3 float-l">
            {titleFromPathname()}
          </h1>
        </div>
      )}
      <Outlet />
      <ManagerRestFunctions />
    </Fragment>
  );
};

export default ManagePage;
