import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  setFormattedDate,
  settingSelectedPeriod,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";
import { CouponSendInquiry } from "./modal";

const CouponSendHistory = () => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      cp_name: "",
      start_dt: getFormattedFirstDay(),
      end_dt: getFormattedLastDay(),
    },
  });
  const inputCpName = watch("cp_name");
  const startDt = watch("start_dt");
  const endDt = watch("end_dt");

  const historyRef = useRef({});

  const [isShowInquiryModal, setIsShowInquiryModal] = useState(false);
  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 결제 내역 리스트 조회
  const [historyList, setHistoryList] = useState([]); // 쿠폰 내역 리스트

  const [selectedCpCode, setSelectedCpCode] = useState(""); // 단건 조회할 쿠폰번호
  const [selectedCpIssueNo, setSelectedCpIssueNo] = useState(0);

  const listPerPage = 10;
  const [historyKey, setHistoryKey] = useState(0);

  // 쿠폰 내역 리스트 조회
  const fetchData = () => {
    const cp_name = inputCpName ? `&cp_name=${inputCpName}` : "";

    setLoading(true);
    fetch(
      `/api/coupon/issue/list?st_code=${
        setToAdminTransAcc().stCode
      }&start_dt=${startDt}&end_dt=${endDt}${cp_name}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, index) => (data.id = index + 1));
          setHistoryList(res.data);
        } else {
          setHistoryList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setValue("start_dt", settingDate.startDt);
    setValue("end_dt", settingDate.endDt);
    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const historyCurrentTab = getCurrentList(historyList, historyKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setHistoryKey(selected);
  };

  const formattedStatus = (status) => {
    let statusMsg = "";
    switch (status) {
      case "1":
        statusMsg = "즉시 발송";
        break;
      case "2":
        statusMsg = "예약 발송";
        break;
      case "3":
        statusMsg = "예약";
        break;
      case "9":
        statusMsg = "취소";
        break;
    }
    return statusMsg;
  };

  // 상세보기
  const handleSendDetail = (cp_code, cp_issue_no) => {
    setSelectedCpCode(cp_code);
    setSelectedCpIssueNo(cp_issue_no);
    setIsShowInquiryModal(true);
  };

  // 쿠폰 발송 예약 취소
  const handelCancelResve = (cp_code, cp_issue_no) => {
    setIsShowUserCallbackAlert(true);
    setCancelAlert(true);
    setUserRequestDatas({
      cp_code: cp_code,
      cp_issue_no: cp_issue_no,
    });
  };

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="title search-div d-flex flex-row">
          <div className="col-lg-12 period-search" style={{ paddingLeft: "0" }}>
            <div className="col-lg-2">
              <input
                type="text"
                id="search_name"
                name="search_name"
                placeholder="쿠폰명"
                className="form-control"
                onKeyDown={keyDownFnc}
                {...register("cp_name")}
              />
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                오늘
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                이번달
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary period_search_css"
                onClick={handlePeriodInquiry}
              >
                올해
              </button>
            </div>
            <div className="col-lg-4" style={{ padding: "0" }}>
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="between_day"
                  name="between_day"
                  className="form-control"
                  {...register("start_dt")}
                />
                <span>~</span>
              </div>
              <div className="input-con col-lg-4">
                <input
                  type="date"
                  id="and_day"
                  name="and_day"
                  className="form-control"
                  {...register("end_dt")}
                />
              </div>
              <div className="input-con col-lg-3">
                <button
                  type="button"
                  className="btn btn-outline-secondary search-btn"
                  onClick={handleSearchClick}
                >
                  <BsSearch style={{ fontWeight: "bold" }} />
                  &nbsp; 조회
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={
                    "table text-center fontS add-table resize-table " +
                    `${historyCurrentTab.length > 0 ? "" : "history-table"}`
                  }
                >
                  <thead>
                    <tr>
                      <th style={{ width: "60px" }} scope="col">
                        No
                      </th>
                      <th scope="col">쿠폰명</th>
                      <th style={{ width: "200px" }} scope="col">
                        발송인원
                      </th>
                      <th scope="col">발송일시</th>
                      <th style={{ width: "200px" }} scope="col">
                        상태
                      </th>
                      <th style={{ width: "200px" }} scope="col">
                        상세보기
                      </th>
                      <th style={{ width: "200px" }} scope="col">
                        예약취소
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyCurrentTab.length > 0 &&
                      historyCurrentTab.map((list, i) => {
                        return (
                          <tr
                            key={i}
                            id={list.cp_code}
                            ref={(el) =>
                              (historyRef.current[list.cp_code] = el)
                            }
                          >
                            <td>{list.id}</td>
                            <td>{list.cp_name}</td>
                            <td>{list.send_cnt}명</td>
                            <td>{setFormattedDate(list.scheduled_dt)}</td>
                            <td
                              className={list.status === "9" ? "color-red" : ""}
                            >
                              {formattedStatus(list.status)}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary margin-auto-center"
                                onClick={() =>
                                  handleSendDetail(
                                    list.cp_code,
                                    list.cp_issue_no
                                  )
                                }
                              >
                                조회
                              </button>
                            </td>
                            <td>
                              {list.status === "3" ? (
                                <button
                                  type="button"
                                  className="btn btn-danger margin-auto-center"
                                  onClick={() =>
                                    handelCancelResve(
                                      list.cp_code,
                                      list.cp_issue_no
                                    )
                                  }
                                >
                                  예약취소
                                </button>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(historyList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <CouponSendInquiry
        isShowInquiryModal={isShowInquiryModal}
        setIsShowInquiryModal={setIsShowInquiryModal}
        selectedCpCode={selectedCpCode}
        setSelectedCpCode={setSelectedCpCode}
        selectedCpIssueNo={selectedCpIssueNo}
        setSelectedCpIssueNo={setSelectedCpIssueNo}
      />
      {isShowUserCallbackAlert &&
        cancelAlert &&
        UserCallbackAlert(
          "예약 발송을 취소하시겠습니까?",
          [],
          "확인",
          true,
          "caution",
          function () {
            setUserRestStep({ key: 40, control: "issue/cancel" });
            setCancelAlert(false);
          }
        )}
    </Fragment>
  );
};

export default CouponSendHistory;
