import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";
import {
  getTransDatePeriodTime,
  handleKeyPress,
  setParamterInteger,
  setToAdminTransAcc,
  phoneRegularExpFnc,
  getFormattedPrice,
  phoneRemoveHyphen,
} from "../../../../../common/utils/FunctionUtility";
import InputTag from "../../../../../common/utils/components/InputTag";

const ReservationModifyModal = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
    subsState,
  } = useComponentsContext();

  // useForm 설정
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const atalkSendDt = watch("atalk_send_dt");
  const status = watch("status");

  // Form 기본 값 세팅
  const formDefaultValues = {
    reservation_no: "",
    contact: "",
    name: "",
    dateFormat: {
      reservation_dt: "",
      period: "PM",
      hour: "12",
      minute: "00",
    },
    adult: 0,
    infant: 0,
    deposit_amt: 0,
    memo: "",
    status: "",
    atalk_send_flag: false,
    atalk_send_dt: "",
  };

  const [buttonTxt, setButtonTxt] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");

  // 예약 상태 포맷
  const resveStatus = [
    { id: "PENDING", name: "대기" },
    { id: "CONFIRMED", name: "확정" },
    { id: "CANCELED", name: "취소" },
    { id: "NO_SHOW", name: "노쇼" },
  ];

  // 예약번호에 대한 상세정보 조회
  const fetchData = () => {
    if (props.selectedResveNo) {
      fetch(
        `/api/reservation/info?st_code=${
          setToAdminTransAcc().stCode
        }&reservation_no=${props.selectedResveNo}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.result) {
            res.data.dateFormat = reservationDtFormatted(
              res.data.reservation_dt
            );
            res.data.contact = phoneRegularExpFnc(res.data.contact); // 연락처 포맷팅
            res.data.deposit_amt = getFormattedPrice(res.data.deposit_amt); // 예약금 포맷팅
            reset(res.data);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  // 선택한 예약번호가 있는 경우에만 조회
  useEffect(() => {
    if (props.selectedResveNo) {
      fetchData();
    } else {
      reset(formDefaultValues);
    }
  }, [props.selectedResveNo]);

  // 선택한 날짜가 있는 경우 날짜 지정
  useEffect(() => {
    if (props.selectedResveDt) {
      setValue("dateFormat.reservation_dt", props.selectedResveDt);
    }
  }, [props.selectedResveDt]);

  // 모달이 닫힌 경우 alert 비활성화 및 form, 선택한 예약번호 리셋
  useEffect(() => {
    if (!props.isShowResveModal) {
      props.setSelectedResveNo("");
      setNoticeAlert(false);
      reset(formDefaultValues);
      if (props.selectedResveDt) {
        props.setSelectedResveDt("");
      }
    }
  }, [props.isShowResveModal]);

  // 저장 버튼 클릭
  const handleModifyInfo = (data) => {
    delete data.atalk_send_dt;
    data.st_code = setToAdminTransAcc().stCode;
    data.reg_type = "Web";
    data.deposit_amt = setParamterInteger(data.deposit_amt);
    data.contact = phoneRemoveHyphen(data.contact);
    setUserRequestDatas(data);

    // 띄울 알림 종류 선택
    let type = data.reservation_no ? "수정" : "등록";
    setButtonTxt(type);
    setNoticeTitle(`예약 정보를 ${type}하시겠습니까?`);
    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    let controlByType = buttonTxt === "등록" ? "reg" : "update";
    setUserRestStep({ key: 50, control: controlByType });
    setButtonTxt("");
  };

  /**
   * 예약일시
   * AM/PM 오전/오후 0~12시
   * 00/10/20/30/40/50분 형태로 포맷설정
   * @param {} date
   * @returns
   */
  const reservationDtFormatted = (date) => {
    const splitResveDt = date.split(" ");
    let resveTime = getTransDatePeriodTime(splitResveDt[1]).split("/");

    // 오후 12시와 오전 12시 처리
    if (resveTime[0] === "AM" && resveTime[1] === "0") {
      resveTime[1] = "12"; // 00시를 오전 12시로 표시
    } else if (resveTime[0] === "AM" && resveTime[1] === "12") {
      resveTime[0] = "PM"; // 오전 12시(정오)를 오후로 표시
    }

    return {
      reservation_dt: splitResveDt[0],
      period: resveTime[0],
      hour: resveTime[1],
      minute: resveTime[2],
    };
  };

  return (
    <Fragment>
      <Modal
        show={props.isShowResveModal}
        onHide={() => {
          props.setIsShowResveModal(false);
        }}
        className="mod-modal"
        style={{ zIndex: "10000" }}
      >
        <Modal.Header>
          <Modal.Title>예약 관리</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowResveModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="usermng-modal-body resve-modal-body">
            <div className="order-table ov-h c-table">
              <input
                id="reservation_no"
                type="hidden"
                {...register("reservation_no")}
              />
              <table className="usermng-mod-table">
                <tbody>
                  <tr>
                    <th style={{ width: "9rem", minWidth: "140px" }}>
                      연락처/예약자명
                    </th>
                    <td
                      className="d-flex align-items-center"
                      style={{ width: "9rem", minWidth: "calc(100% - 140px)" }}
                    >
                      <InputTag
                        id="contact"
                        type="tel"
                        required
                        className="form-control col-lg-6 mr-2"
                        placeholder="연락처"
                        {...register("contact", {
                          validate: (value) =>
                            phoneRemoveHyphen(value).length === 11 ||
                            "연락처를 정확하게 입력해주세요.",
                        })}
                      />
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="예약자명"
                        {...register("name")}
                      />
                    </td>
                    {errors.contact && (
                      <span className="text-danger">
                        {errors.contact.message}
                      </span>
                    )}
                  </tr>
                  <tr>
                    <th>예약일시</th>
                    <td className="d-flex align-items-center">
                      <input
                        id="reservation_dt"
                        type="date"
                        className="form-control col-lg-4 mr-2"
                        placeholder="예약일시"
                        {...register("dateFormat.reservation_dt")}
                      />
                      <select
                        className="form-control mr-2"
                        {...register("dateFormat.period")}
                      >
                        <option value="AM">오전</option>
                        <option value="PM">오후</option>
                      </select>
                      <select
                        className="form-control mr-2"
                        {...register("dateFormat.hour")}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour) => (
                          <option key={hour} value={hour}>
                            {hour}시
                          </option>
                        ))}
                      </select>
                      <select
                        className="form-control"
                        {...register("dateFormat.minute")}
                      >
                        {[0, 10, 20, 30, 40, 50].map((minute) => (
                          <option
                            key={minute}
                            value={minute.toString().padStart(2, "0")}
                          >
                            {minute.toString().padStart(2, "0")}분
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>예약인원</th>
                    <td className="d-flex align-items-center justify-content-sm-between">
                      성인
                      <Controller
                        name="adult"
                        control={control}
                        render={({ field }) => (
                          <InputTag
                            {...field}
                            id="adult"
                            className="form-control ml-2 text-right"
                            style={{ width: "10.7rem" }}
                            min={0}
                            max={999}
                            reset-value="adult"
                            setValue={setValue}
                          />
                        )}
                      />
                      유아
                      <Controller
                        name="infant"
                        control={control}
                        render={({ field }) => (
                          <InputTag
                            {...field}
                            id="infant"
                            className="form-control ml-2 text-right"
                            style={{ width: "10.7rem" }}
                            min={0}
                            max={999}
                            reset-value="infant"
                            setValue={setValue}
                          />
                        )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>예약금</th>
                    <td className="d-flex align-items-center">
                      <Controller
                        name="deposit_amt"
                        control={control}
                        render={({ field }) => (
                          <InputTag
                            {...field}
                            id="deposit_amt"
                            className="form-control text-right"
                            style={{ width: "10.7rem" }}
                            required
                            min={0}
                            max={9999999999}
                            reset-value="deposit_amt"
                            setValue={setValue}
                          />
                        )}
                      />
                      원
                    </td>
                  </tr>
                  <tr>
                    <th>요청사항</th>
                    <td>
                      <textarea
                        id="memo"
                        type="text"
                        placeholder="요청사항"
                        className="form-control w-100"
                        {...register("memo")}
                      />
                    </td>
                  </tr>
                  <tr style={{ height: "4rem" }}>
                    <th>예약상태</th>
                    <td className="resve-status-td pt-2">
                      <div className="d-flex flex-start">
                        {resveStatus.map((status) => {
                          return (
                            <label className="col-lg-3 pl-0" key={status.id}>
                              <input
                                type="radio"
                                name="status"
                                value={status.id}
                                {...register("status")}
                                required
                              />
                              {status.name}
                            </label>
                          );
                        })}
                      </div>
                      <div className="d-flex">
                        <label className="pl-0" style={{ width: "150px" }}>
                          <input
                            type="checkbox"
                            className="mr-2"
                            style={{ width: "15px" }}
                            disabled={
                              !subsState
                                ? true
                                : status === "NO_SHOW"
                                ? true
                                : false
                            }
                            {...register("atalk_send_flag")}
                          />
                          알림톡 발송
                        </label>
                        {atalkSendDt && (
                          <div
                            className="col-lg-8 text-right pr-0"
                            style={{ color: "#17a673" }}
                          >
                            [{atalkSendDt} 발송완료]
                          </div>
                        )}
                      </div>
                      {!subsState && (
                        <small style={{ color: "red" }}>
                          <p>
                            **알림톡 서비스 미가입자는 알림톡이 발송되지
                            않사오니,
                          </p>
                          <p>
                            이용을 원하시는 경우 알림톡 서비스 메뉴에서
                            가입해주시길 바랍니다.
                          </p>
                        </small>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              저장
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowResveModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { ReservationModifyModal };
