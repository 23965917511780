import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";
import { BsXLg } from "react-icons/bs";
import {
  getFormattedDateHyphen,
  handleKeyPress,
  korRegularExpFnc,
  setParamterInteger,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";
import InputTag from "../../../../../common/utils/components/InputTag";

const CouponModifyModal = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    noticeAlert,
    setNoticeAlert,
  } = useComponentsContext();

  // useForm 설정
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const cpTypeCheck = watch("cp_type") === "ONCE" ? true : false;
  const useTypeCheck = watch("use_type") === "FINITE" ? false : true;

  const discountTypeCheck = watch("discount_type");

  useEffect(() => {
    // 수정이 아닌 경우에만 discount_type이 변경될때마다 0으로 초기화 처리
    if (Object.keys(props.selectedCouponList).length === 0) {
      setValue("discount_unit", 0);
    }
  }, [discountTypeCheck]);

  // Form 기본 값 세팅
  const formDefaultValues = {
    cp_name: "",
    cp_comment: "",
    cp_type: "ONCE",
    use_type: "INFINITE",
    use_limit: 0,
    discount_type: "AMOUNT",
    discount_unit: 0,
    start_dt: getFormattedDateHyphen(),
    end_dt: getFormattedDateHyphen(),
  };
  const endDt = watch("end_dt");

  const cpType = [
    { id: "ONCE", name: "일회성" },
    { id: "MULTI", name: "다회성" },
  ];

  const [buttonTxt, setButtonTxt] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");
  const [btnSetting, setBtnSetting] = useState(false);

  const [isShowExmAltalk, setIsShowExmAltalk] = useState(false);

  // 저장 버튼 클릭
  const handleModifyInfo = (data) => {
    const formatDatas = ["discount_unit", "use_limit"];
    formatDatas.map((format) => {
      data[format] = setParamterInteger(data[format]);
    });
    data.st_code = setToAdminTransAcc().stCode;
    // cp_type이 일회성인 경우 파라미터(use_type, use_limit) 삭제
    if (data.cp_type === "ONCE") {
      delete data.use_type;
      delete data.use_limit;
    }
    setUserRequestDatas(data);

    // 띄울 알림 종류 선택
    let type = data.cp_code ? "수정" : "등록";
    setButtonTxt(type);
    setNoticeTitle(`쿠폰 정보를 ${type}하시겠습니까?`);
    setIsShowUserCallbackAlert(true);
    setNoticeAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    let controlByType = buttonTxt === "등록" ? "reg" : "update";
    setUserRestStep({ key: 40, control: controlByType });
    setButtonTxt("");
  };

  // 일회성에서 발행 안된 경우 확인
  const preventationModify = () => {
    let result = false;
    if (!btnSetting) {
      const getInfos = props.selectedCouponList;
      if (getInfos.cp_type === "ONCE" && getInfos.edit_able === 1) {
        result = true;
      }
    } else {
      result = true;
    }
    return result;
  };

  // 알림톡 예시 표시
  const handleShowExample = () => {
    setIsShowExmAltalk(!isShowExmAltalk);
  };

  useEffect(() => {
    if (props.selectedCouponList) {
      reset(props.selectedCouponList);
      setBtnSetting(
        Object.keys(props.selectedCouponList).length === 0 ? true : false
      );
    }
  }, [props.selectedCouponList]);

  // 모달이 닫힌 경우 alert 비활성화 및 form, 선택한 예약번호 리셋
  useEffect(() => {
    if (!props.isShowCouponModal) {
      setNoticeAlert(false);
      setIsShowExmAltalk(false);
      reset(formDefaultValues);
      // 버튼 초기화 현상 수정
      setTimeout(() => {
        props.setSelectedCouponList({});
        setBtnSetting(false);
      }, 300);
    }
  }, [props.isShowCouponModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowCouponModal}
        onHide={() => {
          props.setIsShowCouponModal(false);
        }}
        id="coupon-mod"
        className={`coupon-mod-modal resize-mod-modal resize-modal ${
          isShowExmAltalk ? "marked-exm-atalk" : ""
        }`}
      >
        <Modal.Header>
          <Modal.Title>
            <span>쿠폰관리</span>
            <ul
              className="ml-4 mt-2"
              style={{ width: "29rem", fontSize: "14px", color: "#858796" }}
            >
              <li>
                다회성 쿠폰은 발급 시 기간 만료 전까지 수정/삭제가 불가능합니다.
              </li>
              <li>
                일회성 쿠폰은 발행 전까지 수정/삭제가 가능하나, 발행 후에는 기간
                만료 전까지 수정/삭제가 불가능 합니다.
              </li>
              <li>
                <a href="#" onClick={handleShowExample}>
                  알림톡 예시 {isShowExmAltalk ? "닫기" : "보기"}
                </a>
              </li>
            </ul>
          </Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowCouponModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body
            className={`point-modal-body ${
              isShowExmAltalk ? "d-flex align-items-center" : ""
            }`}
            style={{ pointerEvents: preventationModify() ? "" : "none" }}
          >
            <div className="order-table ov-h c-table">
              <table
                className="usermng-mod-table coupon-mod-modal"
                style={{ width: "33rem" }}
              >
                <tbody>
                  <tr>
                    <th style={{ width: "10rem" }}>쿠폰명</th>
                    <td>
                      <input
                        id="cp_name"
                        type="text"
                        className="form-control col-lg-12"
                        placeholder="쿠폰명"
                        {...register("cp_name")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "10rem" }}>설명</th>
                    <td>
                      <textarea
                        type="text"
                        className="form-control col-lg-12"
                        placeholder="설명"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        }}
                        {...register("cp_comment")}
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>종류</th>
                    <td>
                      <div className="d-flex">
                        {cpType &&
                          cpType.map((type) => {
                            return (
                              <label className="mr-5" key={type.id}>
                                <input
                                  className="mr-3"
                                  type="radio"
                                  name="type"
                                  value={type.id}
                                  disabled={preventationModify() && !btnSetting}
                                  {...register("cp_type")}
                                />
                                {type.name}
                              </label>
                            );
                          })}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>발행 횟수</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <label className="mr-5">
                          <input
                            className="mr-3"
                            type="radio"
                            name="use_type"
                            value="INFINITE"
                            disabled={cpTypeCheck}
                            {...register("use_type")}
                          />
                          <span>무제한</span>
                        </label>
                        <label>
                          <input
                            className="mr-3"
                            type="radio"
                            name="use_type"
                            value="FINITE"
                            disabled={cpTypeCheck}
                            {...register("use_type")}
                          />
                          <span>제한</span>
                        </label>
                        <Controller
                          name="use_limit"
                          control={control}
                          render={({ field }) => (
                            <InputTag
                              {...field}
                              id="use_limit"
                              className="text-right form-control col-lg-3 ml-3 mr-1"
                              disabled={cpTypeCheck ? true : useTypeCheck}
                              min={0}
                              max={9999}
                              reset-value="use_limit"
                              setValue={setValue}
                            />
                          )}
                        />
                        <span>회</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>할인 내용</th>
                    <td>
                      <div className="d-flex">
                        <Controller
                          name="discount_unit"
                          control={control}
                          rules={{
                            validate: (value) =>
                              discountTypeCheck === "PERCENT" && value < 1
                                ? "할인율은 1% 이상으로 설정해주세요."
                                : true,
                          }}
                          render={({ field }) => (
                            <InputTag
                              {...field}
                              id="discount_unit"
                              className="form-control text-right col-lg-4 mr-2"
                              min={0}
                              max={
                                discountTypeCheck === "PERCENT"
                                  ? 100
                                  : 9999999999
                              }
                              reset-value="discount_unit"
                              setValue={setValue}
                            />
                          )}
                        />
                        <select
                          className="form-control col-lg-3"
                          {...register("discount_type")}
                        >
                          <option value="AMOUNT">원</option>
                          <option value="PERCENT">%</option>
                        </select>
                      </div>
                      {errors.discount_unit && (
                        <span className="text-danger">
                          {errors.discount_unit.message}
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>사용 기간</th>
                    <td>
                      <div className="d-flex align-items-center">
                        <input
                          id="start_dt"
                          type="date"
                          className={`form-control col-lg-5 mr-2`}
                          {...register("start_dt", {
                            validate: (value) =>
                              value <= endDt ||
                              "시작일자는 종료일자보다 이전으로 설정해주세요.",
                          })}
                        />
                        &nbsp;~&nbsp;&nbsp;
                        <input
                          id="end_dt"
                          type="date"
                          className="form-control col-lg-5 mr-2"
                          {...register("end_dt")}
                        />
                      </div>
                      {errors.start_dt && (
                        <span className="text-danger">
                          {errors.start_dt.message}
                        </span>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <th>시행 여부</th>
                    <td>
                      <div className="d-flex">
                        <label className="mr-5">
                          <input
                            className="mr-3"
                            type="radio"
                            name="status"
                            defaultChecked
                          />
                          예
                        </label>
                        <label>
                          <input className="mr-3" type="radio" name="status" />
                          아니오
                        </label>
                      </div>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            {isShowExmAltalk && (
              <div id="exm-atalk" style={{ marginLeft: "2.3rem" }}>
                <span>
                  <b>알림톡 예시</b>
                </span>
                <img
                  alt=""
                  src={require("../../../../../assets/images/example/coupon_atalk.png")}
                  className="mt-1"
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {preventationModify() && (
              <button type="submit" className="btn btn-primary mr-4">
                저장
              </button>
            )}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowCouponModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        noticeAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { CouponModifyModal };
