import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import {
  FaEye,
  FaLink,
  FaPaperPlane,
  FaPen,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import ReactPaginate from "react-paginate";
import {
  getFormattedDateHyphen,
  getFormattedPrice,
  setFormattedDate,
  setToAdminTransAcc,
  settingSelectedPeriod,
  subscriptStateCheck,
  getFormattedLastDay,
  getFormattedFirstDay,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";
import { UserCallbackAlert } from "../../utils/alert/UserAlertForm";
import { CouponModifyModal } from "./modal";
import { CouponSendSetting } from "./modal/CouponSendSetting";

const CouponMng = () => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      cp_name: "",
      cp_type: "ALL",
      date_type: "regist",
      start_dt: getFormattedFirstDay(),
      end_dt: getFormattedLastDay(),
    },
  });
  const inputCpName = watch("cp_name");
  const cpType = watch("cp_type");
  const dateType = watch("date_type");
  const startDt = watch("start_dt");
  const endDt = watch("end_dt");

  const couponQrRef = useRef({});

  const [isShowCouponModal, setIsShowCouponModal] = useState(false);
  const [isShowSendModal, setIsShowSendModal] = useState(false);

  const [selectedCouponList, setSelectedCouponList] = useState({});
  const [selectedCpCode, setSelectedCpCode] = useState(""); // 단건 조회할 쿠폰번호
  const [selectedCpName, setSelectedCpName] = useState(""); // 단건 조회할 쿠폰명

  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 결제 내역 리스트 조회
  const [couponList, setCouponList] = useState([]); // 결제내역 리스트

  const listPerPage = 10;
  const [couponKey, setCouponKey] = useState(0);

  // 쿠폰 내역 리스트 조회
  const fetchData = () => {
    const cp_name = inputCpName ? `&cp_name=${inputCpName}` : "";
    const cp_type = cpType === "ALL" ? "" : `&cp_type=${cpType}`;

    setLoading(true);
    fetch(
      `/api/coupon/list?st_code=${
        setToAdminTransAcc().stCode
      }&date_type=${dateType}&start_dt=${startDt}&end_dt=${endDt}${cp_name}${cp_type}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, i) => (data.id = i + 1));
          setCouponList(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setValue("start_dt", settingDate.startDt);
    setValue("end_dt", settingDate.endDt);
    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const couponCurrentTab = getCurrentList(couponList, couponKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setCouponKey(selected);
  };

  // 발행 쿠폰 보기 (새 창 오픈)
  const handleShowCouponQR = (cp_code) => {
    const currentRef = couponQrRef.current[cp_code];
    const getCpName = currentRef.querySelector("#cp_name").innerText;

    const period = currentRef.querySelector("#period_date").innerText;
    const getDate = period.replaceAll(" ", "").split("~");

    const showUrl = `/show/coupon?cp_code=${cp_code}&ps_name=${
      setToAdminTransAcc().custName
    }&cp_name=${getCpName}&start_dt=${getDate[0]}&end_dt=${getDate[1]}`;
    window.open(showUrl, "_blank", "width=412, height=587");
  };

  // 쿠폰 정보 확인
  const handleShowDetailCoupon = (cp_code) => {
    const currentRefID = couponQrRef.current[cp_code].id;
    const getList = couponList.find(
      (coupon) => coupon.cp_code === currentRefID
    );
    getList.discount_unit = getFormattedPrice(getList.discount_unit);
    setSelectedCouponList(getList);
    setIsShowCouponModal(true);
  };

  // 쿠폰 발행
  const handleSendCoupon = (cp_code) => {
    subscriptStateCheck("coupon", setToAdminTransAcc().stCode).then(
      (result) => {
        if (result === true) {
          const currentRef = couponQrRef.current[cp_code];
          setSelectedCpCode(currentRef.id);
          setSelectedCpName(currentRef.querySelector("#cp_name").innerText);
          setIsShowSendModal(true);
        } else if (result === "insufficient") {
          alert(
            "* 알림톡 발송 잔여 금액이 부족합니다.\n  알림톡 서비스 메뉴에서 추가 충전바랍니다."
          );
        } else {
          alert(
            "* 알림톡 서비스 미가입자는 쿠폰이 발행되지 않사오니,\n  이용을 원하시는 경우 알림톡 서비스 메뉴에서 가입바랍니다."
          );
        }
      }
    );
  };

  // 쿠폰 삭제
  const handleDelCoupon = (list) => {
    if (getFormattedDateHyphen() <= list.end_dt) {
      if (list.edit_able !== 1) {
        const message =
          list.cp_type === "ONCE" && list.edit_able === 0
            ? "한 번 이상 발행된 쿠폰은 삭제할 수 없습니다.\n기간 만료 후 삭제해주시길 바랍니다."
            : "사용기간이 만료되지 않은 쿠폰은 삭제할 수 없습니다.";

        alert(message);
        return;
      }
    }

    setIsShowUserCallbackAlert(true);
    setCancelAlert(true);
    setUserRequestDatas({
      cp_code: list.cp_code,
    });
  };

  return (
    <Fragment>
      <div className="title search-div d-flex flex-row">
        <div className="col-lg-12 period-search pl-0 pr-0">
          <div className="col-lg-2 pl-0">
            <input
              type="text"
              id="search_name"
              name="search_name"
              placeholder="쿠폰명"
              className="form-control"
              onKeyDown={keyDownFnc}
              {...register("cp_name")}
            />
          </div>
          <div className="col-lg-1 pl-0 pr-0">
            <select className="form-control" {...register("cp_type")}>
              <option value="ALL">전체</option>
              <option value="ONCE">일회성</option>
              <option value="MULTI">다회성</option>
            </select>
          </div>
          <div className="col-lg-1 pr-0">
            <select className="form-control" {...register("date_type")}>
              <option value="regist">등록일자</option>
              <option value="start">사용시작일</option>
              <option value="end">사용종료일</option>
            </select>
          </div>
          <div className="col-lg-2 resize-period pr-0">
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              오늘
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              이번달
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              올해
            </button>
          </div>
          <div className="col-lg-4" style={{ padding: "0" }}>
            <div className="input-con col-lg-4">
              <input
                type="date"
                id="between_day"
                name="between_day"
                className="form-control"
                {...register("start_dt")}
              />
              <span>~</span>
            </div>
            <div className="input-con col-lg-4">
              <input
                type="date"
                id="and_day"
                name="and_day"
                className="form-control"
                {...register("end_dt")}
              />
            </div>
            <div className="input-con col-lg-3 resize-sch-btn">
              <button
                type="submit"
                className="btn btn-outline-secondary search-btn"
                onClick={handleSearchClick}
              >
                <BsSearch style={{ fontWeight: "bold" }} />
                &nbsp; 조회
              </button>
            </div>
          </div>
          <div className="col-lg-2 resize-btn float-right px-0 cc-col-right">
            <button
              type="button"
              className="btn btn-primary d-flex align-items-center float-right mr-0"
              onClick={() => setIsShowCouponModal(true)}
            >
              <FaStar />
              &nbsp;쿠폰 등록
            </button>
          </div>
        </div>
      </div>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={
                    "table text-center fontS add-table resize-table " +
                    `${couponCurrentTab.length > 0 ? "" : "history-table"}`
                  }
                >
                  <thead>
                    <tr>
                      <th style={{ width: "60px" }} scope="col">
                        No
                      </th>
                      <th style={{ width: "300px" }} scope="col">
                        쿠폰명
                      </th>
                      <th style={{ width: "80px" }} scope="col">
                        종류
                      </th>
                      <th style={{ width: "180px" }} scope="col">
                        할인 금액/비율
                      </th>
                      <th style={{ width: "300px" }} scope="col">
                        사용기간
                      </th>
                      <th style={{ width: "80px" }} scope="col">
                        발행횟수
                      </th>
                      <th style={{ width: "220px" }} scope="col">
                        등록일자
                      </th>
                      {/* <th style={{ width: "80px" }} scope="col">
                        시행여부
                      </th> */}
                      <th style={{ width: "80px" }} scope="col">
                        쿠폰보기
                      </th>
                      <th style={{ width: "80px" }} scope="col">
                        쿠폰발행
                      </th>
                      <th style={{ width: "130px" }} scope="col">
                        관리
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponCurrentTab.length > 0 &&
                      couponCurrentTab.map((list, i) => {
                        return (
                          <tr
                            key={list.cp_code}
                            id={list.cp_code}
                            ref={(el) =>
                              (couponQrRef.current[list.cp_code] = el)
                            }
                          >
                            <td>{list.id}</td>
                            <td id="cp_name">{list.cp_name}</td>
                            <td>
                              {list.cp_type === "MULTI" ? "다회성" : "일회성"}
                            </td>
                            <td>
                              {getFormattedPrice(list.discount_unit)}
                              {list.discount_type === "AMOUNT" ? "원" : "%"}
                            </td>
                            <td id="period_date">
                              {list.start_dt} ~ {list.end_dt}
                            </td>
                            <td>
                              {list.use_type === "INFINITE"
                                ? Number(list.use_limit) === -1
                                  ? "∞"
                                  : getFormattedPrice(list.use_limit)
                                : getFormattedPrice(list.use_limit)}
                            </td>
                            <td>{setFormattedDate(list.reg_dt)}</td>
                            {/* <td>시행여부</td> */}
                            <td>
                              <div className="d-flex justify-content-sm-around align-items-center">
                                <div
                                  title="쿠폰보기"
                                  style={{ fontSize: "18px" }}
                                  className={`modify-icon ${
                                    list.cp_type === "ONCE"
                                      ? "coupon-disabled"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleShowCouponQR(list.cp_code)
                                  }
                                >
                                  <FaLink />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-sm-around align-items-center">
                                <div
                                  title="쿠폰발행"
                                  style={{ fontSize: "18px" }}
                                  className={`modify-icon ${
                                    list.end_dt < getFormattedDateHyphen()
                                      ? "coupon-disabled"
                                      : ""
                                  }`}
                                  onClick={() => handleSendCoupon(list.cp_code)}
                                >
                                  <FaPaperPlane />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-sm-around align-items-center">
                                <div
                                  title="상세보기"
                                  style={{ fontSize: "20px" }}
                                  className="modify-icon"
                                  onClick={() =>
                                    handleShowDetailCoupon(list.cp_code)
                                  }
                                >
                                  {list.edit_able === 1 ? (
                                    <FaPen style={{ fontSize: "16px" }} />
                                  ) : (
                                    <FaEye />
                                  )}
                                </div>
                                <div
                                  id="end_dt"
                                  title="쿠폰삭제"
                                  style={{ fontSize: "18px" }}
                                  className="modify-icon"
                                  onClick={() => handleDelCoupon(list)}
                                >
                                  <FaTrash />
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(couponList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <CouponModifyModal
        isShowCouponModal={isShowCouponModal}
        setIsShowCouponModal={setIsShowCouponModal}
        selectedCouponList={selectedCouponList}
        setSelectedCouponList={setSelectedCouponList}
      />
      <CouponSendSetting
        isShowSendModal={isShowSendModal}
        setIsShowSendModal={setIsShowSendModal}
        selectedCpCode={selectedCpCode}
        setSelectedCpCode={setSelectedCpCode}
        selectedCpName={selectedCpName}
        setSelectedCpName={setSelectedCpName}
      />
      {isShowUserCallbackAlert &&
        cancelAlert &&
        UserCallbackAlert(
          "쿠폰을 삭제하시겠습니까?",
          [],
          "확인",
          true,
          "caution",
          function () {
            setUserRestStep({ key: 40, control: "delete" });
            setCancelAlert(false);
          }
        )}
    </Fragment>
  );
};

export default CouponMng;
