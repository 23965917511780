import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsSearch, BsXLg } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { useComponentsContext } from "../../ContextProvider";
import {
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  setToAdminTransAcc,
  transTypeKor,
  useTypeKor,
} from "../../../common/utils/FunctionUtility";

const PointList = ({ validateChecked, selectedContact }) => {
  const { setLoading } = useComponentsContext();

  const { register, watch, reset } = useForm({
    defaultValues: {
      transaction_type: "ALL",
      start_dt: getFormattedFirstDay(),
      end_dt: getFormattedLastDay(),
    },
  });
  const transactionType = watch("transaction_type");
  const startDt = watch("start_dt");
  const endDt = watch("end_dt");

  const [staticTables, setStaticTables] = useState({
    title: "누적 적립 포인트",
    title2: "누적 사용 포인트",
    title3: "잔여 포인트",
    datas: [0, 0, 0],
  });

  const [pointList, setPointList] = useState([]);
  const listPerPage = 10;
  const [pointKey, setPointKey] = useState(0);

  const fetchDataState = () => {
    fetchAndSetData("state", setStaticTables);
  };
  // 회원 포인트 내역 조회
  const fetchDataByInfo = () => {
    fetchAndSetData("history", setPointList);
  };

  // 공통 기간검색 fetch
  const fetchAndSetData = (url, callback) => {
    if (selectedContact.length === 11) {
      setLoading(true);
      fetch(
        `/api/member/point/${url}?st_code=${pointHistoryListByStCode()}&contact=${selectedContact}${
          url === "history" ? listSearchParamSet() : ""
        }`
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          if (res.result) {
            const resultData = dataSettings(url, res.data);
            callback(resultData);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const pointHistoryListByStCode = () => {
    return localStorage.getItem("acc_url")
      ? localStorage.getItem("acc_st_code")
      : setToAdminTransAcc().stCode;
  };

  const listSearchParamSet = () => {
    return `&start_dt=${startDt}&end_dt=${endDt}&transaction_type=${
      transactionType === "ALL" ? "" : transactionType
    }`;
  };

  // fetch별 데이터 세팅
  const dataSettings = (url, data) => {
    if (url === "state") {
      staticTables.datas = [data.earn_total, data.use_total, data.remain_point];
      return staticTables;
    } else {
      data.map((list, i) => (list.id = i + 1));
      return data;
    }
  };

  // 검색 버튼 클릭
  const handleSearchBtnClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    fetchDataByInfo();
  };

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const listCurrentTab = getCurrentList(pointList, pointKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setPointKey(selected);
  };

  useLayoutEffect(() => {
    if (selectedContact) {
      fetchDataState();
      fetchDataByInfo();
    } else {
      reset();
      setTimeout(() => {
        setPointList([]);
        setPointKey(0);
      }, 300);
    }
  }, [selectedContact]);

  return (
    <Fragment>
      <div className="table-responsive" style={{ marginBottom: "1.5rem" }}>
        <div className="table-stats order-table ov-h c-table">
          <div className="d-flex justify-content-sm-between">
            <table className="table text-center fontS add-table ">
              <thead className="thead-dark">
                <tr>
                  <th>{staticTables.title}</th>
                  <th>{staticTables.title2}</th>
                  <th>{staticTables.title3}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {staticTables.datas.length > 0 &&
                    staticTables.datas.map((state, i) => {
                      return <td key={i}>{getFormattedPrice(state)} P</td>;
                    })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="m-0 sch_box">
        <div
          className={`form-group ${validateChecked ? "validate-point" : "row"}`}
        >
          <div
            className={`col-lg-4 ${validateChecked ? "validate-select" : ""}`}
          >
            <select
              id="search_grade"
              name="search_grade"
              className="form-control"
              {...register("transaction_type")}
            >
              <option value={"ALL"}>전체</option>
              <option value={"EARN"}>적립</option>
              <option value={"USE"}>사용</option>
              <option value={"CANCEL"}>취소</option>
            </select>
          </div>
          <div className="d-flex col-lg-6" style={{ padding: "0" }}>
            <div className="input-con col-lg-6 d-flex justify-content-center">
              <input
                type="date"
                id="between_day"
                name="between_day"
                className="form-control"
                {...register("start_dt")}
              />
              <span>~</span>
            </div>
            <div className="input-con col-lg-6">
              <input
                type="date"
                id="and_day"
                name="and_day"
                className="form-control"
                {...register("end_dt")}
              />
            </div>
          </div>
          <div
            className={`row col-md-2-1 ${
              validateChecked ? "validate-search" : "ml-1"
            }`}
          >
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm"
              onClick={handleSearchBtnClick}
            >
              <BsSearch style={{ fontWeight: "bold" }} /> &nbsp; 조회
            </button>
          </div>
        </div>
      </div>
      <div className="order-table ov-h c-table" style={{ marginTop: "1.5rem" }}>
        <table
          className={
            "table text-center fontS add-table info-table " +
            `${listCurrentTab.length > 0 ? "" : "minHeight-table"}`
          }
        >
          <thead>
            <tr>
              <th scope="col" style={{ width: "60px" }}>
                No
              </th>
              <th scope="col" style={{ width: "200px" }}>
                일자
              </th>
              <th scope="col" style={{ width: "150px" }}>
                거래 구분
              </th>
              <th scope="col" style={{ width: "100px" }}>
                포인트 구분
              </th>
              <th scope="col" style={{ width: "160px" }}>
                적립 및 사용 포인트
              </th>
            </tr>
          </thead>
          <tbody>
            {listCurrentTab.length > 0 &&
              listCurrentTab.map((list, i) => {
                return (
                  <tr key={i} className={list.is_cancel ? "is_cancel_row" : ""}>
                    <td>{list.id}</td>
                    <td>{list.reg_dt}</td>
                    <td>{useTypeKor(list.use_type)}</td>
                    <td>{transTypeKor(list.transaction_type)}</td>
                    <td>{getFormattedPrice(list.point)} P</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="c-pagination prevent-drag">
        <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          pageCount={Math.ceil(pointList.length / listPerPage)}
          breakLabel={false}
          marginPagesDisplayed={false}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </Fragment>
  );
};

export default PointList;
