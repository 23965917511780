import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { BsSearch, BsXLg } from "react-icons/bs";
import {
  getFormattedDateHyphen,
  getGradeList,
  handleKeyPress,
  phoneRegularExpFnc,
  phoneRemoveHyphen,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";

const CouponSendSetting = (props) => {
  const {
    setLoading,
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
  } = useComponentsContext();

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      send_type: "scheduled",
      dateFormat: {
        schde_dt: getFormattedDateHyphen(),
        period: "PM",
        hour: "12",
        minute: "00",
      },
      showList: {
        selectAll: false,
        name: "",
        contact: "",
        gd_name: "ALL",
      },
    },
  });
  const resveSendCheck = watch("send_type") === "scheduled" ? false : true;
  const inputName = watch("showList.name");
  const inputContact = watch("showList.contact");
  const inputGdName = watch("showList.gd_name");

  const [issueAlert, setIssueAlert] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [phoneList, setPhoneList] = useState([]);

  const [noticeTitle, setNoticeTitle] = useState("");

  // 등급 리스트 조회
  const getGradeNameList = async () => {
    const gradeList = await getGradeList(setToAdminTransAcc().stCode);
    setGradeList(gradeList);
  };

  // 공통 기간검색 fetch
  const fetchData = () => {
    setLoading(true);
    fetch(
      `/api/member/list?st_code=${
        setToAdminTransAcc().stCode
      }${listSearchParamSet()}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          // 체크 박스 초기화
          resetCheckbox();

          let gdNameArr = [];
          res.data.map((list, i) => {
            // 인덱스 추가
            list.id = i + 1;
            list.contact = phoneRegularExpFnc(list.contact);
          });
          setCouponList(res.data);
          res.data.forEach((item) => setValue(`id.${item.contact}`, false));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const listSearchParamSet = () => {
    return `&name=${inputName}&contact=${inputContact}&gd_name=${
      inputGdName === "ALL" ? "" : inputGdName
    }`;
  };

  /******** 체크박스 ********/
  // 모든 checkbox를 체크, 체크해제하는 로직
  const handleSelectAll = (e) => {
    if (e.currentTarget.checked) {
      // selectAll checkbox가 체크되면
      couponList.forEach((item) => {
        setValue(`id.${item.contact}`, true); // 모든 checkbox의 value를 true로
      });
    } else {
      // selectAll checkbox가 체크해제되면
      couponList.forEach((item) => {
        setValue(`id.${item.contact}`, false); // 모든 checkbox의 value를 false로
      });
    }
  };

  // checkbox가 다 선택 된 경우 selectAll checkbox가 check
  useEffect(
    () => {
      const selected = couponList.filter(
        (item) => watch(`id.${item.contact}`) === true
      );

      // 선택된 아이템 phoneList에 담기
      const selectedIds = selected.map((item) =>
        phoneRemoveHyphen(item.contact)
      );
      setPhoneList(selectedIds);

      // 선택된 체크박스 length
      const count = selected.length;
      setSelectedCount(count);

      if (
        couponList.length > 0 &&
        couponList.every((item) => watch(`id.${item.contact}`) === true)
      ) {
        setValue("showList.selectAll", true);
      } else {
        setValue("showList.selectAll", false);
      }
    },
    couponList.map((item) => watch(`id.${item.contact}`))
  );

  const resetCheckbox = () => {
    setValue("showList.selectAll", false);
    setSelectedCount(0);
    setPhoneList([]);
  };

  // 저장 버튼 클릭
  const handleSendSettingInfo = (data) => {
    if (phoneList.length > 0) {
      ["id", "showList", "send_type"].forEach((value) => {
        delete data[value];
      });
      if (resveSendCheck) {
        delete data.dateFormat;
      }
      data.st_code = setToAdminTransAcc().stCode;
      data.cp_code = props.selectedCpCode;
      data.phone_list = phoneList;
      setUserRequestDatas(data);

      // 띄울 알림 종류 선택
      setNoticeTitle("쿠폰을 발행하시겠습니까?");
      setIsShowUserCallbackAlert(true);
      setIssueAlert(true);
    } else {
      alert("쿠폰을 발송 할 회원을 한 명 이상 선택해주세요.");
    }
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    setUserRestStep({
      key: 40,
      control: "issue",
      callback: function (msg) {
        failedSendControl(msg);
      },
    });
  };

  // 발행 설정 실패 처리
  const failedSendControl = (msg) => {
    if (msg === "FAILED_NONE_MEMBER") {
      alert(
        "알림톡 서비스 미가입자로, 쿠폰을 발행할 수 없습니다.\n가입 후 다시 발행해 주시기를 바랍니다."
      );
    } else if (msg === "FAILED_REMAIN_AMT") {
      alert(
        "알림톡 서비스 요금이 부족합니다.\n충전 후 다시 발행해 주시기를 바랍니다."
      );
    }
  };

  const handleSearchBtnClick = () => {
    fetchData();
  };

  // 행 클릭으로 체크박스 체크/해제
  const handleRowClick = (contact) => {
    const currentValue = watch(`id.${contact}`);
    setValue(`id.${contact}`, !currentValue);
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
    }
  };

  useEffect(() => {
    if (props.isShowSendModal) {
      fetchData();
    } else {
      setCouponList([]);
      setIssueAlert(false);
    }
  }, [props.isShowSendModal]);

  useEffect(() => {
    getGradeNameList();
  }, []);

  return (
    <Fragment>
      <Modal
        show={props.isShowSendModal}
        onHide={() => {
          props.setIsShowSendModal(false);
        }}
        className="coupon-setting-modal resize-mod-modal"
      >
        <Modal.Header>
          <Modal.Title>쿠폰 발행 설정</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowSendModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleSendSettingInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body style={{ width: "50rem" }}>
            <div className="order-table ov-h c-table">
              <table className="usermng-mod-table coupon-search-mod-table">
                <tbody>
                  <tr>
                    <th style={{ width: "10rem" }}>쿠폰명</th>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="쿠폰명"
                        disabled
                        defaultValue={props.selectedCpName}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>발송일시</th>
                    <td>
                      <div className="d-flex align-items-center justify-content-sm-between">
                        <label key={0}>
                          <input
                            className="mr-3"
                            type="radio"
                            name="send_type"
                            value="scheduled"
                            {...register("send_type")}
                          />
                          예약 발송
                        </label>
                        <div className="d-flex justify-content-sm-end">
                          <input
                            type="date"
                            className="form-control col-lg-4 mr-2"
                            disabled={resveSendCheck}
                            min={getFormattedDateHyphen()}
                            required
                            {...register("dateFormat.schde_dt")}
                          />
                          <select
                            className="form-control col-lg-3 mr-2"
                            disabled={resveSendCheck}
                            {...register("dateFormat.period")}
                          >
                            <option value="AM">오전</option>
                            <option value="PM">오후</option>
                          </select>
                          <select
                            className="form-control col-lg-3 mr-2"
                            disabled={resveSendCheck}
                            {...register("dateFormat.hour")}
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                              (hours) => (
                                <option value={hours} key={hours}>
                                  {hours}시
                                </option>
                              )
                            )}
                          </select>
                          <select
                            className="form-control col-lg-3"
                            disabled={resveSendCheck}
                            {...register("dateFormat.minute")}
                          >
                            {[0, 10, 20, 30, 40, 50].map((minute) => (
                              <option
                                key={minute}
                                value={minute.toString().padStart(2, "0")}
                              >
                                {minute.toString().padStart(2, "0")}분
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex mt-2">
                        <label key={1} className="mr-3">
                          <input
                            className="mr-3"
                            type="radio"
                            name="send_type"
                            value="immediately"
                            {...register("send_type")}
                          />
                          즉시 발송
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="m-0 sch_box mt-2">
                  <div className="row form-group mb-2">
                    <div className="col-lg-3 pr-0">
                      <input
                        type="text"
                        id="search_name"
                        name="search_name"
                        placeholder="이름"
                        className="form-control"
                        onKeyDown={keyDownFnc}
                        defaultValue=""
                        {...register("showList.name")}
                      />
                    </div>
                    <div className="col-lg-3 pr-0">
                      <input
                        type="text"
                        id="search_name"
                        name="search_name"
                        placeholder="연락처"
                        className="form-control"
                        onKeyDown={keyDownFnc}
                        {...register("showList.contact")}
                      />
                    </div>
                    <div className="col-lg-3 pr-0">
                      <select
                        id="search_grade"
                        name="search_grade"
                        className="form-control"
                        {...register("showList.gd_name")}
                      >
                        <option value={"ALL"}>등급</option>
                        {gradeList.length > 0 &&
                          gradeList.map((grade) => {
                            return (
                              <option key={grade.gd_name} value={grade.gd_name}>
                                {grade.gd_name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="row ml-2">
                      <button
                        type="button"
                        className="btn btn-outline-secondary btn-sm"
                        onClick={handleSearchBtnClick}
                      >
                        <BsSearch style={{ fontWeight: "bold" }} /> &nbsp; 조회
                      </button>
                      <span className="ml-2 pt-2 pb-1">
                        선택 : {selectedCount}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="table-resposive">
                  <div className="table-stats order-table ov-h c-table">
                    <div className="order-table ov-h c-table coupon-mod-table">
                      <table className={"table text-center fontS add-table "}>
                        <thead>
                          <tr>
                            <th style={{ width: "60px" }} scope="col">
                              No
                            </th>
                            <th style={{ width: "60px" }} scope="col">
                              <div className="custom-checkbox">
                                <input
                                  type="checkbox"
                                  {...register("showList.selectAll")}
                                  onChange={(e) => handleSelectAll(e)}
                                  style={{
                                    width: "25px",
                                    height: "15px",
                                  }}
                                  id="customCheck"
                                />
                              </div>
                            </th>
                            <th style={{ width: "100px" }} scope="col">
                              이름
                            </th>
                            <th style={{ width: "150px" }} scope="col">
                              연락처
                            </th>
                            <th style={{ width: "100px" }} scope="col">
                              등급
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {couponList.length > 0 &&
                            couponList.map((list, i) => {
                              return (
                                <tr
                                  key={i}
                                  onClick={(e) => {
                                    if (e.target.type !== "checkbox") {
                                      handleRowClick(list.contact);
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{list.id}</td>
                                  <td>
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: "25px",
                                          height: "15px",
                                        }}
                                        id={list.contact}
                                        {...register(`id.${list.contact}`)}
                                        key={`${list.contact}`}
                                        onClick={(e) => e.stopPropagation()} // 부모 행 클릭 방지
                                      />
                                    </div>
                                  </td>
                                  <td>{list.name}</td>
                                  <td>{list.contact}</td>
                                  <td>{list.gd_name}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              발행
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowSendModal(false)}
            >
              취소
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        issueAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { CouponSendSetting };
