import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const MngServicesPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/manager/services") {
      navigate("/manager/services/userMng");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default MngServicesPage;
