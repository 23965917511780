import { Fragment, useEffect, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useComponentsContext } from "../../../ContextProvider";

import { ReservationCalendar, ReservationList } from "./management";
import userMenuList from "../userMenuList.json";
import { ReservationModifyModal } from "./modal/ReservationModifyModal";
import {
  emptyToHyphen,
  setToAdminTransAcc,
  subscriptStateCheck,
} from "../../../../common/utils/FunctionUtility";

const ReservationMng = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const resveMenuList = userMenuList.list[4].subMenuList[0].insideMenuList;

  const [subscriptState, setSubscriptState] = useState(true);
  const [isShowResveModal, setIsShowResveModal] = useState(false); // 예약 등록, 수정 모달 useState
  const [selectedResveNo, setSelectedResveNo] = useState(""); // 단건 조회할 예약번호
  const [selectedResveDt, setSelectedResveDt] = useState(""); // 캘린더 날짜 클릭 시 가져올 날짜

  const [staticTables, setStaticTables] = useState([]); // 오늘자 예약 현황 데이터

  // 선택 된 탭 useState
  const userPath = location.pathname.replace("/user/reservation/resveMng/", "");
  const [resveCurrentTab, setResveCurrentTab] = useState(() => {
    const menuTab = resveMenuList.find((menu) => menu.id === userPath);
    return menuTab ? menuTab.tab : 100;
  });

  // 오늘 예약 현황 조회
  const todayStaticfetchData = () => {
    fetch(`/api/reservation/state?st_code=${setToAdminTransAcc().stCode}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setStaticTables(res.data);
        }
      })
      .catch((error) => console.error(error));
  };

  // 예약 메뉴 컴포넌트
  const renderContent = (tab) => {
    switch (tab) {
      case 100:
        return (
          <ReservationCalendar
            setIsShowResveModal={setIsShowResveModal}
            setSelectedResveNo={setSelectedResveNo}
            setSelectedResveDt={setSelectedResveDt}
            research={() => todayStaticfetchData()}
          />
        );
      case 101:
        return (
          <ReservationList
            setIsShowResveModal={setIsShowResveModal}
            setSelectedResveNo={setSelectedResveNo}
            research={() => todayStaticfetchData()}
          />
        );
    }
  };

  // 메뉴 버튼 클릭
  const handleMenuClick = (e, menuNo) => {
    setResveCurrentTab(menuNo);
  };

  // 탭 변경에 따른 경로 변경
  useEffect(() => {
    if (resveCurrentTab >= 100) {
      navigate(`${resveMenuList[resveCurrentTab - 100].id}`);
    }
  }, [resveCurrentTab]);

  // 경로 변경에 따른 탭 상태 변경
  useEffect(() => {
    const basePath = "/user/reservation/resveMng";
    if (location.pathname === `${basePath}`) {
      navigate(`${basePath}/calendar`);
    } else {
      const pathname = location.pathname.replace(`${basePath}/`, "");
      const getMenu = resveMenuList.find((menu) => menu.id === pathname);
      setResveCurrentTab(getMenu.tab);
    }
  }, [location.pathname]);

  useEffect(() => {
    subscriptStateCheck("reservation", setToAdminTransAcc().stCode).then(
      (result) => {
        setSubscriptState(result);
      }
    );
    todayStaticfetchData();
  }, []);

  return (
    <Fragment>
      <div className="mb-4 alert-light shadow pt-sm-3 pb-sm-3 pp-m1 cf menu-header">
        <h1 className="h5 mb-0 text-gray-800 ml-3 float-l">예약관리</h1>
        <div className="pl-0 pr-0 float-r mr-3">
          {resveMenuList.map((menu, i) => {
            return (
              <button
                key={menu.tab}
                id={menu.id}
                type="button"
                className={`btn ml-2 ${
                  100 + i === resveCurrentTab ? "btn-primary" : "btn-secondary"
                }`}
                onClick={(e) => handleMenuClick(e, i + 100)}
              >
                {menu.name}
              </button>
            );
          })}
        </div>
      </div>
      {!subscriptState && (
        <div className="w-100 h-100 service-notice mb-4">
          <span>
            ※ 알림톡 서비스 미가입자는 예약정보에 대한 알림톡이 발송되지
            않사오니, 이용을 원하시는 경우 알림톡 서비스 메뉴에서 가입해주시길
            바랍니다.
          </span>
          <p className="ml-4">
            <Link to="/user/services">→ 알림톡 서비스 가입하기</Link>
          </p>
        </div>
      )}
      <div className="col-lg-12 pl-0 pr-0 mb-4">
        <div className="table-responsive">
          <div className="order-table ov-h c-table">
            <div className="d-flex justify-content-sm-between">
              {Object.keys(staticTables).length > 0 && (
                <table className="table text-center fontS add-table userinfo-table">
                  <thead className="thead-dark">
                    <tr>
                      <th>오늘 대기 건수</th>
                      <th>오늘 확정 건수</th>
                      <th>오늘 취소 건수</th>
                      <th>오늘 노쇼 건수</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{emptyToHyphen(staticTables.pending)}</td>
                      <td>{emptyToHyphen(staticTables.confirmed)}</td>
                      <td>{emptyToHyphen(staticTables.canceled)}</td>
                      <td>{emptyToHyphen(staticTables.no_show)}</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <Routes>
        {resveMenuList.map((menu) => (
          <Route
            key={menu.id}
            path={`${menu.id}`}
            element={renderContent(resveCurrentTab)}
          />
        ))}
      </Routes>
      <ReservationModifyModal
        subscriptState={subscriptState}
        selectedResveNo={selectedResveNo}
        setSelectedResveNo={setSelectedResveNo}
        selectedResveDt={selectedResveDt}
        setSelectedResveDt={setSelectedResveDt}
        isShowResveModal={isShowResveModal}
        setIsShowResveModal={setIsShowResveModal}
      />
    </Fragment>
  );
};

export default ReservationMng;
