import { Fragment, useEffect, useState } from "react";
import { useComponentsContext } from "../../../components/ContextProvider";
import notice from "./HowToUseByMenu.json";

const HowToUse = ({ currentMenu, cardWidth, alreadyShow }) => {
  const { isShowHowToUse, setIsShowHowToUse } = useComponentsContext();

  const [noticeContent, setNoticeContent] = useState({});

  useEffect(() => {
    setNoticeContent(notice.menus[currentMenu]);
  }, [currentMenu]);

  useEffect(() => {
    setIsShowHowToUse(alreadyShow);
  }, [alreadyShow]);

  // *content* 표시는 파란색으로 표시
  const HighlightedText = (content) => {
    // 첫 번째 *와 두 번째 *의 인덱스를 찾기
    const firstStarIndex = content.indexOf("*");
    const secondStarIndex = content.indexOf("*", firstStarIndex + 1);

    if (firstStarIndex === -1 || secondStarIndex === -1) {
      // *가 두 개 미만일 경우, 그대로 텍스트를 반환
      return content;
    }

    // * 이전, * 사이, * 이후로 문자열을 나누기
    const beforeContent = content.slice(0, firstStarIndex);
    const highlight = content.slice(firstStarIndex + 1, secondStarIndex);
    const afterContent = content.slice(secondStarIndex + 1);

    return (
      <span>
        {beforeContent}
        <b style={{ color: "#4e73df" }}>{highlight}</b>
        {afterContent}
      </span>
    );
  };

  return (
    <Fragment>
      {isShowHowToUse && Object.keys(noticeContent).length > 0 && (
        <div
          className={`${
            cardWidth === "half" ? "col-lg-6" : "col-lg-12 pl-0 mb-4"
          } pr-0 notice_card`}
        >
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-stats order-table ov-h c-table">
                  <div className="title">
                    <h5>{noticeContent.name} 사용 안내</h5>
                  </div>
                  <ul className="notice-ul">
                    <li type="none">
                      <b>{noticeContent.name}이란?</b>
                    </li>
                    <li type="none">{noticeContent.mean}</li>
                  </ul>
                  <ul className="notice-ul mt-0" style={{ borderTop: "0" }}>
                    <li type="none">
                      <b>용어 안내</b>
                    </li>
                    {noticeContent.term.map((term, i) => {
                      return (
                        <li key={i} style={{ marginLeft: "1.5rem" }}>
                          {HighlightedText(term)}
                        </li>
                      );
                    })}
                  </ul>
                  <ul
                    className="notice-ul mt-0 mb-2"
                    style={{ borderTop: "0" }}
                  >
                    <li type="none">
                      <b>사용 안내</b>
                    </li>
                    {noticeContent.use.map((use, i) => {
                      return (
                        <li key={i} style={{ marginLeft: "1.5rem" }}>
                          {HighlightedText(use)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HowToUse;
