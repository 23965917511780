import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import { useComponentsContext } from "../ContextProvider";

import userMenuList from "./menus/userMenuList.json";
import UserRestFunctions from "./utils/UserRestFunctions";

const UserPage = () => {
  const { isShowHowToUse, setIsShowHowToUse } = useComponentsContext();
  const adminToCustAcc = localStorage.getItem("adminToCustAcc");

  const navigate = useNavigate();
  const stCode = localStorage.getItem("stCode");

  const titleFromPathname = () => {
    const getSubMenuTitleName = userMenuList.list
      .find((menu) => location.pathname.includes(menu.path))
      ?.subMenuList?.find((sub) => location.pathname.includes(sub.id))?.name;

    return getSubMenuTitleName;
  };

  useEffect(() => {
    // stCode가 없는 경우 로그인 화면으로 이동
    if (!stCode) {
      navigate("/login");
    }

    if (location.pathname === "/user" || location.pathname === "/user/") {
      navigate("/user/services");
    }

    if (
      adminToCustAcc !== "ACCESS" &&
      localStorage.getItem("webId") === "admin"
    ) {
      alert("선택된 가맹점이 없어 해당 경로로 접근할 수 없습니다.");
      navigate("/manager");
      return;
    }
  }, []);

  return (
    <Fragment>
      {!location.pathname.includes("/resveMng") && (
        <div className="mb-4 alert-light shadow pt-sm-3 pb-sm-3 pp-m1 cf menu-header">
          <h1 className="h5 mb-0 text-gray-800 ml-3 float-l">
            {titleFromPathname()}
          </h1>
          {(location.pathname.includes("/memberGrade") ||
            location.pathname.includes("/accmlPolicyMng") ||
            location.pathname.includes("/usePolicyMng")) && (
            <button
              className="mr-3 float-right btn d-flex align-items-center"
              onClick={() => setIsShowHowToUse(!isShowHowToUse)}
            >
              <FaQuestionCircle className="mr-2" />
              도움말
            </button>
          )}
        </div>
      )}
      <Outlet />
      <UserRestFunctions />
    </Fragment>
  );
};

export default UserPage;
