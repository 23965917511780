import { Fragment, useEffect, useState } from "react";
import { useComponentsContext } from "../../ContextProvider";
import { MngApproveAlert } from "./alert/MngAlertForm";

/**
 * [ 관리자 ]
 * menu - 알림톡 서비스
 * 1: 결제 취소               setMngRestStep(10)
 * 2: 회원 관리 수정          setMngRestStep(11)
 * 3: 금액 설정               setMngRestStep(12)
 *
 * menu - 쿠폰관리
 * 1. 쿠폰 발행               setMngRestStep(20)
 */
const ManagerRestFunctions = () => {
  const {
    setIsShowMngApproveAlert,
    mngRestStep,
    setMngRestStep,
    mngRequestDatas,
    setMngRequestDatas,
    modifyTabName,
  } = useComponentsContext();

  const [noticeAlert, setNoticeAlert] = useState(false);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [couponAlert, setCouponAlert] = useState(false);

  useEffect(() => {
    switch (mngRestStep) {
      case 0:
        break;
      case 10:
        cancelPayment();
        break;
      case 11:
        modifyUserInfo();
        break;
      case 12:
        modifyUnitPrice();
        break;
      case 20:
        sendCoupon();
        break;
      case 99:
        setNoticeAlert(false);
        setMngRestStep(0);
        break;
      default:
        break;
    }
  }, [mngRestStep]);

  // 10: 결제 취소 Api
  const cancelPayment = () => {
    const cancelUrl = `pay/cancel`;
    utilFetch(cancelUrl, "POST", mngRequestDatas);
  };

  // 11: 회원 관리 수정 Api
  const modifyUserInfo = () => {
    const modifyUrl = `member/reg/up`;
    utilFetch(modifyUrl, "POST", mngRequestDatas);
  };

  // 12: 단가 수정 Api
  const modifyUnitPrice = () => {
    let apiUrl = modifyTabName.includes("단가")
      ? "price/unit"
      : modifyTabName.includes("정기")
      ? "prod/sub"
      : "prod/add";
    utilFetch(`${apiUrl}/up`, "POST", mngRequestDatas);
  };

  // 20: 쿠폰 발행 Api
  const sendCoupon = () => {
    utilFetch(`coupon/market/issue`, "POST", mngRequestDatas);
  };

  // 정기 결제 공용 fetch함수
  const utilFetch = (url, method, param) => {
    const fetchOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (method === "POST") {
      fetchOptions.body = JSON.stringify(param);
    }

    fetch(`/api/manager/${url}`, fetchOptions)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          setMngRequestDatas(null);
          restStepByAlert(mngRestStep);
        }
      })
      .catch((error) => console.error(error));
  };

  // mngRestStep에 따른 완료 알림 띄우기
  const restStepByAlert = (step) => {
    setIsShowMngApproveAlert(true);
    switch (step) {
      case 10:
        setCancelAlert(true);
        break;
      case 11:
        setNoticeAlert(true);
        break;
      case 12:
        setNoticeAlert(true);
        break;
      case 20:
        setCouponAlert(true);
        break;
      default:
        setNoticeAlert(true);
        break;
    }
  };

  // 완료 알림 후 mngRestStep에 따른 콜백 처리
  const noticeFunction = (step) => {
    switch (step) {
      case 10:
        break;
      case 11:
        window.location.reload();
        break;
      case 12:
        window.parent.postMessage({
          request: "refetch",
          type: modifyTabName,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      {cancelAlert &&
        MngApproveAlert("결제가 취소되었습니다.", "caution", function () {
          window.open("https://office.easypay.co.kr/", "_blank");
          window.location.reload();
        })}
      {noticeAlert &&
        MngApproveAlert("수정이 완료되었습니다.", "notice", function () {
          noticeFunction(mngRestStep);
        })}
      {couponAlert &&
        MngApproveAlert("발행이 완료되었습니다.", "notice", function () {
          window.location.reload();
        })}
    </Fragment>
  );
};

export default ManagerRestFunctions;
