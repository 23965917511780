import { Fragment, useEffect, useState } from "react";
import {
  getTransDateTime,
  setToAdminTransAcc,
} from "../../../common/utils/FunctionUtility";
import { EasypayApi } from "./pg/api";
import { useComponentsContext } from "../../ContextProvider";
import { UserApproveAlert } from "./alert/UserAlertForm";

/**
 * [ 사용자 ]
 * 0: 동작 없음                  setUserRestStep({ key: 0 })
 *
 * [menu] 알림톡 서비스 (10)
 * 1: PG 일반 결제
 *    1-1: PG 결제창 오픈        setUserRestStep({ key: 1, control: 'basic' })
 *    1-2: PG 승인 요청          setUserRestStep({ key: 2 })
 *    1-3: PG 일반 결제 DB 저장  setUserRestStep({ key: 10 })
 *
 * 2: PG 정기 결제
 *    2-1: PG 결제창 오픈        setUserRestStep({ key: 1, control: 'regular' })
 *    2-2: PG 승인 요청          setUserRestStep({ key: 2 })
 *    2-3: PG 정기 결제 등록     setUserRestStep({ key: 11 })
 *    2-4: PG 결제 카드 변경     setUserRestStep({ key: 12 })
 *
 * 3: PG 정기 결제 해지          setUserRestStep({ key: 13 })
 *
 * [menu] 회원관리 (20, 21)
 * ## 회원정보관리 ##
 * 1: 회원 등록                  setUserRestStep({ key: 20, control: 'reg' })
 * 2: 회원 수정                  setUserRestStep({ key: 20, control: 'update' })
 * 3: 회원 삭제                  setUserRestStep({ key: 20, control: 'delete' })
 * 4. 회원 포인트 적립/차감      setUserRestStep({ key: 20, control: 'point/update' })
 *
 * ## 회원등급관리 ##
 * 1. 회원 등급 저장             setUserRestStep({ key: 21, control: 'update' })
 *
 * [menu] 포인트관리 (30, 31)
 * ## 적립 정책 관리 ##
 * 1: 적립 정책 수정             setUserRestStep({ key: 30, control: 'update' })
 * 2: 적립 정책 사용 여부 변경   setUserRestStep({ key: 30, control: 'update/status' })
 *
 * ## 사용 정책 관리 ##
 * 1: 사용 정책 등록             setUserRestStep({ key: 31, control: 'reg' })
 * 1: 사용 정책 수정             setUserRestStep({ key: 31, control: 'update' })
 * 1: 사용 정책 수정             setUserRestStep({ key: 31, control: 'delete' })
 * 1: 사용 정책 Active 지정      setUserRestStep({ key: 31, control: 'update/active' })
 *
 * [menu] 쿠폰관리 (40)
 * 1: 쿠폰 등록                  setUserRestStep({ key: 40, control: 'reg' })
 * 2. 쿠폰 삭제                  setUserRestStep({ key: 40, control: 'delete' })
 * 3. 쿠폰 발행                  setUserRestStep({ key: 40, control: 'issue' })
 *
 * [menu] 예약관리 (50)
 * 1: 예약 등록                  setUserRestStep({ key: 50, control: 'reg' })
 * 2: 예약 수정                  setUserRestStep({ key: 50, control: 'update' })
 * 3: 예약 삭제                  setUserRestStep({ key: 50, control: 'del' })
 */
const UserRestFunction = ({ mappedParams }) => {
  const {
    isShowUserApproveAlert,
    setIsShowUserApproveAlert,
    userRestStep,
    setUserRestStep,
    userRequestDatas,
    setUserRequestDatas,
    totalAmount,
    goodsName,
    noticeAlert,
    setNoticeAlert,
    cancelAlert,
    setCancelAlert,
  } = useComponentsContext();

  const msPdCode = sessionStorage.getItem("msPdCode");
  const stType = localStorage.getItem("stType");

  const [pgCompany, setPgCompany] = useState("Easypay");
  const [paymentInfo, setPaymentInfo] = useState([]);

  const [noticeByUrlName, setNoticeByUrlName] = useState("");
  const [showPayLoading, setShowPayLoading] = useState(false);

  const [noticeTitle, setNoticeTitle] = useState("");

  const [cancelTitle, setCancelTitle] = useState("");

  useEffect(() => {
    switch (userRestStep.key) {
      case 0:
        break;
      case 1:
        pgPaymentWindowOpen();
        break;
      case 2:
        requestApproval();
        break;
      case 10:
        basicPaymentJoin();
        break;
      case 11:
        reqularPaymentJoin();
        break;
      case 12:
        reqularCardUpdate();
        break;
      case 13:
        reqularPaymentCancel();
        break;
      case 20:
        controlMemberInfo();
        break;
      case 21:
        controlMemberGrade();
        break;
      case 30:
        controlAccmlPolicy();
        break;
      case 31:
        controlUsePolicy();
        break;
      case 40:
        controlCoupon();
        break;
      case 50:
        controlReservation();
        break;
      default:
        break;
    }
  }, [userRestStep.key]);

  // 1: PG 결제창 오픈
  const pgPaymentWindowOpen = () => {
    switch (pgCompany) {
      case "Easypay":
        const result = EasypayApi.tradesPgReg(
          `${userRestStep.control}`,
          goodsName,
          totalAmount
        );

        result.then((info) => {
          if (info.result) {
            setUserRestStep({ key: 0 });
            window.open(info.data, "_blank", "width=657, height=548");
          }
        });
        break;
    }
  };

  // 2: 승인 요청
  const requestApproval = () => {
    switch (pgCompany) {
      case "Easypay":
        let result = EasypayApi.requestApproval(mappedParams);
        result.then((res) => {
          if (res.result) {
            setPaymentInfo(res.data);
            res.paymentType === "pg"
              ? setUserRestStep({ key: 10 })
              : sessionStorage.getItem("paymentType") === "등록"
              ? setUserRestStep({ key: 11 })
              : setUserRestStep({ key: 12, control: "" });
          } else {
            alert("결제 승인 요청에 실패하였습니다. 다시 시도해주세요.");
            window.close();
          }
        });
        break;
    }
  };

  // 10: 일반 PG 결제 DB 저장
  const basicPaymentJoin = () => {
    const cardInfo = paymentInfo.paymentInfo.cardInfo;
    let param = {
      st_code: setToAdminTransAcc().stCode,
      ms_pd_code: msPdCode,
      amt_tot: paymentInfo.amount,
      acquire_name: cardInfo.acquirerName,
      card_mask_no: cardInfo.cardNo,
      trn_no: paymentInfo.pgCno,
      trn_type: paymentInfo.cpCode === "" ? "카드결제" : "간편결제",
      inst_month: cardInfo.installmentMonth,
      aprv_no: paymentInfo.paymentInfo.approvalNo,
    };
    sessionStorage.removeItem("msPdCode");

    utilFetch("payment/add", "POST", param);
  };

  // 11: 정기 결제 등록
  const reqularPaymentJoin = () => {
    const cardInfo = paymentInfo.paymentInfo.cardInfo;
    let param = {
      st_code: setToAdminTransAcc().stCode,
      batch_key: cardInfo.cardNo,
      st_type: stType,
      acquire_name: cardInfo.acquirerName,
      card_mask_no: cardInfo.cardMaskNo,
    };
    utilFetch("ms/join", "POST", param);
  };

  // 12: 정기 결제 카드 변경
  const reqularCardUpdate = () => {
    const cardInfo = paymentInfo.paymentInfo.cardInfo;
    let param = {
      st_code: setToAdminTransAcc().stCode,
      batch_key: cardInfo.cardNo,
      acquire_name: cardInfo.acquirerName,
      card_mask_no: cardInfo.cardMaskNo,
    };
    utilFetch("ms/update/card", "POST", param);
  };

  // 13: 정기 결제 해지
  const reqularPaymentCancel = () => {
    let param = {
      st_code: setToAdminTransAcc().stCode,
    };
    utilFetch("ms/cancel", "POST", param);
  };

  // 20: 회원 정보 control Api
  const controlMemberInfo = () => {
    const { control } = userRestStep;
    // 등록, 수정, 삭제
    const performMethod = control === "delete" ? "DELETE" : "POST";
    utilFetch(`member/${control}`, performMethod, userRequestDatas);
  };

  // 21: 회원 등급 control Api
  const controlMemberGrade = () => {
    const { control } = userRestStep;
    utilFetch(`grade/${control}`, "PUT", userRequestDatas);
  };

  // 30: 적립 정책 control Api
  const controlAccmlPolicy = () => {
    const { control } = userRestStep;
    utilFetch(`point/policy/earn/${control}`, "PUT", userRequestDatas);
  };

  // 31: 사용 정책 control Api
  const controlUsePolicy = () => {
    const { control } = userRestStep;
    // 등록, 수정, 삭제, active 설정
    if (control === "reg") {
      delete userRequestDatas.po_code;
    }

    let performMethod = "";
    switch (control) {
      case "reg":
      case "update/active":
        performMethod = "POST";
        break;
      case "update":
        performMethod = "PUT";
        break;
      case "delete":
        performMethod = "DELETE";
        break;
    }
    utilFetch(`point/policy/use/${control}`, performMethod, userRequestDatas);
  };

  // 40: 쿠폰 control Api
  const controlCoupon = () => {
    const { control } = userRestStep;

    // 삭제
    if (control === "delete") {
      deleteFetch(
        `coupon/delete?st_code=${setToAdminTransAcc().stCode}&cp_code=${
          userRequestDatas.cp_code
        }`
      );
      return;
    }

    // 등록, 수정, 발행, 예약 취소
    switch (control) {
      case "reg":
        delete userRequestDatas.cp_code;
        break;
      case "issue":
        if (userRequestDatas.dateFormat) {
          userRequestDatas.scheduled_dt = paramSchdeDtFormat(
            userRequestDatas.dateFormat
          );
        }
        break;
    }
    let performMethod = control === "update" ? "PUT" : "POST";
    utilFetch(`coupon/${control}`, performMethod, userRequestDatas);
  };

  // 쿠폰관리 - 쿠폰일시 포맷 세팅
  const paramSchdeDtFormat = (date) => {
    const schdeDateTime = getTransDateTime(
      date.period,
      date.hour,
      date.minute,
      0
    );
    const scheduledDt = `${date.schde_dt} ${schdeDateTime}`;
    return scheduledDt;
  };

  // 50: 예약 control Api
  const controlReservation = () => {
    const { control } = userRestStep;

    // 삭제
    if (control === "delete") {
      deleteFetch(
        `reservation/delete?st_code=${
          setToAdminTransAcc().stCode
        }&reservation_no=${userRequestDatas.reservation_no}`
      );
      return;
    }

    // 등록, 수정
    if (control === "reg") {
      delete userRequestDatas.reservation_no;
    }
    userRequestDatas.reservation_dt = paramResveDtFormat(
      userRequestDatas.dateFormat
    );
    const performMethod = control === "reg" ? "POST" : "PUT";
    utilFetch(`reservation/${control}`, performMethod, userRequestDatas);
  };

  // 예약관리 - 예약일시 포맷 세팅
  const paramResveDtFormat = (date) => {
    const resveDateTime = getTransDateTime(
      date.period,
      date.hour,
      date.minute,
      0
    );
    const reservationDt = `${date.reservation_dt} ${resveDateTime}`;
    return reservationDt;
  };

  // 공용 fetch함수
  const utilFetch = (url, method, param) => {
    fetch(`/api/${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          if (paymentUrlCheck(url)) {
            setNoticeByUrlName(nameByUrl(url));
            sessionStorage.removeItem("paymentType");
            setShowPayLoading(true);
          } else {
            restStepByAlert(userRestStep);
          }
        } else {
          failedAlert(userRestStep, res.message);
        }
        setUserRequestDatas([]);
      })
      .catch((error) => {
        console.error(error);
        if (paymentUrlCheck(url)) {
          setShowPayLoading(true);
        }
      });
  };

  // 공용 삭제 fetch함수
  const deleteFetch = (url) => {
    fetch(`/api/${url}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          restStepByAlert(userRestStep);
          setUserRequestDatas([]);
        } else {
          failedAlert(userRequestDatas.control);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const paymentUrlCheck = (url) => {
    return (
      url.includes("ms/update") ||
      url.includes("ms/join") ||
      url.includes("payment")
    );
  };

  // useState 초기화
  const resetUseState = () => {
    setUserRestStep({ key: 0 });
    setUserRequestDatas([]);
  };

  // url별 성공 메시지 표시
  const nameByUrl = (url) => {
    return url.includes("update")
      ? "카드 번호가 변경되었습니다."
      : "결제가 완료되었습니다.";
  };

  // 결제 후 부모창 새로고침 호출 및 창 닫기기
  useEffect(() => {
    if (showPayLoading) {
      setTimeout(() => {
        window.opener.postMessage({
          request: "showAlert",
          userRestStepKey: userRestStep.key,
        });
        window.close();
      }, 1000);
    }
  }, [showPayLoading]);

  // userRestStep에 따른 완료 알림 띄우기
  const restStepByAlert = (step) => {
    setIsShowUserApproveAlert(true);

    const setAlert = (title, isCancel = false) => {
      isCancel ? setCancelTitle(title) : setNoticeTitle(title);
      isCancel ? setCancelAlert(true) : setNoticeAlert(true);
    };

    const processStep = (message, isCancel = false) => {
      let particle = message === "삭제" ? "가" : "이";
      setAlert(`${message}${particle} 완료되었습니다.`, isCancel);
    };

    switch (step.key) {
      case 13:
        setAlert("서비스가 해지되었습니다.", true);
        break;
      case 20:
        if (step.control === "point/update") {
          processStep("적용");
        } else if (step.control === "delete") {
          processStep("삭제", true);
        } else {
          processStep(step.control === "reg" ? "등록" : "수정");
        }
        break;
      case 21:
        processStep("저장");
        break;
      case 40:
        if (step.control === "issue") {
          processStep("쿠폰 발행");
        } else if (step.control === "issue/cancel") {
          processStep("쿠폰 발송 예약 취소", true);
        } else {
          processStep(step.control === "reg" ? "등록" : "수정");
        }
        break;
      default:
        if (step.control === "delete") {
          processStep("삭제", true);
        } else if (
          !step.control.includes("active") ||
          !step.control.includes("status")
        ) {
          processStep(step.control === "reg" ? "등록" : "수정");
        }
        break;
    }
  };

  // userRestStep.control에 따른 실패 알림
  const failedAlert = (step, failedMsg) => {
    let message = "";
    switch (step.control) {
      case "reg":
      case "update":
        message = "저장";
        break;
      case "delete":
        message = "삭제";
        break;
      case "issue":
        step.callback(failedMsg);
        return;
      default:
        message = "처리";
        break;
    }
    alert(
      `${message} 과정에서 오류가 발생했습니다.\n다시 시도해 주시기 바랍니다.`
    );
    window.location.reload();
  };

  const noticeFunction = (step) => {
    switch (step.key) {
      case 50:
        window.parent.postMessage({
          request: "refetch",
        });
        break;
      default:
        window.location.reload();
        break;
    }
  };

  return (
    <Fragment>
      {showPayLoading && (
        <div
          className="user-auth-wrapper"
          style={{ flexDirection: "column", background: "#fff" }}
        >
          <div className="success-checkmark">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
          <div style={{ marginTop: "-11rem", marginLeft: "0.5rem" }}>
            <h5>{noticeByUrlName}</h5>
          </div>
        </div>
      )}
      {isShowUserApproveAlert &&
        noticeAlert &&
        UserApproveAlert(noticeTitle, "notice", function () {
          noticeFunction(userRestStep);
        })}
      {isShowUserApproveAlert &&
        cancelAlert &&
        UserApproveAlert(cancelTitle, "caution", function () {
          noticeFunction(userRestStep);
        })}
    </Fragment>
  );
};

export default UserRestFunction;
