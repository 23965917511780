import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import {
  commaRemovePrice,
  getFormattedPrice,
  handleKeyPress,
  phoneRemoveHyphen,
  setParamterInteger,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";
import InputTag from "../../../../../common/utils/components/InputTag";
import { UserCallbackAlert } from "../../../utils/alert/UserAlertForm";

const PointManualSetting = (props) => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setUserRestStep,
    setUserRequestDatas,
  } = useComponentsContext();

  const { control, register, watch, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      transaction_type: "EARN",
      point: 0,
    },
  });
  const transactionType = watch("transaction_type") === "EARN" ? true : false;

  const [noticeTitle, setNoticeTitle] = useState("");
  const [pointAlert, setPointAlert] = useState("");

  // 저장 버튼 클릭 시 기존 값보다 작은 지 확인
  const handleModifyInfo = (data) => {
    const getInfo = props.selectedMemberInfos;
    const point = setParamterInteger(data.point);
    if (!transactionType && point > getInfo.remain_point) {
      alert("차감 포인트가 현재 잔여 포인트보다 많을 수 없습니다.");
      return;
    }
    data.point = point;
    data.st_code = setToAdminTransAcc().stCode;
    data.contact = phoneRemoveHyphen(getInfo.contact);
    setUserRequestDatas(data);

    // 띄울 알림 종류 선택
    let type = transactionType ? "적립" : "차감";
    setNoticeTitle(`포인트를 ${type}하시겠습니까?`);
    setIsShowUserCallbackAlert(true);
    setPointAlert(true);
  };

  // Alert 콜백함수 설정
  const noticeFunction = () => {
    setUserRestStep({ key: 20, control: "point/update" });
  };

  useEffect(() => {
    if (!props.isShowPointSetModal) {
      setPointAlert(false);
      reset();
      setTimeout(() => {
        props.setSelectedMemberInfos({});
      }, 300);
    }
  }, [props.isShowPointSetModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowPointSetModal}
        onHide={() => {
          props.setIsShowPointSetModal(false);
        }}
        className="mod-modal"
      >
        <Modal.Header>
          <Modal.Title>포인트 수동 적립 및 차감</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowPointSetModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(handleModifyInfo)}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Modal.Body className="usermng-modal-body">
            <div className="order-table ov-h c-table">
              {Object.keys(props.selectedMemberInfos).length > 0 && (
                <table className="usermng-mod-table">
                  <tbody>
                    <tr>
                      <th style={{ width: "9rem" }}>회원명</th>
                      <td>
                        <input
                          key={props.selectedMemberInfos.name}
                          id="name"
                          type="text"
                          defaultValue={props.selectedMemberInfos.name}
                          className="form-control col-lg-12"
                          placeholder="회원명"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "6rem" }}>현재 잔여포인트</th>
                      <td>
                        <input
                          key={props.selectedMemberInfos.remain_point}
                          type="text"
                          defaultValue={getFormattedPrice(
                            props.selectedMemberInfos.remain_point
                          )}
                          className="form-control col-lg-12 text-right"
                          placeholder="현재 잔여포인트"
                          disabled
                        />
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "6rem" }}>포인트 입력</th>
                      <td>
                        <div className="d-flex justify-content-sm-between">
                          <select
                            className="col-lg-4 form-control"
                            {...register("transaction_type")}
                          >
                            <option value="EARN">적립</option>
                            <option value="USE">차감</option>
                          </select>
                          <Controller
                            name="point"
                            control={control}
                            render={({ field }) => (
                              <InputTag
                                {...field}
                                id="point"
                                className="form-control col-lg-7 text-right"
                                min={0}
                                max={9999999999}
                                reset-value="point"
                                setValue={setValue}
                              />
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary">
              저장
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => props.setIsShowPointSetModal(false)}
            >
              닫기
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isShowUserCallbackAlert &&
        pointAlert &&
        UserCallbackAlert(noticeTitle, [], "확인", true, "notice", function () {
          noticeFunction();
        })}
    </Fragment>
  );
};

export { PointManualSetting };
