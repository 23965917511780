import { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import {
  handleShowCouponQR,
  phoneRegularExpFnc,
  setFormattedDate,
  setToAdminTransAcc,
} from "../../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../../ContextProvider";

const CouponSendInquiry = (props) => {
  const { setLoading } = useComponentsContext();

  const [couponList, setCouponList] = useState([]);
  const [infosByStCode, setInfosByStCode] = useState({});

  const fetchDetailData = () => {
    setLoading(true);
    fetch(
      `/api/coupon/issue/info?st_code=${setToAdminTransAcc().stCode}&cp_code=${
        props.selectedCpCode
      }&cp_issue_no=${props.selectedCpIssueNo}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          const data = res.data;
          let infosByCpCode = {
            cp_name: data.cp_name,
            scheduled_dt: data.scheduled_dt,
            send_cnt: data.send_cnt,
            status: formattedStatus(data.status),
          };
          setInfosByStCode(infosByCpCode);

          res.data.member_list.map((data, i) => (data.id = i + 1));
          setCouponList(res.data.member_list);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const formattedStatus = (status) => {
    let statusMsg = "";
    switch (status) {
      case "1":
        statusMsg = "즉시 발송";
        break;
      case "2":
        statusMsg = "예약 발송";
        break;
      case "3":
        statusMsg = "예약";
        break;
      case "9":
        statusMsg = "취소";
        break;
    }
    return statusMsg;
  };

  useEffect(() => {
    if (props.selectedCpCode && props.selectedCpIssueNo) fetchDetailData();
  }, [props.selectedCpCode, props.selectedCpIssueNo]);

  useEffect(() => {
    if (!props.isShowInquiryModal) {
      props.setSelectedCpCode("");
      props.setSelectedCpIssueNo(0);
    }
  }, [props.isShowInquiryModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowInquiryModal}
        onHide={() => {
          props.setIsShowInquiryModal(false);
        }}
        className="coupon-inquiry-modal resize-mod-modal"
      >
        <Modal.Header>
          <Modal.Title>쿠폰 발행 조회</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowInquiryModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <Modal.Body style={{ width: "40rem" }}>
          <div className="order-table ov-h c-table">
            <table className="usermng-mod-table coupon-send-modal">
              {Object.keys(infosByStCode).length > 0 && (
                <tbody>
                  <tr>
                    <th style={{ width: "10rem" }}>
                      <span className="mr-2">쿠폰명</span>
                      <span className="ml-5">|</span>
                    </th>
                    <td>
                      <span>{infosByStCode.cp_name}</span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "10rem" }}>
                      <span className="mr-3">발송일시</span>
                      <span className="ml-4">|</span>
                    </th>
                    <td>
                      <span>
                        {setFormattedDate(infosByStCode.scheduled_dt)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: "10rem" }}>
                      <span className="mr-3">상태</span>
                      <span className="ml-5" style={{ paddingLeft: "5px" }}>
                        |
                      </span>
                    </th>
                    <td>
                      <span>{infosByStCode.status}</span>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="card mt-4">
            <div className="card-body">
              <span>
                <b>※ 발송 인원 [{couponList.length}명]</b>
              </span>
              <div className="table-resposive">
                <div className="table-stats order-table ov-h c-table">
                  <div className="order-table ov-h c-table coupon-mod-table">
                    <table className={"table text-center fontS add-table "}>
                      <thead>
                        <tr>
                          <th style={{ width: "50px" }} scope="col">
                            No
                          </th>
                          <th style={{ width: "120px" }} scope="col">
                            이름
                          </th>
                          <th style={{ width: "150px" }} scope="col">
                            연락처
                          </th>
                          <th style={{ width: "100px" }} scope="col">
                            등급
                          </th>
                          <th style={{ width: "80px" }} scope="col">
                            쿠폰 보기
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {couponList.length > 0 &&
                          couponList.map((list, i) => {
                            return (
                              <tr key={i}>
                                <td>{list.id}</td>
                                <td>{list.name}</td>
                                <td>{phoneRegularExpFnc(list.contact)}</td>
                                <td>{list.gd_name}</td>
                                <td>
                                  <div className="d-flex justify-content-sm-around align-items-center">
                                    <div
                                      title="쿠폰보기"
                                      style={{ fontSize: "18px" }}
                                      className={
                                        "modify-icon " +
                                        `${
                                          list.cp_type === 0
                                            ? "coupon-disabled"
                                            : ""
                                        }`
                                      }
                                      onClick={() =>
                                        handleShowCouponQR(list.cp_unique_code)
                                      }
                                    >
                                      <FaLink />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => props.setIsShowInquiryModal(false)}
          >
            닫기
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export { CouponSendInquiry };
