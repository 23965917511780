import { Fragment, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BsXLg } from "react-icons/bs";
import PointList from "../../../utils/PointList";

const MemberPointList = (props) => {
  useEffect(() => {
    if (!props.isShowPointModal) {
      props.setSelectedContact("");
    }
  }, [props.isShowPointModal]);

  return (
    <Fragment>
      <Modal
        show={props.isShowPointModal}
        onHide={() => {
          props.setIsShowPointModal(false);
        }}
        className={`member-point-modal resize-mod-modal`}
      >
        <Modal.Header>
          <Modal.Title>포인트 내역</Modal.Title>
          <button
            className="btn"
            style={{ fontSize: "1.3rem", padding: "0.5rem 1rem" }}
            onClick={() => props.setIsShowPointModal(false)}
          >
            <BsXLg />
          </button>
        </Modal.Header>
        <Modal.Body>
          <PointList selectedContact={props.selectedContact} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export { MemberPointList };
