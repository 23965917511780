import { Fragment } from "react";
import { DotLoader } from "react-spinners";
import { useComponentsContext } from "../../components/ContextProvider";

const PageLoading = () => {
  const { loading, setLoading } = useComponentsContext();

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <Fragment>
      {loading && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center loading-div">
          <DotLoader color="#5a9bb5" cssOverride={override} size={60} />
        </div>
      )}
    </Fragment>
  );
};

export default PageLoading;
