import {
  getFormattedDate,
  getRandomId,
} from "../../../../../common/utils/FunctionUtility";
// PG 거래 등록
export function tradesPgReg(type, goodsName, totalAmount) {
  const param = easypayParams(type, goodsName, totalAmount);

  return fetch("https://pgapi.easypay.co.kr/api/trades/webpay", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Charset: "utf-8",
    },
    body: JSON.stringify(param),
  })
    .then((res) => res.json())
    .then((res) => {
      let result = {
        result: true,
        data: "",
      };

      if (res.resCd === "0000" && res.resMsg === "정상") {
        result.data = res.authPageUrl;
      } else {
        console.error("PG 거래 등록 실패");
        result.result = false;
      }
      return result;
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// 거래 승인
export function requestApproval(mappedParams) {
  return fetch("https://pgapi.easypay.co.kr/api/trades/approval", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Charset: "utf-8",
    },
    body: JSON.stringify({
      mallId: mappedParams.paymentType === "pg" ? "05579625" : "05580210",
      shopTransactionId: mappedParams.shopOrderNo,
      authorizationId: mappedParams.authorizationId,
      shopOrderNo: mappedParams.shopOrderNo,
      approvalReqDate: getFormattedDate(),
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      let result = {
        result: true,
        data: "",
      };

      if (res.resCd === "0000") {
        result.paymentType = mappedParams.paymentType;
      } else {
        alert(res.resMsg);
        result.result = false;
      }
      result.data = res;
      return result;
    })
    .catch((error) => {
      console.error(error);
    });
}

// 거래 등록 시 KICC에 보낼 파라미터 세팅
function easypayParams(type, goodsName, totalAmount) {
  const param = {
    mallId: type === "basic" ? "05579625" : "05580210",
    payMethodTypeCode: type === "basic" ? "11" : "81",
    currency: "00",
    amount: totalAmount,
    clientTypeCode: "00",
    returnUrl:
      // type === "basic"
      //   ? "http://192.168.0.29:8080/api/redirect/pg"
      //   : "http://192.168.0.29:8080/api/redirect/bg",
      type === "basic"
        ? location.protocol + "//" + location.host + ":8080/api/redirect/pg"
        : location.protocol + "//" + location.host + ":8080/api/redirect/bg",
    shopOrderNo: getRandomId(),
    deviceTypeCode: "pc",
    orderInfo: {
      goodsName: goodsName,
    },
    payMethodInfo: {
      billKeyMethodInfo: {
        certType: "1",
      },
    },
  };

  if (type === "basic") {
    delete param.payMethodInfo;
  }
  return param;
}
