import { Fragment, useEffect, useState } from "react";
import { FaCreditCard, FaSearchDollar, FaTimes } from "react-icons/fa";
import {
  getFormattedDate,
  getFormattedPrice,
  setUseStatus,
  getUnscribedReason,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

import UserAuthModal from "../../utils/UserAuthModal";
import {
  UserCallbackAlert,
  UserApproveAlert,
} from "../../utils/alert/UserAlertForm";

const SubscriptionInfo = () => {
  const {
    isShowUserCallbackAlert,
    setIsShowUserCallbackAlert,
    setIsShowUserApproveAlert,
    setUserRestStep,
    setPgControlStep,
    userInfo,
    setTotalAmount,
    setGoodsName,
    regularInfo,
    subsState,
  } = useComponentsContext();

  const alertByBtn = {
    register: {
      title: "정기결제 등록 확인",
      content: [
        "정기결제를 위해 결제하신 정보가 PG사에 안전하게 보관되며,",
        "향후 결제가 필요한 시점에 결제정보로 사용됩니다.",
      ],
      buttonTxt: "등록하기",
      cancelBtnMark: false,
      alertType: "notice",
      callback: function () {
        setUserRestStep({ key: 1, control: "regular" });
      },
    },
    select: {
      title: "등록된 카드 조회",
      content: [
        "결제 업체 조회 결과 고객님께서 등록한 자동연장 서비스용 카드는",
        "다음 번호로 시작되는 신용(체크)카드로 등록되어 있습니다.",
      ],
      buttonTxt: "확인",
      cancelBtnMark: false,
      alertType: "notice",
    },
    cancel: {
      title: "서비스를 정말 해지하시겠습니까?",
      content: [
        "정기결제를 해지해도 잔여 금액은 남은 서비스 기간동안 사용하실 수 있습니다.",
      ],
      buttonTxt: "해지",
      cancelBtnMark: true,
      alertType: "caution",
      callback: function () {
        setUserRestStep({ key: 13 });
      },
    },
  };
  const [alertSettings, setAlertSettings] = useState("");
  const [isUserAuthModal, setIsUserAuthModal] = useState(false); // 사용자 인증 모달 useState

  const [noticeAlert, setNoticeAlert] = useState(false);
  const [noticeTitle, setNoticeTitle] = useState("");

  // 정기결제 버튼 클릭 이벤트
  const handleButtonClick = (e) => {
    e.preventDefault();
    let buttonTxt = e.currentTarget.innerText.replace("카드", "");
    if (buttonTxt === "등록" || buttonTxt === "변경") {
      setTotalAmount(regularInfo[0].price);
      setGoodsName(regularInfo[0].ms_pd_name);
      sessionStorage.setItem("paymentType", buttonTxt);
    }
    contentsSetting(buttonTxt);
  };

  const contentsSetting = (buttonTxt) => {
    switch (buttonTxt) {
      case "등록":
      case "변경":
        setAlertSettings(alertByBtn.register);
        break;
      case "조회":
        setAlertSettings(alertByBtn.select);
        break;
      case "해지":
        setAlertSettings(alertByBtn.cancel);
        break;
    }
    setIsUserAuthModal(true);
  };

  useEffect(() => {
    // 자식창에서 메시지 호출 받은 경우
    window.addEventListener("message", function (e) {
      if (e.data.request === "showAlert") {
        setPgControlStep(e.data.userRestStepKey);
        restStepByAlert(e.data.userRestStepKey);
      }
    });
  }, []);

  // userRestStep에 따른 완료 알림 띄우기
  const restStepByAlert = (step) => {
    setIsShowUserApproveAlert(true);
    switch (step) {
      case 11:
        setNoticeTitle("카드 등록이 완료되었습니다.");
        setNoticeAlert(true);
        break;
      case 12:
        setNoticeTitle("카드 변경이 완료되었습니다.");
        setNoticeAlert(true);
        break;
    }
  };

  const getUsagePeriodAndState = () => {
    return subsState ? (
      <span
        id="state-info"
        className={userInfo.use_status === 0 ? "red-color" : ""}
      >
        [{userInfo.start_dt} ~ {userInfo.end_dt}] &nbsp;&nbsp;
        {subsState ? "사용중" : "해지 완료"}
        &nbsp;
        <span id="state-msg" className="red-color">
          {getUnscribedReason(subsState, userInfo.use_status)}
        </span>
        {subsState && userInfo.use_status !== 5 && (
          <button
            className="btn btn-danger"
            style={{ marginLeft: "8px" }}
            onClick={handleButtonClick}
          >
            <FaTimes />
            해지
          </button>
        )}
      </span>
    ) : (
      "미가입"
    );
  };

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <div className="title">
                  <h5>정기결제 안내</h5>
                </div>
                <ul className="notice-ul">
                  <li>
                    본 서비스는 키오스크와 테이블오더의 주문알림 기능 등에
                    이용되는 카카오톡/문자 메시지 서비스입니다.
                  </li>
                  {Object.keys(regularInfo).length > 0 && (
                    <>
                      <li>
                        서비스{" "}
                        <b>
                          기본 금액은 월{" "}
                          {getFormattedPrice(regularInfo[0].price)}
                          원(VAT 포함)
                        </b>
                        이며, <b>정기결제</b>로 관리됩니다.
                      </li>
                    </>
                  )}
                  <li>
                    해지 시 사용기간이 끝난 시점으로부터{" "}
                    <b>1년 후에는 미가입 처리</b>됩니다.
                  </li>
                  <li>카카오톡 미설치 고객님께는 문자메시지로 전송됩니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <h5 className="m-0 font-weight-bold">
              정기결제 등록 {subsState && "정보"}
            </h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  border={"1"}
                  className="info-table resize-table"
                  style={{ width: "60%", minWidth: "770px" }}
                >
                  <tbody>
                    <tr className="payment-tr">
                      <th>정기결제 관리</th>
                      <td>
                        {subsState &&
                        userInfo.use_status > 0 &&
                        userInfo.use_status !== 5 ? (
                          <div className="group-btn">
                            <button
                              className="btn btn-primary"
                              onClick={handleButtonClick}
                              style={{ marginRight: "1rem" }}
                            >
                              <FaSearchDollar />
                              카드조회
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={handleButtonClick}
                            >
                              <FaCreditCard />
                              카드변경
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={handleButtonClick}
                          >
                            <FaCreditCard />
                            카드등록
                          </button>
                        )}
                      </td>
                    </tr>
                    <tr className="state-tr">
                      <th>사용기간</th>
                      <td>{getUsagePeriodAndState()}</td>
                    </tr>
                    {subsState && (
                      <tr className="state-tr">
                        <th>정기결제일</th>
                        <td>
                          <span>
                            <b>
                              {userInfo.use_status > 0
                                ? userInfo.next_pay_dt
                                : "-"}
                            </b>
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <UserAuthModal
        isUserAuthModal={isUserAuthModal}
        setIsUserAuthModal={setIsUserAuthModal}
        callback={() => {
          setIsUserAuthModal(false);
          setIsShowUserCallbackAlert(true);
        }}
      />
      {isShowUserCallbackAlert &&
        UserCallbackAlert(
          alertSettings.title,
          alertSettings.content,
          alertSettings.buttonTxt,
          alertSettings.cancelBtnMark,
          alertSettings.alertType,
          alertSettings.callback
        )}
      {noticeAlert &&
        UserApproveAlert(noticeTitle, "notice", function () {
          window.location.reload();
        })}
    </Fragment>
  );
};

export default SubscriptionInfo;
