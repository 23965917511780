import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./assets/css/layout.css";
import "./assets/css/service-menu.scss";
import "./assets/css/member-menu.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
