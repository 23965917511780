import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import DatePeriodTag from "../../../../common/utils/components/DatePeriodTag";
import {
  formattedPeriod,
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  setToAdminTransAcc,
} from "../../../../common/utils/FunctionUtility";
import { useComponentsContext } from "../../../ContextProvider";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const PointStatistics = () => {
  const {
    setLoading,
    periodUpdate,
    setPeriodUpdate,
    searchPeriod,
    setSearchPeriod,
  } = useComponentsContext();

  const [startDt, setStartDt] = useState(getFormattedFirstDay());
  const [endDt, setEndDt] = useState(getFormattedLastDay());

  const [totalTables, setTotalTables] = useState({
    title: "전체",
    title2: "적립",
    title3: "사용",
    datas: ["-", "-", "-"],
  });

  const [staticTables, setStaticTables] = useState([]);

  const datas = [
    {
      id: "grades",
      label: "회원등급별",
      backgroundColor: "rgb(96 125 139 / 50%)",
    },
    {
      id: "days",
      label: "일별",
      backgroundColor: "rgb(90 155 181 / 50%)",
    },
    {
      id: "weeks",
      label: "요일별",
      backgroundColor: "rgb(67 102 169 / 50%)",
    },
    {
      id: "hours",
      label: "시간대별",
      backgroundColor: "rgba(66 66 67 / 50%)",
    },
  ];

  // 그래프 옵션 설정
  const options = {
    responsive: true,
    scales: {
      x: {
        offset: true,
      },
      y: {
        beginAtZero: true,
        suggestedMax: 5000,
      },
    },
  };
  // datalabels 옵션 세팅
  const datalabels = () => {
    let options = {
      labels: {
        value: {
          anchor: "end",
          borderWidth: 2,
          borderRadius: 4,
          font: { size: 13 },
          formatter: function (value, ctx) {
            return value > 0 ? `${getFormattedPrice(value)} P` : null;
          },
          color: "black",
          backgroundColor: "#8f8f8f33",
        },
      },
    };

    return options;
  };

  // 막대 그래프 너비 설정
  const percentageSetting = (data) => {
    if (data.length > 0) {
      const length = data[0].label.length;
      return length === 1 ? 0.4 : length === 2 ? 0.5 : 0.6;
    }
  };

  // 포인트 그래프
  const cntGraphByPoint = (graphDatas) => {
    let pointCntGraph = {};

    let formattedGraphByType = graphDataSetting(graphDatas);

    pointCntGraph = {
      labels: formattedGraphByType[0].label,
      datasets: [
        {
          label: "적립",
          data: formattedGraphByType[0].earnDatas,
          backgroundColor: "rgb(75 192 192 / 50%)",
          datalabels: datalabels(),
          barPercentage: percentageSetting(formattedGraphByType),
          categoryPercentage: percentageSetting(formattedGraphByType),
        },
        {
          label: "사용",
          data: formattedGraphByType[0].useDatas,
          backgroundColor: "rgb(255 159 64 / 50%)",
          datalabels: datalabels(),
          barPercentage: percentageSetting(formattedGraphByType),
          categoryPercentage: percentageSetting(formattedGraphByType),
        },
        {
          label: "취소",
          data: formattedGraphByType[0].cancelDatas,
          backgroundColor: "rgb(255 99 132 / 30%)",
          datalabels: datalabels(),
          barPercentage: percentageSetting(formattedGraphByType),
          categoryPercentage: percentageSetting(formattedGraphByType),
        },
      ],
    };
    return pointCntGraph;
  };

  const graphDataSetting = (graphDatas) => {
    const dataExist = graphDatas.data && graphDatas.data.length > 0;
    let labelArrays = [];
    let earnPointArrays = [];
    let usePointArrays = [];
    let cancelPointArrays = [];
    let settingDatas = [];

    if (dataExist) {
      let labelByType = [];
      graphDatas.data.map((list) => {
        switch (graphDatas.id) {
          case "grades":
            labelByType.push(list.gd_name);
            break;
          case "days":
            labelByType.push(list.time_span);
            break;
          case "weeks":
            labelByType.push(list.week_day);
            break;
          case "hours":
            labelByType.push(`${list.hour}시`);
            break;
        }

        earnPointArrays.push(list.earn_point);
        usePointArrays.push(list.use_point);
        cancelPointArrays.push(list.cancel_point);
      });

      labelArrays = [...new Set(labelByType)];
    }

    settingDatas.push({
      label: labelArrays,
      earnDatas: earnPointArrays,
      useDatas: usePointArrays,
      cancelDatas: cancelPointArrays,
    });
    setLoading(false);

    return settingDatas;
  };

  const fetchDataState = () => {
    fetchAndSetData("state", setTotalTables);
  };

  const fetchGraphData = () => {
    fetchAndSetData("statistics", setStaticTables);
  };

  // 공통 기간검색 fetch
  const fetchAndSetData = (url, callback) => {
    setLoading(true);
    fetch(
      `/api/point/${url}?st_code=${
        setToAdminTransAcc().stCode
      }${formattedPeriod(searchPeriod, startDt, endDt)}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          const resultData = dataSettings(url, res.data);
          callback(resultData);
        } else {
          totalTables.datas = ["-", "-", "-"];
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const dataSettings = (url, data) => {
    if (url === "state") {
      totalTables.datas = [
        getFormattedPrice(data.reamin_amt),
        getFormattedPrice(data.earn_total),
        getFormattedPrice(data.use_total),
      ];
      return totalTables;
    } else {
      datas.map((type) => {
        if (data[type.id].length > 0) {
          type.data = data[type.id];
        }
      });
      return datas;
    }
  };

  useEffect(() => {
    fetchGraphData();

    return () => {
      setSearchPeriod("daliy");
    };
  }, []);

  useEffect(() => {
    fetchDataState();
    if (periodUpdate) {
      fetchGraphData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  return (
    <Fragment>
      <div className="title search-div d-flex">
        <DatePeriodTag
          currentMenu={"statistics"}
          startDt={startDt}
          setStartDt={setStartDt}
          endDt={endDt}
          setEndDt={setEndDt}
        />
      </div>
      <div className="table-responsive mb-4">
        <div className="order-table ov-h c-table">
          <div className="d-flex justify-content-sm-between">
            <table className="table text-center fontS add-table userinfo-table">
              <thead className="thead-dark">
                <tr>
                  <th colSpan={3}>누적포인트</th>
                </tr>
                <tr>
                  <th>{totalTables.title}</th>
                  <th>{totalTables.title2}</th>
                  <th>{totalTables.title3}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {totalTables.datas.map((state, i) => {
                    return <td key={i}>{state}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        {datas.length > 0 &&
          staticTables.map((pointGraph) => {
            return (
              <div className="col-xl-6 col-lg-12" key={pointGraph.id}>
                <div className="card shadow mb-4" id={`${pointGraph.id}Graph`}>
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between h-71">
                    <h6 className="m-0 font-weight-bold text-primary p-0">
                      {pointGraph.label}
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="chart-area">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <Bar
                            options={options}
                            data={cntGraphByPoint(pointGraph)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
};

export default PointStatistics;
