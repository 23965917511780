import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { BsSearch } from "react-icons/bs";
import { FaPen, FaUserCog } from "react-icons/fa";
import { useComponentsContext } from "../../../ContextProvider";
import {
  emptyToHyphen,
  getFormattedDate,
  getFormattedPrice,
  setUseStatus,
} from "../../../../common/utils/FunctionUtility";
import { UserInfoModifyModal } from "./modal";
import { MngCallbackAlert } from "../../utils/alert/MngAlertForm";
import { useForm } from "react-hook-form";

const UserManagement = () => {
  const { setLoading, isShowMngCallbackAlert, setMngRestStep } =
    useComponentsContext();
  const adminToCustAcc = localStorage.getItem("adminToCustAcc");

  const { register, watch, reset } = useForm({
    defaultValues: {
      st_code: "",
      cust_name: "",
      use_status: 99,
    },
  });
  const inputStCode = watch("st_code");
  const inputCustName = watch("cust_name");
  const selectedStatus = watch("use_status");

  const navigator = useNavigate();

  const [selectTab, setSelectTab] = useState(0); // 선택된 탭 메뉴
  const listPerPage = 10; // 페이지 당 항목 표시 개수

  const [subscribList, setSubscribList] = useState([]); // 가입 고객 리스트
  const [subPageKey, setSubPageKey] = useState(0); // 가입 고객 현재 페이지

  const [unsubscribList, setUnsubscribList] = useState([]); // 미가입 고객 리스트
  const [unsubPageKey, setUnsubPageKey] = useState(0); // 미가입 고객 현재 페이지

  const [selectedStCode, setSelectedStCode] = useState("");
  const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);

  // 탭 별 테이블 세팅
  const tabContent = [
    {
      menuTab: 1,
      tabTitle: "가입 고객",
      header: [
        "서비스 이용 기간",
        "상태",
        "결제예정일",
        "발송건수",
        "잔여건수",
        "잔여금액",
        "관리",
      ],
    },
    {
      menuTab: 2,
      tabTitle: "미가입 고객",
      header: ["사업자등록번호", "대표자명", "전화번호", "주소"],
    },
  ];

  // 공통 fetch
  const fetchAndSetData = (url, callback) => {
    // 가입 고객인 경우 use_status도 포함
    setLoading(true);
    fetch(
      `/api/manager/member/${url}?st_code=${inputStCode}&cust_name=${inputCustName}${
        url === "reg" && Number(selectedStatus) !== 99
          ? `&use_status=${selectedStatus}`
          : ""
      }`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        if (res.result) {
          callback(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };

  const subCurrentTab = getCurrentList(subscribList, subPageKey);
  const unsubCurrentTab = getCurrentList(unsubscribList, unsubPageKey);
  const currentPage = selectTab === 0 ? subPageKey : unsubPageKey;

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    selectTab === 0 ? setSubPageKey(selected) : setUnsubPageKey(selected);
  };

  // 검색 버튼 클릭
  const handleSearchBtnClick = () => {
    if (selectTab === 0) {
      fetchAndSetData("reg", setSubscribList);
      setSubPageKey(0);
    } else {
      fetchAndSetData("unreg", setUnsubscribList);
      setUnsubPageKey(0);
    }
  };
  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchBtnClick();
    }
  };

  // 관리 (연필 아이콘) 클릭 시 수정 모달 표시
  const handleMngClick = (getStCode) => {
    setSelectedStCode(getStCode);
    setIsShowUserInfoModal(true);
  };

  // 관리 (사용자 전환) 클릭 시 가맹점 전환
  const handleTransActClick = (getStCode, getCustName) => {
    const transAccount = {
      stCode: getStCode,
      custName: getCustName,
    };
    localStorage.setItem("transAccountInfo", JSON.stringify(transAccount));
    localStorage.setItem("adminToCustAcc", "ACCESS");
    setTimeout(() => {
      location.href = "/user/services";
    }, 500);
  };

  // 건수 또는 금액 매핑
  const mappedData = (useStatus, data) => {
    return Number(useStatus) === 0
      ? "-"
      : emptyToHyphen(getFormattedPrice(data));
  };

  // input 값 초기화
  const resetValue = () => {
    reset();
    setSubPageKey(0);
    setUnsubPageKey(0);
  };

  // selectTab에 따른 조회
  useEffect(() => {
    if (selectTab === 0) {
      fetchAndSetData("reg", setSubscribList);
      setUnsubscribList([]);
    } else {
      fetchAndSetData("unreg", setUnsubscribList);
      setSubscribList([]);
    }
  }, [selectTab]);

  return (
    <Fragment>
      <div className="col-lg-12 pl-0 pr-0 store">
        <ul
          className="nav nav-tabs align-items-center price-ul"
          id="menuTab"
          role="tablist"
        >
          {tabContent.map((tab, i) => {
            return (
              <li key={i} className="nav-item" role="presentation">
                <Link
                  className={
                    "nav-link " +
                    `${tab.menuTab === selectTab + 1 ? "active" : ""}`
                  }
                  id="store-tab"
                  onClick={() => {
                    setSelectTab(i);
                    resetValue();
                  }}
                >
                  {tab.tabTitle}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="store"
            role="tabpanel"
            aria-labelledby="store-tab"
          >
            <div className="col-lg-12 pl-0 pr-0">
              <div className="card">
                <div className="card-body">
                  <div className="mt-4 mb-4 sch_box user-sch-box">
                    <div className="row form-group col-lg-9 ">
                      <div className="col-md-3-1">
                        <input
                          type="text"
                          id="search_name"
                          name="search_name"
                          placeholder="매장코드"
                          className="form-control"
                          onKeyDown={keyDownFnc}
                          {...register("st_code")}
                        />
                      </div>
                      <div className="col-md-3-1">
                        <input
                          type="text"
                          id="search_biz_no"
                          name="search_biz_no"
                          placeholder="매장명"
                          className="form-control"
                          onKeyDown={keyDownFnc}
                          {...register("cust_name")}
                        />
                      </div>
                      {selectTab === 0 && (
                        <div className="col-md-3-1">
                          <select
                            className="form-control"
                            {...register("use_status")}
                          >
                            <option value={99}>상태</option>
                            <option value={1}>사용중</option>
                            <option value={9}>사용중 (정기결제 실패)</option>
                            <option value={5}>사용중 (정기결제 중단)</option>
                            <option value={0}>미사용</option>
                          </select>
                        </div>
                      )}
                      <div className="row col-md-3-1 ml-1">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={handleSearchBtnClick}
                        >
                          <BsSearch style={{ fontWeight: "bold" }} /> &nbsp;
                          검색
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <div className="table-stats order-table ov-h c-table">
                      <table
                        className={
                          "table text-center fontS add-table " +
                          `${
                            (
                              selectTab === 0
                                ? subCurrentTab.length === 0
                                : unsubCurrentTab.length === 0
                            )
                              ? "user-table"
                              : ""
                          }`
                        }
                      >
                        <thead>
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">매장코드</th>
                            <th scope="col">매장명</th>
                            {tabContent[selectTab].header.map((head, i) => {
                              return (
                                <th key={i} scope="col">
                                  {head}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {selectTab === 0 &&
                            subCurrentTab.length > 0 &&
                            subCurrentTab.map((list, i) => {
                              return (
                                <tr key={i}>
                                  <td style={{ width: "60px" }}>{i + 1}</td>
                                  <td style={{ width: "100px" }}>
                                    {list.st_code}
                                  </td>
                                  <td
                                    style={{
                                      width: "250px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {list.cust_name}
                                  </td>
                                  <td style={{ width: "230px" }}>
                                    {list.start_dt} ~ {list.end_dt}
                                  </td>
                                  <td style={{ width: "250px" }}>
                                    {Number(list.use_status) === 0 ? (
                                      <span className="red-color">미사용</span>
                                    ) : (
                                      <>
                                        <span id="state-info">
                                          {Number(list.use_status) === 0 ||
                                          (list.end_dt < getFormattedDate() &&
                                            Number(list.use_status) === 9)
                                            ? "해지 완료"
                                            : "사용중"}
                                        </span>
                                        <span
                                          id="state-msg"
                                          className="red-color"
                                        >
                                          {setUseStatus(
                                            Number(list.use_status)
                                          )}
                                        </span>
                                      </>
                                    )}
                                  </td>
                                  <td style={{ width: "120px" }}>
                                    {emptyToHyphen(list.next_pay_dt)}
                                  </td>
                                  <td>
                                    {mappedData(
                                      list.use_status,
                                      list.atalk_count
                                    )}
                                  </td>
                                  <td>
                                    {mappedData(
                                      list.use_status,
                                      list.remain_atalk
                                    )}
                                  </td>
                                  <td>
                                    {mappedData(
                                      list.use_status,
                                      list.remain_amt
                                    )}
                                  </td>
                                  <td className="modify-td">
                                    <div className="d-flex justify-content-sm-around align-items-center">
                                      {(Number(list.use_status) === 1 ||
                                        Number(list.use_status) === 9) && (
                                        <div
                                          title="정보수정"
                                          className="modify-icon"
                                          onClick={() =>
                                            handleMngClick(list.st_code)
                                          }
                                        >
                                          <FaPen />
                                        </div>
                                      )}
                                      <div
                                        title="계정전환"
                                        style={{ fontSize: "18px" }}
                                        className="modify-icon"
                                        onClick={() =>
                                          handleTransActClick(
                                            list.st_code,
                                            list.cust_name
                                          )
                                        }
                                      >
                                        <FaUserCog />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          {selectTab === 1 &&
                            unsubCurrentTab.length > 0 &&
                            unsubCurrentTab.map((list) => {
                              return (
                                <tr key={list.row_num}>
                                  <td style={{ width: "60px" }}>
                                    {list.row_num}
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {list.st_code}
                                  </td>
                                  <td
                                    style={{
                                      width: "300px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {list.cust_name}
                                  </td>
                                  <td style={{ width: "150px" }}>
                                    {emptyToHyphen(list.biz_no)}
                                  </td>
                                  <td style={{ width: "150px" }}>
                                    {emptyToHyphen(list.biz_boss_name)}
                                  </td>
                                  <td style={{ width: "150px" }}>
                                    {emptyToHyphen(list.phone_no)}
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {emptyToHyphen(list.addr)}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="c-pagination prevent-drag">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      pageCount={Math.ceil(
                        (selectTab === 0
                          ? subscribList.length
                          : unsubscribList.length) / listPerPage
                      )}
                      breakLabel={false}
                      marginPagesDisplayed={false}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      forcePage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserInfoModifyModal
        selectedStCode={selectedStCode}
        setSelectedStCode={setSelectedStCode}
        isShowUserInfoModal={isShowUserInfoModal}
        setIsShowUserInfoModal={setIsShowUserInfoModal}
      />
      {isShowMngCallbackAlert &&
        MngCallbackAlert(
          "사용기간 또는 잔여 금액을 수정하시겠습니까?",
          "해당 내용은 신중한 검토 후에 수정하는 것을 권장합니다.",
          "수정",
          "notice",
          function () {
            setMngRestStep(11);
          }
        )}
    </Fragment>
  );
};

export default UserManagement;
