import { Fragment, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useComponentsContext } from "../../../ContextProvider";

const UserMngPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/user/usermng") {
      navigate("userInfo");
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default UserMngPage;
