import { Fragment, useEffect, useState } from "react";
import { useComponentsContext } from "../../../ContextProvider";
import {
  commaRemovePrice,
  getFormattedPrice,
} from "../../../../common/utils/FunctionUtility";
import AddChargeFunction, {
  mappedAddChargeParams,
} from "../../utils/AddChargeFunction";
import { MngCallbackAlert } from "../../utils/alert/MngAlertForm";

const SettingPrice = () => {
  const {
    isShowMngCallbackAlert,
    setIsShowMngCallbackAlert,
    setMngRestStep,
    mngRequestDatas,
    setMngRequestDatas,
    setModifyTabName,
  } = useComponentsContext();
  const [saveFncExcute, setSaveFncExcute] = useState(false);

  const price_data = ["atalk_price", "lms_price", "mms_price", "sms_price"];
  const [unitPriceList, setUnitPriceList] = useState({}); // 건 당 단가

  const [regularPayList, setRegularPayList] = useState({}); // 정기 결제

  const [addChargeList, setAddChargeList] = useState([]); // 추가 충전
  const [existAddList, setExistAddList] = useState([]); // 존재하는 리스트
  const [deleteList, setDeleteList] = useState([]); // 삭제 리스트

  const fetchData = () => {
    // 건당 단가 조회
    fetchAndSetData("/api/manager/price/unit", setUnitPriceList);
    // 정기 결제 조회
    fetchAndSetData("/api/manager/prod?type=0", setRegularPayList);
    // 추가 충전 조회
    fetchAndSetData("/api/manager/prod?type=1", (data) => {
      setAddChargeList(data);
      let existData = data.map((item) => item.ms_pd_code);
      setExistAddList(existData);
    });
  };

  // 공통 fetch
  const fetchAndSetData = (url, callback) => {
    fetch(url)
      .then((res) => res.json())
      .then((res) => {
        if (res.result) {
          callback(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 건당 단가, 정기결제 저장 데이터 가공 함수
  const handleSave = (tableId) => {
    let prices = document.querySelectorAll(`${tableId} td input`);
    let obj = {};
    prices.forEach((price) => {
      obj[price.id] = commaRemovePrice(price.value);
      price.value = getFormattedPrice(price.value);
    });
    setMngRequestDatas(obj);
  };

  // 저장
  const handleSaveClick = (type) => {
    switch (type) {
      case "perCase":
        handleSave("#per-case-table");
        setModifyTabName("단가");
        break;
      case "regularPayment":
        handleSave("#regular-pay-table");
        setModifyTabName("정기");
        break;
      case "addCharge":
        const resultData = mappedAddChargeParams(
          "#add-charge-table",
          deleteList
        );
        setMngRequestDatas(resultData);
        setModifyTabName("추가");
        break;
      default:
        break;
    }
    setSaveFncExcute(true);
  };

  useEffect(() => {
    if (saveFncExcute) {
      // {건당 단가}, {정기 결제} || [추가 충전]
      if (
        mngRequestDatas &&
        (Object.keys(mngRequestDatas).length > 0 || mngRequestDatas.length > 0)
      ) {
        setIsShowMngCallbackAlert(true);
      } else {
        alert("저장할 데이터가 없습니다.");
      }
      setSaveFncExcute(false);
    }
  }, [saveFncExcute]);

  // 자식창에서 메시지 호출 받은 경우 재fetch
  const handleMessage = (e) => {
    if (e.data.request === "refetch") {
      let url;
      let callback;

      switch (e.data.type) {
        case "단가":
          url = "/api/manager/price/unit";
          callback = setUnitPriceList;
          break;
        case "정기":
          url = "/api/manager/prod?type=0";
          callback = setRegularPayList;
          break;
        case "추가":
          url = "/api/manager/prod?type=1";
          callback = (data) => {
            setAddChargeList(data);
            let existData = data.map((item) => item.ms_pd_code);
            setExistAddList(existData);
            setDeleteList([]);
          };
          break;
        default:
          return;
      }

      fetchAndSetData(url, callback);
      setMngRestStep(99);
      setMngRequestDatas({});
    }
  };

  useEffect(() => {
    fetchData();

    const messageHandler = (e) => handleMessage(e);
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return (
    <Fragment>
      <div className="price-component">
        <div className="col-lg-12 pl-0 pr-0 store mb-3">
          <ul
            className="nav nav-tabs align-items-center justify-content-sm-between price-ul"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <span className="nav-link active" id="store-tab">
                건당 단가
              </span>
            </li>
            <div className="btn_box top">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSaveClick("perCase")}
              >
                저장
              </button>
            </div>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="store"
              role="tabpanel"
              aria-labelledby="store-tab"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table-stats order-table ov-h c-table inputFull">
                      <table
                        id="per-case-table"
                        className="table text-center fontS add-table"
                      >
                        <thead>
                          <tr>
                            <th>카카오톡</th>
                            <th>단문</th>
                            <th>장문</th>
                            <th>이미지</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.keys(unitPriceList).length > 0 &&
                              price_data.map((price, i) => {
                                return (
                                  <td key={i} className="price-table-span">
                                    <span className="price-table-span">
                                      <input
                                        id={price}
                                        type="text"
                                        className="form-control"
                                        defaultValue={unitPriceList[price]}
                                        onFocus={(e) => e.target.select()}
                                      />{" "}
                                      원
                                    </span>
                                  </td>
                                );
                              })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="col-lg-12 pl-0 pr-0 store mb-3">
          <ul
            className="nav nav-tabs align-items-center justify-content-sm-between price-ul"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <span className="nav-link active" id="store-tab">
                정기 결제
              </span>
            </li>
            <div className="btn_box top">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSaveClick("regularPayment")}
              >
                저장
              </button>
            </div>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="store"
              role="tabpanel"
              aria-labelledby="store-tab"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table-stats order-table ov-h c-table inputFull">
                      <table
                        id="regular-pay-table"
                        className="table text-center fontS add-table"
                      >
                        <thead>
                          <tr>
                            <th>결제 금액</th>
                            <th>충전 금액</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(regularPayList).length > 0 && (
                            <tr>
                              <td>
                                <span className="price-table-span">
                                  <input
                                    id="price"
                                    type="text"
                                    className="form-control"
                                    defaultValue={getFormattedPrice(
                                      regularPayList[0].price
                                    )}
                                    onFocus={(e) => e.target.select()}
                                  />{" "}
                                  원
                                </span>
                              </td>
                              <td className="price-table-span">
                                <span className="price-table-span">
                                  <input
                                    id="ms_amt"
                                    type="text"
                                    className="form-control"
                                    defaultValue={getFormattedPrice(
                                      regularPayList[0].ms_amt
                                    )}
                                    onFocus={(e) => e.target.select()}
                                  />{" "}
                                  원
                                </span>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="col-lg-12 pl-0 pr-0 store">
          <ul
            className="nav nav-tabs align-items-center justify-content-sm-between price-ul"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <span className="nav-link active" id="store-tab">
                추가 충전
              </span>
            </li>
            <div className="btn_box top">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSaveClick("addCharge")}
              >
                저장
              </button>
            </div>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="store"
              role="tabpanel"
              aria-labelledby="store-tab"
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <div className="table-stats order-table ov-h c-table inputFull">
                      <AddChargeFunction
                        addChargeList={addChargeList}
                        setAddChargeList={setAddChargeList}
                        existAddList={existAddList}
                        setExistAddList={setExistAddList}
                        deleteList={deleteList}
                        setDeleteList={setDeleteList}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowMngCallbackAlert &&
        MngCallbackAlert(
          "설정값을 수정하시겠습니까?",
          "해당 내용은 신중한 검토 후에 수정하는 것을 권장합니다.",
          "수정",
          "notice",
          function () {
            setMngRestStep(12);
          }
        )}
    </Fragment>
  );
};

export default SettingPrice;
