import { Fragment, useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { useComponentsContext } from "../../../ContextProvider";
import {
  emptyToHyphen,
  getFormattedFirstDay,
  getFormattedLastDay,
  getFormattedPrice,
  getGradeList,
  phoneRegularExpFnc,
  settingSelectedPeriod,
  setToAdminTransAcc,
  transTypeKor,
  useTypeKor,
} from "../../../../common/utils/FunctionUtility";
import { useForm } from "react-hook-form";
import ExcelDownload from "../../../../common/utils/excel/ExcelDownload";

const PointHistory = () => {
  const { setLoading } = useComponentsContext();

  const { register, watch, setValue } = useForm({
    defaultValues: {
      name: "",
      gd_level: 0,
      transaction_type: "ALL",
      start_dt: getFormattedFirstDay(),
      end_dt: getFormattedLastDay(),
    },
  });
  const name = watch("name");
  const gdLevel = watch("gd_level");
  const transactionType = watch("transaction_type");
  const startDt = watch("start_dt");
  const endDt = watch("end_dt");

  const [periodUpdate, setPeriodUpdate] = useState(false); // true인 경우 결제 내역 리스트 조회
  const [gradeList, setGradeList] = useState([]);
  const [historyList, setHistoryList] = useState([]); // 포인트 내역 리스트

  const listPerPage = 10;
  const [historyKey, setHistoryKey] = useState(0);

  // 등급 리스트 조회
  const getGradeNameList = async () => {
    const gradeList = await getGradeList(setToAdminTransAcc().stCode);
    setGradeList(gradeList);
  };

  // 포인트 내역 리스트 조회
  const fetchData = () => {
    setLoading(true);
    fetch(
      `/api/point/history?st_code=${
        setToAdminTransAcc().stCode
      }&name=${name}&start_dt=${startDt}&end_dt=${endDt}${setParameter()}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.result) {
          res.data.map((data, index) => (data.id = index + 1));
          setHistoryList(res.data);
        } else {
          setHistoryList([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const setParameter = () => {
    return `${gdLevel === 0 ? "" : `&gd_level=${gdLevel}`}${
      transactionType === "ALL" ? "" : `&transaction_type=${transactionType}`
    }`;
  };

  useEffect(() => {
    getGradeNameList();
    fetchData();
  }, []);

  // 기간 검색 포맷
  const handlePeriodInquiry = (e) => {
    let btnName = e.currentTarget.innerText;
    const settingDate = settingSelectedPeriod(btnName);
    setValue("start_dt", settingDate.startDt);
    setValue("end_dt", settingDate.endDt);
    setPeriodUpdate(true);
  };

  // 조회 돋보기 검색
  const handleSearchClick = () => {
    if (startDt > endDt) {
      alert("시작일자는 종료일자보다 이전으로 설정해주세요.");
      return;
    }
    setPeriodUpdate(true);
  };

  // 엔터로 검색
  const keyDownFnc = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchClick();
    }
  };

  useEffect(() => {
    if (periodUpdate) {
      fetchData();
      setPeriodUpdate(false);
    }
  }, [periodUpdate]);

  // 페이지 번호를 계산하여 설정
  const getCurrentList = (list, pageNumber) => {
    const start = pageNumber * listPerPage;
    const end = start + listPerPage;
    return list.slice(start, end);
  };
  const historyCurrentTab = getCurrentList(historyList, historyKey);

  // 페이지 클릭 시 페이지 번호 저장
  const handlePageClick = ({ selected }) => {
    setHistoryKey(selected);
  };

  return (
    <Fragment>
      <div className="title search-div d-flex flex-row">
        <div className="col-lg-12 period-search pl-0 pr-0">
          <div className="col-lg-2 pl-0">
            <input
              type="text"
              id="search_name"
              name="search_name"
              placeholder="회원명"
              className="form-control"
              onKeyDown={keyDownFnc}
              {...register("name")}
            />
          </div>
          <div className="col-lg-1 pl-0 pr-0">
            <select className="form-control" {...register("gd_level")}>
              <option value={0}>등급</option>
              {gradeList.length > 0 &&
                gradeList.map((grade) => {
                  return (
                    <option key={grade.gd_level} value={grade.gd_level}>
                      {grade.gd_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-1 pr-0">
            <select className="form-control" {...register("transaction_type")}>
              <option value={"ALL"}>전체</option>
              <option value={"EARN"}>적립</option>
              <option value={"USE"}>사용</option>
              <option value={"CANCEL"}>취소</option>
            </select>
          </div>
          <div className="col-lg-2 resize-period pr-0">
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              오늘
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              이번달
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary period_search_css"
              onClick={handlePeriodInquiry}
            >
              올해
            </button>
          </div>
          <div className="col-lg-4" style={{ padding: "0" }}>
            <div className="input-con col-lg-4">
              <input
                type="date"
                id="between_day"
                name="between_day"
                className="form-control"
                {...register("start_dt")}
              />
              <span>~</span>
            </div>
            <div className="input-con col-lg-4">
              <input
                type="date"
                id="and_day"
                name="and_day"
                className="form-control"
                {...register("end_dt")}
              />
            </div>
            <div className="input-con col-lg-3 resize-sch-btn">
              <button
                type="submit"
                className="btn btn-outline-secondary search-btn"
                onClick={handleSearchClick}
              >
                <BsSearch style={{ fontWeight: "bold" }} />
                &nbsp; 조회
              </button>
            </div>
          </div>
          <div className="col-lg-2 resize-btn float-right px-0 cc-col-right">
            <ExcelDownload
              className="btn btn-primary d-flex align-items-center float-right mr-0"
              menu={"point"}
              downloadDatas={historyList}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 pl-0 pr-0">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <div className="table-stats order-table ov-h c-table">
                <table
                  className={`table text-center fontS add-table resize-table point-table ${
                    historyCurrentTab.length > 0 ? "" : "history-table"
                  }`}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "60px" }} scope="col">
                        No
                      </th>
                      <th style={{ width: "200px" }} scope="col">
                        연락처
                      </th>
                      <th style={{ width: "200px" }} scope="col">
                        회원명
                      </th>
                      <th style={{ width: "200px" }} scope="col">
                        등급
                      </th>
                      <th style={{ width: "150px" }} scope="col">
                        거래 구분
                      </th>
                      <th style={{ width: "150px" }} scope="col">
                        포인트 구분
                      </th>
                      <th style={{ width: "180px" }} scope="col">
                        포인트
                      </th>
                      <th style={{ width: "180px" }} scope="col">
                        잔여 포인트
                      </th>
                      <th style={{ width: "250px" }} scope="col">
                        일자
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyCurrentTab.length > 0 &&
                      historyCurrentTab.map((list, i) => {
                        return (
                          <tr
                            key={i}
                            className={list.is_cancel ? "is_cancel_row" : ""}
                          >
                            <td>{list.id}</td>
                            <td>{phoneRegularExpFnc(list.contact)}</td>
                            <td>{emptyToHyphen(list.name)}</td>
                            <td>{list.gd_name}</td>
                            <td>{useTypeKor(list.use_type)}</td>
                            <td>{transTypeKor(list.transaction_type)}</td>
                            <td>{getFormattedPrice(list.point)} P</td>
                            <td>{getFormattedPrice(list.remain_point)} P</td>
                            <td>{list.mod_dt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="c-pagination prevent-drag">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                pageCount={Math.ceil(historyList.length / listPerPage)}
                breakLabel={false}
                marginPagesDisplayed={false}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PointHistory;
